import { ReportTemplateModel, TemplateSetType } from "./sustainReportDeclareModel";
/** 範本資料 */
const PocReportTemplateArr: ReportTemplateModel[] = [
    {
      id: "template_1",
      leftText: "Template1",
      rightText: "直式A4",
      previewImg: "/assets/images/declaration/reportTemplate/template_1_1.jpg",
      previewItems: [1, 2, 3, 4],
      type: TemplateSetType.VerticalSet1,
    },
    {
      id: "template_2",
      leftText: "Template2",
      rightText: "直式A4",
      previewImg: "/assets/images/declaration/reportTemplate/template_2_1.jpg",
      previewItems: [1, 2, 3, 4],
      type: TemplateSetType.VerticalSet2,
    },
    {
      id: "template_3",
      leftText: "Template3",
      rightText: "橫式A4",
      previewImg: "/assets/images/declaration/reportTemplate/template_3_1.jpg",
      previewItems: [1, 2, 3, 4],
      type: TemplateSetType.HorizontalSet1,
    },
    {
      id: "template_4",
      leftText: "Template4",
      rightText: "橫式A4",
      previewImg: "/assets/images/declaration/reportTemplate/template_4_1.jpg",
      previewItems: [1, 2, 3, 4],
      type: TemplateSetType.HorizontalSet2,
    },
  ];

  export { PocReportTemplateArr}