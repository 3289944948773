import { HttpClient } from 'services/httpClient';
import {
	DeclareDataRequestModel,
	EsgDisclosureDataRequestModel,
	DeclareSettingDataRequestModel,
	DeclareSettingDataModel,
	ReportProduceSearchModel,
} from 'models/admin/declarationModel';

const httpClient = new HttpClient();

// #region 異動申請管理 TransactionRequest

/** 取得更正申請管理清單 */
const getDeclareDataCorrectionRequestList = (request: DeclareDataRequestModel) => {
	return httpClient.post(`DeclareDataManage/correction-request/list`, request);
};

/** 取得更正申請管理清單excel檔 */
const getDeclareDataCorrectionRequestExcel = (request: DeclareDataRequestModel) => {
	return httpClient.postBlob(`DeclareDataManage/correction-request/excel/FileStream`, request);
};

/** 開啟更正申請權限 */
const openDeclareDataCorrectionRequestPermission = (id: string) => {
	return httpClient.post(`DeclareDataManage/correction-request/permission?requestId=${id}`, null);
};

// #endregion 異動申請管理 TransactionRequest

// #region ESG資訊揭露 EsgDisclosure

/** 取得申報資料管理-ESG資訊揭露 */
const getEsgDisclosureList = (request: EsgDisclosureDataRequestModel) => {
	return httpClient.post(`DeclareDataManage/esg/list`, request);
};

/** 取得申報資料管理-ESG資訊揭露清單excel檔 */
const getEsgDisclosureExcel = (request: EsgDisclosureDataRequestModel) => {
	return httpClient.postBlob(`DeclareDataManage/esg/excel/FileStream`, request);
};

// #endregion ESG資訊揭露 EsgDisclosure

// #region 溫室氣體排放及減量資訊 GhgEmissionsReduction

/** 取得申報資料管理-溫室氣體排放及減量資訊清單 */
const getGhgDeclareList = (request: EsgDisclosureDataRequestModel) => {
	return httpClient.post(`DeclareDataManage/ghg/list`, request);
};

/**取得申報資料管理-溫室氣體排放及減量資訊清單excel檔 */
const getGhgDeclareExcel = (request: EsgDisclosureDataRequestModel) => {
	return httpClient.postBlob(`DeclareDataManage/ghg/excel/FileStream`, request);
};

// #endregion 溫室氣體排放及減量資訊 GhgEmissionsReduction

// #region 永續報告書清單 SustainabilityReport

/** 取得申報資料管理-永續報告書清單 */
const getSustainReportDeclareList = (request: EsgDisclosureDataRequestModel) => {
	return httpClient.post(`DeclareDataManage/sustain-report/list`, request);
};

/** 取得申報資料管理-永續報告書清單excel檔 */
const getSustainReportDeclareExcel = (request: EsgDisclosureDataRequestModel) => {
	return httpClient.postBlob(`DeclareDataManage/sustain-report/excel/FileStream`, request);
};

// #endregion 永續報告書清單 SustainabilityReport

// #region 申報資料設定 DeclareSetting

/** 取得申報資料設定 */
const getDeclareSettingData = (request: DeclareSettingDataRequestModel) => {
	return httpClient.post(`DeclareDataManage/setting`, request);
};

/** 編輯申報資料設定 */
const editDeclareSettingData = (request: DeclareSettingDataModel) => {
	return httpClient.post(`DeclareDataManage/editSetting`, request);
};

// #endregion 申報資料設定 DeclareSetting

// #region 匯出檔案 ExportDocument

/** 匯出檔案(最新申報資料)列表 */
const getExportCsvList = () => {
	return httpClient.post(`DeclareDataManage/exportcsv/list`);
};

/** 匯出檔案 檔案下載 */
const getExportCsvFile = (request: { fileMarketType: number; fileType: number }) => {
	return httpClient.getBlob(
		`DeclareDataManage/exportcsv/FileStream?fileMarketType=${request.fileMarketType}&fileType=${request.fileType}`
	);
};

// #endregion 匯出檔案 ExportDocument

//#region 報告書產製 ReportGeneration

/** 取得報告書產製資訊清單 */
const getReportProduceList = (request: ReportProduceSearchModel) => {
	return httpClient.post(`DeclareDataManage/reportProduce/list`, request);
};

/** 取得申報資料管理-報告書產製清單excel檔 */
const getReportProduceListExcelFileStream = (request: ReportProduceSearchModel) => {
	return httpClient.postBlob(`DeclareDataManage/reportProduce/excel/FileStream`, request);
};

/** 取得公司資訊-根據報告書產製id */
const getCompanyInfo = (produceId: string) => {
	return httpClient.get(`DeclareDataManage/getCompanyInfo?produceId=${produceId}`);
};
//#endregion

/** 取得報告(資料)年度清單 */
const getYearList = () => {
	return httpClient.get(`DeclareDataManage/yearList`);
};

const DeclarationService = {
	getDeclareDataCorrectionRequestList,
	getDeclareDataCorrectionRequestExcel,
	openDeclareDataCorrectionRequestPermission,
	getEsgDisclosureList,
	getEsgDisclosureExcel,
	getGhgDeclareList,
	getGhgDeclareExcel,
	getSustainReportDeclareList,
	getSustainReportDeclareExcel,
	getDeclareSettingData,
	editDeclareSettingData,
	getExportCsvList,
	getExportCsvFile,
	getReportProduceList,
	getReportProduceListExcelFileStream,
	getYearList,
	getCompanyInfo
}

export default DeclarationService;
