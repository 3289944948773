import { HttpClient } from "services/httpClient";
import { OperatingRacordListRequestModel } from "models/admin/operatingRecordModel";

const httpClient = new HttpClient();

/** 取得更正申請管理清單 */
const getOperatingRecordList = (request: OperatingRacordListRequestModel) => {
  return httpClient.post(`SustainRreportManuscriptManage/recordList`, request);
};

const getRecordData = (id: string) => {
  return httpClient.post(`SustainRreportManuscriptManage/recordData?recordId=${id}`, null);
};

const getDraftData = (id: string) => {
  return httpClient.post(`SustainRreportManuscriptManage/recordData?recordId=${id}`, null);
};

const getGriStandard = () => {
  return httpClient.get(`SustainRreportManuscriptManage/griStandard`);
};

const getDefaultTreeFlat = (id: string) => {
  return httpClient.get(`SustainRreportManuscriptManage/defaultTreeFlat?manageId=${id}`)
}

const getSustainReportDeclareExcel = (request: OperatingRacordListRequestModel) => {

  return httpClient.postBlob(`SustainRreportManuscriptManage/recordList/FileStream`, request);
};


const OperatingRecordService = {
  getOperatingRecordList,
  getRecordData,
  getDraftData,
  getGriStandard,
  getDefaultTreeFlat,
  getSustainReportDeclareExcel,
};

export default OperatingRecordService;
