import React, { useEffect, useState, useRef } from "react";
import styles from "./DraftRecordModal.module.scss";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
import { useMutation } from "@tanstack/react-query";
import OperatingRecordService from "services/admin/operatingRecordService"
import { DraftDataModel, GriStandardItem, DefaultTreeFlat,Table, TableCell } from "models/admin/operatingRecordModel"
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import TableRecordModal from "../TableRecordModal/TableRecordModal";

export interface DreftRecordModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  recordId: string;
}

const DreftRecordModal: React.FC<DreftRecordModalProps> = ({
  show,
  onClose,
  title,
  recordId,
}) => {
  const scrollBarContainerHeight = 508;
  const [isLoading, setIsLoding] = useState(false);
  const beforeEditingRef = useRef<HTMLDivElement | null>(null);
  const afterEditingRef = useRef<HTMLDivElement | null>(null);
  const [scrollBarHeight, setScrollBarHeight] = useState<number | null>(null);
  const [modifiedData, setModifiedData] = useState<DraftDataModel>();
  const [originData, setOriginData] = useState<DraftDataModel>();
  const [dataDefaultValues, setDataDefaultValues] = useState<DraftDataModel>({
    ChapterString: '',
    ChapterTitle: '',
    GriLabel: '',
    Manuscript: '',
  });;
  const [griStandard, setGriStandard] = useState([]);
  const [scrollBarDivHeight, setScrollBarDivHeightHeight] = useState<number | null>(null);
  const [openTableRecordModal, setOpenTableRecordModal] = useState<boolean>(false)
  const manageIdRef = useRef<string>();
  const modifiedTableList = useRef<Table[]>()
  const originTableList = useRef<Table[]>()
  const handleTableBtn = useRef<boolean>(false)
  const findDiffFromOriginTableList = useRef<Table[]>()
  const findDiffFromModifiedTableList = useRef<Table[]>()
  const defaultTreeFlatRef = useRef<Array<DefaultTreeFlat>>([]);
  const [diffSection, setDiffSections] = useState({
    title: false,
    griLabel:false,
  });
  const closeModal = () => {
    setModifiedData(dataDefaultValues)
    setOriginData(dataDefaultValues)
    onClose();
  }
  const [modal, setModal] = useState<ContentModalProps>({
    show: false,
    size: "xl",
    customClass: "open-draftRecord-modal",
    handleClose: closeModal,
    title: title,
  });

  /******************* Effect區塊 START *******************/
  // 包裝比較邏輯的函數
  useEffect(()=> {
    const compareSections = () => {
      const chapterChanged =
        (originData?.ChapterString || '') !== (modifiedData?.ChapterString || '') ||
        (originData?.ChapterTitle || '') !== (modifiedData?.ChapterTitle || '');

        
      const GriChanged =  (originData?.GriLabel || '') !== (modifiedData?.GriLabel || '') ||
      (originData?.GriLabel || '') !== (modifiedData?.GriLabel || '');

      return { chapterChanged, GriChanged };

    };
    // 調用函數以獲取比較結果
    const { chapterChanged,GriChanged } = compareSections();
  
    setDiffSections({
      title: chapterChanged,
      griLabel: GriChanged,
    });
  },[modifiedData,originData])
  

  useEffect(() => {
    setModal((prev) => ({ ...prev, show: show, title: title }));


    if (show === false) {
      return;
    }

    if (manageIdRef.current) {
      getDefaultTreeFlat(manageIdRef.current)
    }
    
    handleTableBtn.current = false;
    getGriStandard() 
    setIsLoding(true)
    
  }, [show, manageIdRef.current])

  useEffect(() => {
    if (recordId) {
      getDraftData(recordId)
    }
  },[defaultTreeFlatRef.current,recordId])

  useEffect(() => {
    //因為章節名稱需要 getDraftData 兩次來設置，所以等有值得時候再取消 loading
    if (modifiedData?.ChapterString ) {
      setIsLoding(false)
    }
  },[modifiedData,originData])


//  useEffect(()=> {
//   console.log('modifiedData',modifiedData);
//   console.log('originData',originData);
  
//  },[originData])


  // 讓元素同時scroll
  useEffect(() => {
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;

      const syncScroll = (scrolledDiv: HTMLElement) => {
        const bf = document.querySelector(".beforeEditing");
        const af = document.querySelector(".afterEditing");
        const d3 = document.querySelector(".scrollBarBox");
        

        if (bf && af && d3) {
          bf.scrollTop = af.scrollTop = d3.scrollTop = scrolledDiv.scrollTop;
        }
      };
      syncScroll(target);
    };


    const elements = [".beforeEditing", ".afterEditing", ".scrollBarBox"];

    const addScrollListeners = () => {
      elements.forEach((selector) => {
        const element = document.querySelector(selector);
        if (element) {
          element.addEventListener("scroll", handleScroll);
        }
      });
    };

    const removeScrollListeners = () => {
      elements.forEach((selector) => {
        const element = document.querySelector(selector);
        if (element) {
          element.removeEventListener("scroll", handleScroll);
        }
      });
    };

    // 確保元素已經渲染
    const timeOut = setTimeout(addScrollListeners, 50);


    return () => {
      clearTimeout(timeOut);
      removeScrollListeners();
    };
  }, [modifiedData,originData]); 

  // 計算容器高度
  useEffect(() => {
    const getOverflowHeights = () => {
      if (beforeEditingRef.current && afterEditingRef.current) {
        const beforeOverflowHeight =
          beforeEditingRef.current.scrollHeight -
          beforeEditingRef.current.clientHeight;
          
        const afterOverflowHeight =
          afterEditingRef.current.scrollHeight -
          afterEditingRef.current.clientHeight;

        const maxOverflowHeight = Math.max(
          beforeOverflowHeight,
          afterOverflowHeight
        );

        const containerHeight = scrollBarContainerHeight + maxOverflowHeight; // 前面數值為容器高度

        setScrollBarHeight(maxOverflowHeight);
        setScrollBarDivHeightHeight(containerHeight);
      }
    };
    // 延遲以確保渲染完成
    const timeoutId = setTimeout(getOverflowHeights, 50);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [modifiedData,originData]);
  /******************* Effect區塊 END *******************/

  // 比較兩個表格並標記不同的單元格
  const compareTables = (originTableList: Table[], modifiedTableList: Table[]) => {
    const getTableMap = (tableList: Table[]) => {
      const tableMap: Map<string, { tableName: string, cells: Map<string, TableCell> }> = new Map();
  
      tableList.forEach(table => {
        const cellMap: Map<string, TableCell> = new Map();
        table.Cells.forEach(cell => {
          cellMap.set(cell.CellId, cell);
        });
        tableMap.set(table.TableId, { tableName: table.TableName, cells: cellMap });
      });
  
      return tableMap;
    };
  
    const originTableMap = getTableMap(originTableList);
    const modifiedTableMap = getTableMap(modifiedTableList);
  
    let hasDifferences = false; // 用來跟蹤是否有不同的單元格或表格名稱
  
    // 比較表格並標記不同的單元格和表格名稱
    const markDifferences = (originMap: Map<string, { tableName: string, cells: Map<string, TableCell> }>, modifiedMap: Map<string, { tableName: string, cells: Map<string, TableCell> }>) => {
      
      modifiedMap.forEach((modifiedTable, tableId) => {
        const originTable = originMap.get(tableId);
  
        if (originTable) {
          // 檢查 TableName 是否不同
          if (originTable.tableName !== modifiedTable.tableName) {
            hasDifferences = true; 
          }
  
          // 檢查每個表格的單元格
          modifiedTable.cells.forEach((modifiedCell, cellId) => {
            const originCell = originTable.cells.get(cellId);
            if (originCell) {
              if (originCell.CellValue !== modifiedCell.CellValue) {
                modifiedCell.IsDifferent = true;
                originCell.IsDifferent = true;
                hasDifferences = true; 
              }
            } else {
              // 新增的單元格
              modifiedCell.IsDifferent = true;
              hasDifferences = true; 
            }
          });
  
          // 標記原始資料中未在修改資料中的單元格
          originTable.cells.forEach((originCell, cellId) => {
            if (!modifiedTable.cells.has(cellId)) {
              originCell.IsDifferent = true;
              hasDifferences = true; 
            }
          });
        } else {
          // 原始表格中沒有這個表格，表格被新增
          modifiedTable.cells.forEach(modifiedCell => {
            modifiedCell.IsDifferent = true;
            hasDifferences = true; 
          });
        }
      });
  
      // 接著檢查原始表格中有而修改過的表格中沒有的情況（表格被刪除）
      originMap.forEach((originTable, tableId) => {
        if (!modifiedMap.has(tableId)) {
          originTable.cells.forEach(originCell => {
            originCell.IsDifferent = true;
          });
          hasDifferences = true; 
        }
      });
    };
  
    markDifferences(originTableMap, modifiedTableMap);
  
    const showTableBtn = hasDifferences;
  
    return {
      updatedOriginTableList: originTableList,
      updatedModifiedTableList: modifiedTableList,
      showTableBtn,
    };
  };

  /******************* Mutation區塊 START *******************/


  const { mutate: getDraftData,} = useMutation({
    mutationFn: (id: string) => OperatingRecordService.getDraftData(id),
    onSuccess: async (res) => {
      console.log('RecordID',res);
      
      setIsLoding(true);
      manageIdRef.current = res.data.manageId;
        if (res.data) {
          
          
          const modifiedDataAfterParse = res.data.modifiedData ? JSON.parse(res.data.modifiedData)  : null;
          console.log('modifiedDataAfterParse',modifiedDataAfterParse);
          

          const originDataAfterParse = res.data.originData  ? JSON.parse(res.data.originData)  : null;

          
          if ((modifiedDataAfterParse && modifiedDataAfterParse.TableList) ||
              (originDataAfterParse && originDataAfterParse.TableList)) {

            modifiedTableList.current = modifiedDataAfterParse?.TableList as Table[] || [];
            originTableList.current = originDataAfterParse?.TableList as Table[] || [];

            const { updatedOriginTableList, updatedModifiedTableList, showTableBtn } = compareTables(
              originTableList.current, 
              modifiedTableList.current
            );

            if (showTableBtn) {
              findDiffFromOriginTableList.current = updatedOriginTableList;
              findDiffFromModifiedTableList.current = updatedModifiedTableList;
              handleTableBtn.current = true;
            } else {
              handleTableBtn.current = false;
            }
          }

          
          
          // 設置完整的 originData 的 title
          const originDataFullyTitle = defaultTreeFlatRef.current.find(
            (item: DefaultTreeFlat) => item.defaultId === originDataAfterParse?.DefaultId
          );
          
          const originDataChapterString = originDataFullyTitle?.chapterString;
          const originDataChapterTitle = originDataFullyTitle?.title;
  
          // 設置完整的 modifiedData 的 title
          const modifiedDataFullyTitle = defaultTreeFlatRef.current.find(
            (item: DefaultTreeFlat) => item.defaultId === modifiedDataAfterParse?.DefaultId
          );
          const modifiedDataTitleChapterString = modifiedDataFullyTitle?.chapterString;
          const modifiedDataTitleChapterTitle = modifiedDataFullyTitle?.title;
  
          // 找出相對應的 Gris
          const originDataGriStandards = griStandard.filter((item: GriStandardItem) =>
            originDataAfterParse?.GriStandards.includes(item.griSno))
          .map((item: GriStandardItem) => item.griStandard).join('、');
  
          const modifiedDataGriStandards = griStandard.filter((item: GriStandardItem) =>
            modifiedDataAfterParse?.GriStandards.includes(item.griSno))
          .map((item: GriStandardItem) => item.griStandard).join('、');

          // 確定找到相對應 title string 之後再渲染

          if (modifiedDataFullyTitle) {
            setModifiedData({
              Manuscript: modifiedDataAfterParse.Manuscript || '',
              ChapterString: modifiedDataTitleChapterString || '',
              ChapterTitle: modifiedDataTitleChapterTitle || '',
              GriLabel: modifiedDataGriStandards || '',
            });
            setOriginData({
              Manuscript: originDataAfterParse.Manuscript || '',
              ChapterString: originDataChapterString || '',
              ChapterTitle: originDataChapterTitle || '',
              GriLabel: originDataGriStandards || '',
            });
            
          } else {
            setModifiedData({
              Manuscript: modifiedDataAfterParse.Manuscript || '',
              ChapterString: "(已刪除的章節)--",
              ChapterTitle: modifiedDataAfterParse.Title || '',
              GriLabel: modifiedDataGriStandards || '',
            });
            setOriginData({
              Manuscript: originDataAfterParse.Manuscript || '',
              ChapterString:"(已刪除的章節)--",
              ChapterTitle: originDataAfterParse.Title || '',
              GriLabel: originDataGriStandards || '',
            });
          }
            
          

        } else {
          setModifiedData(dataDefaultValues);
          setOriginData(dataDefaultValues);
        }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  

  const { mutate: getGriStandard,} = useMutation({
    mutationFn: () => OperatingRecordService.getGriStandard(),
    onSuccess: (res) => {
      setGriStandard(res.data)
    },
    onError: (err) => { console.log(err); },
  })

  const { mutate: getDefaultTreeFlat } = useMutation({
    mutationFn: (id: string) => OperatingRecordService.getDefaultTreeFlat(id),
    onSuccess: (res) => {
      defaultTreeFlatRef.current = res.data;
      console.log(' defaultTreeFlatRef.current', defaultTreeFlatRef.current);
      
    },
    onError: (err) => { console.log(err); },
  })

  const splitManuscript = (before: string, after: string, type: 'before' | 'after') => {
  const splitPattern = /([，。、？！：；“”‘’（）《》〈〉【】『』「」﹁﹂—…·,.?!:;'"()<>[\]{}])/;
  const beforeParts = before.split(splitPattern);
  const afterParts = after.split(splitPattern);

  // 判斷是否有差異且索引不同
  // const isDifferentWithIndexCheck = (part: string, index: number, compareParts: string[]) => {
  //     const otherIndex = compareParts.findIndex((p, i) => p === part && i === index);
  //     return otherIndex === -1;
    
  // };

  if (type === 'before') {
    return beforeParts.map((part, index) => {
      // const textDiff = !afterParts.includes(part);
      // let isDifferent = false;
      // if (textDiff) {
      //   isDifferent = isDifferentWithIndexCheck(part, index, afterParts);
      // }
      const isDifferent = !afterParts.includes(part)
  
      const className = isDifferent ? "highlight-before" : "";
      return (
        <span key={index} className={className}>
          {part}
        </span>
      );
    });
  } else if (type === 'after') {
    return afterParts.map((part, index) => {
      // const textDiff = !beforeParts.includes(part);
      // let isDifferent = false;  
      // if (textDiff) {
      //   isDifferent = isDifferentWithIndexCheck(part, index, beforeParts);
      // }
      const isDifferent = !beforeParts.includes(part)
      const className = isDifferent ? "highlight-after" : "";
      return (
        <span key={index} className={className}>
          {part}
        </span>
      );
    });
  }
};
  

  const handleTableRecordModal = () => {
    setOpenTableRecordModal(true);
    setModal((prev) => ({ ...prev, show: false, }));
  };

  const closeTableModal = () => {
    setOpenTableRecordModal(false)
    setModal((prev) => ({ ...prev, show: true, }));
  }


  return (
  <>
  <ContentModal {...modal}>
      { isLoading ? (
          <VisuallLoading />
        ) : null}
      <div className={`${styles["open-draftRecord-modal-content"]}`}>
        <div className="beforeAfter_text_box">
          <div className="beforeAfter_text">編輯前</div>
          <div className="beforeAfter_text">編輯後</div>
          {handleTableBtn.current && (
            <button className="table_btn" onClick={handleTableRecordModal}>查看表格紀錄</button>
          )}
        </div>

        <div className="result-box scroll">
          {/* 編輯前 */}
          <div className="beforeEditing" ref={beforeEditingRef}>
              <div className="content_section" >
                <div className="title">章節名稱</div>
                <div className={`text ${originData?.ChapterString === "(已刪除的章節)--" ? 'highlight-before' : ''}`} style={{ marginLeft: "12px" }}>
                {originData?.ChapterString || originData?.ChapterTitle 
                  ? `${originData?.ChapterString || ''} ${originData?.ChapterTitle || ''}` 
                  :  '-'}
                </div>
              </div>
              <div className="content_section" >
                <div className="title">GRI指標</div>
                
                <div className={`text griLabel ${diffSection.griLabel && originData?.GriLabel ? 'highlight-before' : ''}`}  style={{ marginLeft: "12px" }} >
                    {originData?.GriLabel ? originData?.GriLabel : "-"}
                </div>
              </div>
              <div className="content_section" >
                <div className="title">參考範例</div>
                <div className="text" style={{ marginLeft: "12px" }}>
                  -
                </div>
              </div>
              <div className="content_section no-border" >
                <div className="title ">底稿撰寫</div>
                <div className="text" style={{ marginLeft: "12px" }}>
                  <div>
                    {splitManuscript(originData?.Manuscript || '', modifiedData?.Manuscript || '', 'before')}
                  </div>
                </div>
              </div>
          </div>

          {/* 編輯後 */}
          <div className="afterEditing" ref={afterEditingRef}>
              <div className="content_section" >
                <div className="title">章節名稱</div>
                <div className={`text ${modifiedData?.ChapterString === "(已刪除的章節)--" ? 'highlight-after' : ''}`} style={{ marginLeft: "12px" }}>
                 {modifiedData?.ChapterString} {modifiedData?.ChapterTitle}
                </div>
              </div>
              <div className="content_section" >
                <div className="title">GRI指標</div>
                  <div className={`text griLabel ${diffSection.griLabel && modifiedData?.GriLabel ? 'highlight-after' : ''}`}style={{ marginLeft: "12px" }}>
                  {modifiedData?.GriLabel ? modifiedData?.GriLabel : "-"}
                </div>
              </div>
              <div className="content_section" >
                <div className="title">參考範例</div>
                <div className="text" style={{ marginLeft: "12px" }}>
                  -
                </div>
              </div>
              <div className="content_section no-border" >
                <div className="title">底稿撰寫</div>
                <div className="text" style={{ marginLeft: "12px" }}>
                  <div>
                   {splitManuscript(originData?.Manuscript || '', modifiedData?.Manuscript || '', 'after')}
                  </div>
                </div>
              </div>
          </div>
          {/* Scroll-bar-sec */}
          <div className="scrollBarContainer">
            <div
              id="div3"
              className="scroll scrollBarBox"
              style={{
                height: `${scrollBarContainerHeight}px`,
                width: "10px",
                backgroundColor: "transparent",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  height: `${scrollBarDivHeight}px`,
                  width: "2px",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </ContentModal>
    <TableRecordModal
        show={openTableRecordModal}
        title="表格操作紀錄"
        onClose={closeTableModal}
        modifiedTableList={findDiffFromModifiedTableList.current || []}
        originTableList={findDiffFromOriginTableList.current || []}
        />
  </>
    
  );
};

export default DreftRecordModal;