import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./MultiSelect.module.scss";
import { MultiOptionModel } from "../../../models/baseModel";
import { useTranslation } from "react-i18next";
import { CommonService } from 'services/common/commonService';

interface MultiSelectProps {
  onChange: (selIds: string[]) => void;
  placeholder: string | null;
  options: MultiOptionModel[];
  /** 預設id */
  defaultIds?: string[];
  valid?: boolean;
  disabled?: boolean;
  /** 浮動title */
  isFloatTitle?: boolean;
  /** 搜尋框 */
  showSearch?: boolean;
  /** 是否全選 */
  showAll?: boolean;
  /** show 全選文字字樣 */
  showAllText?: string;
  /** 隱藏option (主要因為render關係會讓選單點一次就關閉) */
  hideOptions?: boolean;
  /** 只顯示icon，不顯示輸入框 */
  isOnlyIcon?: boolean;
}

const MultiSelect: FC<MultiSelectProps> = ({
  valid = true,
  showSearch = true,
  showAll = true,
  hideOptions = true,
  showAllText = "全選",
  ...props
}) => {
  const [filterOptions, setFilterOptions] = useState<MultiOptionModel[]>(
    props.options
  );
  // 實際的options
  const [realOptions, setRealOptions] = useState<MultiOptionModel[]>([]);
  const [hide, setHide] = useState<boolean>(hideOptions);
  const [searchKey, setSearchKey] = useState("");
  const [displayWord, setDisplayWord] = useState("");
  const [checkAll, setCheckAll] = useState<boolean>();
  const boxRef = useRef<HTMLDivElement>(null);
  const { defaultIds } = props;
  const { t } = useTranslation();

  // 初始設定
  useEffect(() => {
    // 將預設的選單checked 都改為false
    const newOptions = props.options;
    newOptions.forEach((p) => {
      p.checked = false;
      if (defaultIds && defaultIds.includes(p.id)) {
        p.checked = true;
      }
    });
    setConcateStr(newOptions);
    // defaultIds陣列不一定會作為動態一直傳入，所以要保留初次的完整陣列
    setRealOptions(CommonService.deepClone(newOptions))
    // 有文字重新初始化時需調整
    if (searchKey) {
      handleChangeKey(searchKey);
    } else {
      setFilterOptions(CommonService.deepClone(newOptions));
      setCheckFlow(newOptions);
    }
  }, [props.options, defaultIds]);

  // 輸入框
  const clickItem = (
    e: React.MouseEvent<HTMLDivElement>,
    item: MultiOptionModel
  ) => {
    e.preventDefault();
    e.stopPropagation();

    const newOptions = CommonService.deepClone(realOptions);

    // 找到選擇的部分
    const index = newOptions.findIndex((p) => p.id === item.id);
    newOptions[index].checked = !newOptions[index].checked;
    // 完整項目
    setRealOptions(newOptions);

    // 設定輸出資料流程
    setDataFlow(newOptions);
    setCheckFlow(newOptions);

    // 搜尋清單調整(不是完整的)
    setFilterOptions(
      filterOptions.map((p) => {
        if (p.id === item.id) {
          p.checked = !p.checked;
        }
        return p;
      })
    );
  };

  // 設置連接文字
  const setConcateStr = (selOptions: MultiOptionModel[]) => {
    const selTexts = selOptions.filter((p) => p.checked).map((p) => p.text);

    // 外部自訂文字
    if (selTexts.length === props.options.length) {
      setDisplayWord(showAllText);
    } else {
      if (selTexts) {
        const str = selTexts.join(",");
        setDisplayWord(str);
      }
    }
  };

  // 搜尋key
  const handleChangeKey = (key: string | null) => {
    setSearchKey(key || "");
    // 複製新陣列
    const newOptions = [...props.options];
    if (key) {
      const selItems = newOptions.filter((p) =>
        p.text.toLocaleLowerCase().includes(key.toLocaleLowerCase())
      );
      setFilterOptions(selItems);
    } else {
      setFilterOptions(newOptions);
      setCheckFlow(newOptions);
    }
  };

  // 全選
  const selectAll = (res: boolean) => {
    setCheckAll(res);
    const newOptions = filterOptions.slice();
    // 全勾 or 取消
    newOptions.forEach((p) => {
      p.checked = res;
    });
    setFilterOptions(newOptions);

    setDataFlow(newOptions);
  };

  // 全選flow
  const setCheckFlow = (newOptions: MultiOptionModel[]) => {
    setCheckAll(newOptions.every((p) => p.checked === true));
  };

  // 輸出資料flow
  const setDataFlow = (newOptions: MultiOptionModel[]) => {
    setConcateStr(newOptions);
    const selIds = newOptions.filter((p) => p.checked).map((p) => p.id);
    props.onChange(selIds);
  };

  // 點擊外部
  useEffect(() => {
    const handleOtherClick = (e: any) => {
      if (!boxRef.current?.contains(e.target)) {
        setHide(true);
      }
    };

    document.body.addEventListener("click", handleOtherClick);

    return () => {
      document.body.removeEventListener("click", handleOtherClick);
    };
  }, []);

  /** 打開視窗flow */
  const openFlow = () => {
    setHide(false);
    handleChangeKey("");
  };

  /** 清除全部 */
  const clearAll = ()=>{
    const newOptions = props.options.slice();
    setFilterOptions(
      newOptions.map(p=> {
        p.checked = false;
        return p;
      })
    )
    setCheckAll(false);
    setDataFlow([]);
  }
  return (
    <div
      ref={boxRef}
      className={
        `${styles["multi-select-box"]} ` +
        `${props.isOnlyIcon ? "onlyIcon" : ""} ` +
        `${valid ? "" : "invalid"} ` +
        `${props.disabled ? "disabled" : ""} `
      }
      onClick={openFlow}
    >
      {props.isOnlyIcon === true ? (
        <img alt="" src="/assets/images/buttonIcon/sort-icon.svg" />
      ) : (
        <input
          className={`display-input `}
          readOnly
          type="text"
          value={displayWord}
          placeholder={props.placeholder || ""}
          title={displayWord}
        />
      )}
      {/* 浮動title */}
      {props.isFloatTitle && (
        <div className="placeholder-item">{props.placeholder}</div>
      )}
      <div className="dropdown-icon"></div>
      {/* 搜尋清單 */}
      {!hide && (
        <div
          className={`dropdown-box ${props.isOnlyIcon ? "onlyIcon-box" : ""}`}
        >
          {/* 篩選 */}
          {showSearch && (
            <div className="search-box">
              <input
                className={`seach-input `}
                type="text"
                value={searchKey}
                onChange={($event) => handleChangeKey($event.target.value)}
                placeholder={`${t("SEARCH")}...`}
              />
              <img
                alt="search"
                src="/assets/images/buttonIcon/search-icon.svg"
              />
            </div>
          )}
          {/* 全選 */}
          {showAll && (
            <div className="show-all-box">
              <label className="normal-checkbox ">
                <input
                  type="checkbox"
                  checked={checkAll === true}
                  onChange={($event) => {
                    $event.stopPropagation();
                    selectAll($event.target.checked);
                  }}
                />
                <span>全選</span>
              </label>
              <div className="clear-box" onClick={clearAll}>
                清除
              </div>
            </div>
          )}

          <div className="option-box scroll">
            {filterOptions.map((p, filterIndex) => {
              return (
                <div
                  className={"option-item"}
                  key={filterIndex}
                  onClick={($event) => clickItem($event, p)}
                >
                  <label className="normal-checkbox ">
                    <input
                      type="checkbox"
                      checked={p.checked}
                      onChange={() => {}}
                    />
                    <span>{p.text}</span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
