import React, { useEffect, useState, useRef } from "react";
import styles from "./OperatingRecordModal.module.scss";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
import { useMutation } from "@tanstack/react-query";
import OperatingRecordService from "services/admin/operatingRecordService"

export interface OperatingRecordModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  recordId:string;
}

const OperatingRecordModal: React.FC<OperatingRecordModalProps> = ({
  show,
  onClose,
  title,
  recordId,
}) => {
  /******************* 定義區塊 START *******************/
  const scrollBarContainerHeight = 560 // 卷軸的容器高度 (應跟 .beforeEditing .afterEditing 相同)
  const beforeEditingRef = useRef<HTMLDivElement | null>(null);
  const afterEditingRef = useRef<HTMLDivElement | null>(null);
  const [modifiedData, setModifiedData] = useState<any[]>([])
  const [originData, setOriginData] = useState<any[]>([])
  const [scrollBarHeight, setScrollBarHeight] = useState<number | null>(null);
  const [scrollBarDivHeight, setScrollBarDivHeightHeight] = useState<
    number | null
  >(null);
  const [modal, setModal] = useState<ContentModalProps>({
    show: false,
    size: "xl",
    customClass: "open-operatingRecord-modal",
    handleClose: onClose,
    title: title,
  });
  /******************* 定義區塊 END *******************/

  /******************* Effect區塊 START *******************/

  useEffect(() => {
    setModal((prev) => ({ ...prev, show: show, title: title }));
  }, [show]);

  useEffect(() => {
    console.log(recordId);

    if (recordId) {
      getRecordData(recordId)
    }
    
  }, [recordId]);

  // 讓元素同時scroll
  useEffect(() => {
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLDivElement;
  
      const syncScroll = (scrolledDiv: HTMLElement) => {
        const bf = document.querySelector(".beforeEditing");
        const af = document.querySelector(".afterEditing");
        const d3 = document.querySelector(".scrollBarBox");
  
        if (bf && af && d3) {
          bf.scrollTop = af.scrollTop = d3.scrollTop = scrolledDiv.scrollTop;
        }
      };
  
      syncScroll(target);
    };
  
    
    const elements = [".beforeEditing", ".afterEditing", ".scrollBarBox"];
    
    const addScrollListeners = () => {
      elements.forEach((selector) => {
        const element = document.querySelector(selector);
        if (element) {
          element.addEventListener("scroll", handleScroll);
        }
      });
    };
  
    const removeScrollListeners = () => {
      elements.forEach((selector) => {
        const element = document.querySelector(selector);
        if (element) {
          element.removeEventListener("scroll", handleScroll);
        }
      });
    };
  
    // 確保元素已經渲染
  const timeOut = setTimeout(addScrollListeners, 50);


  return () => {
    clearTimeout(timeOut); 
    removeScrollListeners(); 
  };
  }, [modifiedData, originData, show]); // 需設置成抓取資料後進行

  // 計算容器高度
  useEffect(() => {
    const getOverflowHeights = () => {
      if (beforeEditingRef.current && afterEditingRef.current) {
        const beforeOverflowHeight =
          beforeEditingRef.current.scrollHeight -
          beforeEditingRef.current.clientHeight;
          
        const afterOverflowHeight =
          afterEditingRef.current.scrollHeight -
          afterEditingRef.current.clientHeight;

        const maxOverflowHeight = Math.max(
          beforeOverflowHeight,
          afterOverflowHeight
        );
        
        
        const containerHeight = scrollBarContainerHeight + maxOverflowHeight; // 前面數值為容器高度

        setScrollBarHeight(maxOverflowHeight);
        setScrollBarDivHeightHeight(containerHeight);
      }
    };
    // 延遲以確保渲染完成
    const timeoutId = setTimeout(getOverflowHeights, 50);
    
    return () => {
      clearTimeout(timeoutId);
    };
  }, [modifiedData, originData, show]); // 需設置成抓取資料後進行

  /******************* Effect區塊 END *******************/

 /******************* Mutation區塊 START *******************/

  const {mutate:getRecordData,} = useMutation({
    mutationFn: (id:any) => 
      OperatingRecordService.getRecordData(id),
    onSuccess: (res) => {
      const modifiedDataAfterParse = JSON.parse(res.data.modifiedData)
      console.log('modifiedDataAfterParse', modifiedDataAfterParse);
      
      const originDataAfterParse = JSON.parse(res.data.originData)
      console.log('originDataAfterParse', originDataAfterParse);
      setModifiedData(modifiedDataAfterParse)
      setOriginData(originDataAfterParse)
    },
    onError: (err) => { console.log(err);
    },
  });

  /******************* Mutation區塊 END *******************/

  const beforeData = [
    {
      key: "1",
      label: "一、關於本報告書",
      children: [
        {
          key: "1-01",
          label: "1-01 報告書資訊",
          children: [
            { key: "1-01-1", label: "1-01-1 編製依據" },
            { key: "1-01-2", label: "1-01-2 報告涵蓋期間" },
            { key: "1-01-3", label: "1-01-3 報告邊界與範疇" },
            { key: "1-01-4", label: "1-01-4 資訊重編" },
            { key: "1-01-5", label: "1-01-5 外部確信/保證情形" },
            { key: "1-01-6", label: "1-01-6 永續報告之書在單位" },
            { key: "1-01-7", label: "1-01-7 資料管理" },
          ],
        },
        { key: "1-02", label: "1-02 關於本公司" },
        { key: "1-03", label: "1-03 其他" },
        { key: "1-04", label: "1-04 應注意事項" },
      ],
    },
    {
      key: "2",
      label: "二、經營理念與永續策略",
      children: [
        { key: "2-01", label: "2-01 經營理念與永續發展策略" },
        {
          key: "2-02",
          label: "2-02 推動永續發展機制",
          children: [
            { key: "2-02-1", label: "2-02-1 推動永續發展治理架構" },
            { key: "2-02-2", label: "2-02-2 運作及執行情形" },
          ],
        },
        { key: "2-03", label: "2-03 董事會及功能性委員會" },
        { key: "2-04", label: "2-04 委員會追查報告書" },
      ],
    },
  ];

  const afterData = [
    {
      key: "1",
      label: "一、關於本報告書",
      children: [
        {
          key: "1-01",
          label: "1-01 報告書資訊",
          children: [
            { key: "1-01-1", label: "1-01-1 編製依據" },
            { key: "1-01-2", label: "1-01-2 報告涵蓋期間、頻率" },
            { key: "1-01-3", label: "1-01-3 報告邊界與範疇" },
            { key: "1-01-4", label: "1-01-4 資訊重編" },
            { key: "1-01-5", label: "1-01-5 外部確信/保證情形" },
            { key: "1-01-6", label: "1-01-6 永續報告之書在單位" },
          ],
        },
        { key: "1-02", label: "1-02 關於本公司" },
        { key: "1-03", label: "1-03 其他" },
      ],
    },
    {
      key: "2",
      label: "二、經營理念與永續策略",
      children: [
        { key: "2-01", label: "2-01 經營理念與永續發展策略/經營者的話" },
        {
          key: "2-02",
          label: "2-02 推動永續發展機制",
          children: [
            { key: "2-02-1", label: "2-02-1 推動永續發展治理架構" },
            { key: "2-02-2", label: "2-02-2 運作及執行情形" },
            { key: "2-02-3", label: "2-02-3 廣告預算編製" },
          ],
        },
        { key: "2-03", label: "2-03 董事會及功能性委員會" },
        { key: "2-04", label: "2-05 委員會報告書" },
        { key: "2-05", label: "2-06 紀錄" },
      ],
    },
  ];

  function syncFromAfterToBefore(originData: any[], modifiedData: any[]) {
    modifiedData.forEach((modifiedItem: any) => {
      const originItem = originData.find((item: any) => item.Id === modifiedItem.Id);
  
      if (!originItem) {
        // 如果 originData 中不存在此項目，創建新的項目，並重設 Title 和 ChapterString
        const newItem = {
          ...modifiedItem,
          Title: `\u200B`,
          ChapterString: ""
        };
  
        // 保留 Children 結構，但重置 Title 和 ChapterString
        if (modifiedItem.Children) {
          newItem.Children = modifiedItem.Children.map((child: any) => ({
            ...child,
            Title: `\u200B`,
            ChapterString: "",
          }));
        }
  
        originData.push(newItem);
  
        // 同時更新 modifiedData 中對應的項目的 label
        modifiedItem.Title = `${modifiedItem.Title}--(新增)`;
  
        // 更新 Children 的 Title 標籤
        if (modifiedItem.Children) {
          modifiedItem.Children.forEach((child: any) => {
            child.Title = `${child.Title}--(新增)`;
          });
        }
      } else if (modifiedItem.Children) {
        // 如果存在，則遞歸調用
        syncFromAfterToBefore(originItem.Children, modifiedItem.Children);
      }
    });
  }
  
  function syncFromBeforeToAfter(originData: any, modifiedData: any) {
    originData.forEach((originItem: any) => {
      const modifiedItem = modifiedData.find((item: any) => item.Id === originItem.Id);
  
      if (!modifiedItem) {
        // 如果 afterData 中不存在此項目，創建新的項目
        const newItem = { ...originItem, Title: `${originItem.Title}--(刪除)` }; // 使用 beforeItem 的 key，避免無限新增
        modifiedData.push(newItem);

        const originItemToUpdate = originData.find((item: any) => item.Id === originItem.Id);
        if (originItemToUpdate) {
          originItemToUpdate.Title = `${originItemToUpdate.Title}`;
        }
      } else if (originItem.Children) {
        syncFromBeforeToAfter(originItem.Children, modifiedItem.Children);
      }
    });
  }
  
  // 先同步 afterData 到 beforeData
  syncFromAfterToBefore(originData, modifiedData);
  // 再同步 beforeData 到 afterData
  syncFromBeforeToAfter(originData, modifiedData);

  // 用於比較項目是否不同
  const isItemDifferent = (itemId: string) => {
    const beforeData = originData.find((d) => d.Id === itemId);
    const afterData = modifiedData.find((d) => d.Id === itemId);
  
    const beforeLabel = beforeData?.Title;
    const afterLabel = afterData?.Title;
  
    const beforeChapterString = beforeData?.ChapterString;
    const afterChapterString = afterData?.ChapterString;
  
    return beforeLabel !== afterLabel || beforeChapterString !== afterChapterString;
  };

// 用於比較子項目是否不同
const isSubItemDifferent = (itemId: string, subItemId: string) => {
  const beforeData = originData
    .find((d) => d.Id === itemId)
    ?.Children?.find((c: any) => c.Id === subItemId);

  const afterData = modifiedData
    .find((d) => d.Id === itemId)
    ?.Children?.find((c: any) => c.Id === subItemId);

  const beforeLabel = beforeData?.Title;
  const afterLabel = afterData?.Title;

  const beforeChapterString = beforeData?.ChapterString;
  const afterChapterString = afterData?.ChapterString;

  return beforeLabel !== afterLabel || beforeChapterString !== afterChapterString;
};

// 用於比較子子項目是否不同
const isSubSubItemDifferent = (
  itemId: string,
  subItemId: string,
  subSubItemId: string
) => {
  const beforeData = originData
    .find((d) => d.Id === itemId)
    ?.Children?.find((c: any) => c.Id === subItemId)
    ?.Children?.find((sc: any) => sc.Id === subSubItemId);

  const afterData = modifiedData
    .find((d) => d.Id === itemId)
    ?.Children?.find((c: any) => c.Id === subItemId)
    ?.Children?.find((sc: any) => sc.Id === subSubItemId);

  const beforeLabel = beforeData?.Title;
  const afterLabel = afterData?.Title;

  const beforeChapterString = beforeData?.ChapterString;
  const afterChapterString = afterData?.ChapterString;

  return beforeLabel !== afterLabel || beforeChapterString !== afterChapterString;
};

// 用於比較子子子項目是否不同
const isSubSubSubItemDifferent = (
  itemId: string,
  subItemId: string,
  subSubItemId: string,
  subSubSubItemId: string,
) => {
  const beforeData = originData
    .find((d) => d.Id === itemId)
    ?.Children?.find((c: any) => c.Id === subItemId)
    ?.Children?.find((sc: any) => sc.Id === subSubItemId)
    ?.Children?.find((ssc:any) => ssc.Id === subSubSubItemId)

  const afterData = modifiedData
    .find((d) => d.Id === itemId)
    ?.Children?.find((c: any) => c.Id === subItemId)
    ?.Children?.find((sc: any) => sc.Id === subSubItemId)
    ?.Children?.find((ssc:any) => ssc.Id === subSubSubItemId)

  const beforeLabel = beforeData?.Title;
  const afterLabel = afterData?.Title;

  const beforeChapterString = beforeData?.ChapterString;
  const afterChapterString = afterData?.ChapterString;

  return beforeLabel !== afterLabel || beforeChapterString !== afterChapterString;
};

  

  return (
    <ContentModal {...modal}>
      <div className={`${styles["open-operatingRecord-modal-content"]}`}>
        <div className="beforeAfter_text_box">
          <div className="beforeAfter_text">編輯前</div>
          <div className="beforeAfter_text">編輯後</div>
        </div>

        <div className="result-box scroll">
          {/* 編輯前 */}
          <div className="beforeEditing" ref={beforeEditingRef}>
            {originData && originData.map((item, index) => (
              <div key={item.Id}>
                <div
                  className={
                    isItemDifferent(item.Id) ? "beforeItemDifferent" : "normal_text"
                  }
                >
                  {item.Title}
                </div>
                {item.Children && (
                  <div style={{ marginLeft: "22px" }}>
                    {item.Children.map((subItem: any) => (
                      <div key={subItem.Id}>
                        <div
                          className={
                            isSubItemDifferent(item.Id, subItem.Id)
                              ? "beforeItemDifferent"
                              : "normal_text"
                          }
                        >
                          {subItem.ChapterString} {subItem.Title}
                        </div>
                        {subItem.Children && (
                          <div style={{ marginLeft: "22px" }}>
                            {subItem.Children.map((subSubItem: any) => (
                              <div key={subSubItem.Id}>
                                <div
                                  className={
                                    isSubSubItemDifferent(
                                      item.Id,
                                      subItem.Id,
                                      subSubItem.Id
                                    )
                                      ? "beforeItemDifferent"
                                      : "normal_text"
                                  }
                                >
                                  {subSubItem.ChapterString} {subSubItem.Title}
                                </div>
                                {subSubItem.Children && (
                                  <div style={{ marginLeft: "22px" }}>
                                    {subSubItem.Children.map((subSubSubItem: any) => (
                                      <div
                                        key={subSubSubItem.Id}
                                        className={
                                          isSubSubSubItemDifferent(
                                            item.Id,
                                            subItem.Id,
                                            subSubItem.Id,
                                            subSubSubItem.Id
                                          )
                                            ? "beforeItemDifferent"
                                            : "normal_text"
                                        }
                                      >
                                        {subSubSubItem.ChapterString} {subSubSubItem.Title}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* 編輯後 */}
          <div className="afterEditing" ref={afterEditingRef}>
            {modifiedData && modifiedData.map((item) => (
              <div key={item.Id}>
                <div
                  className={
                    isItemDifferent(item.Id) ? "afterItemDifferent" : "normal_text"
                  }
                >
                  {item.Title}
                </div>
                {item.Children && (
                  <div style={{ marginLeft: "22px" }}>
                    {item.Children.map((subItem: any) => (
                      <div key={subItem.Id}>
                        <div
                          className={
                            isSubItemDifferent(item.Id, subItem.Id)
                              ? "afterItemDifferent"
                              : "normal_text"
                          }
                        >
                          {subItem.ChapterString} {subItem.Title}
                        </div>
                        {subItem.Children && (
                          <div style={{ marginLeft: "22px" }}>
                            {subItem.Children.map((subSubItem: any) => (
                              <div key={subSubItem.Id}>
                                <div
                                  className={`${
                                    isSubSubItemDifferent(
                                      item.Id,
                                      subItem.Id,
                                      subSubItem.Id
                                    )
                                      ? "afterItemDifferent"
                                      : "normal_text"
                                  } `}
                                >
                                  {subSubItem.ChapterString} {subSubItem.Title}
                                </div>
                                {subSubItem.Children && (
                                  <div style={{ marginLeft: "22px" }}>
                                    {subSubItem.Children.map((subSubSubItem: any) => (
                                      <div
                                        key={subSubSubItem.Id}
                                        className={`${
                                          isSubSubSubItemDifferent(
                                            item.Id,
                                            subItem.Id,
                                            subSubItem.Id,
                                            subSubSubItem.Id
                                          )
                                            ? "afterItemDifferent"
                                            : "normal_text"
                                        } `}
                                      >
                                        {subSubSubItem.ChapterString}{" "}
                                        {subSubSubItem.Title}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="scrollBarContainer">
            <div
              id="div3"
              className="scroll scrollBarBox"
              style={{
                height: `${scrollBarContainerHeight}px`,
                width: "10px",
                backgroundColor: "transparent",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  height: `${scrollBarDivHeight}px`,
                  width: "2px",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </ContentModal>
  );
};

export default OperatingRecordModal;
