import { SideBarItemModel, NavBarItemModel } from '../../models/baseModel';
//* ---------------------------- Common start ---------------------------- *//

export interface PermissionRouteObjectModel {
	path: string;
	element: JSX.Element;
	pagePermission?: string | boolean;
	featurePermission?: string | boolean;
	actionReadPermission?: string | boolean;
	children?: PermissionRouteObjectModel[];
}

export interface PermissionSideBarItemModel extends SideBarItemModel {
	permission: string;
}

export interface PermissionNavBarItemModel extends NavBarItemModel {
	permission: string;
}

//* ---------------------------- Common end  ---------------------------- *//

//* ---------------------------- Page start ---------------------------- *//

export enum PagePermissionCodes {
	//> 管理後台
	//* 首頁
	Home = 'home',
	//* 申報資料管理
	DeclareDataManage = 'declare-data-manage',
	//* 指標管理
	IndicatorManage = 'indicator-manage',
	//* 報告書底稿管理
	ReportManage = 'report-manage',
	//* 行業別管理
	IndustryManage = 'industry-manage',
	//* 永續矩陣管理
	SustainMatrixManage = 'sustain-matrix-manage',
	//* 問卷管理
	QuestionnaireManage = 'questionnaire-manage',
	//* 教育宣導管理
	EduPropagandaManage = 'edu-resource-manage',
	//* 角色管理
	RoleManage = 'role-manage',
	//* 系統管理
	SystemManage = 'system-manage',
	//> 申報前台
	//* 永續報告書產製
	SustainReportProduceModule = 'sustain-report-produce-module',
}

//* ---------------------------- Page end  ---------------------------- *//

//* ---------------------------- Feature start ---------------------------- *//
//> 管理後台 start --------------------------------------------
//* 首頁
export enum HomeFeaturePermissionCodes {
	//- Dashboard
	Dashboard = 'home/dashboard',
}

//* 申報資料管理
export enum DeclareDataManageFeaturePermissionCodes {
	//- 異動申請管理
	CorrectionRequestManage = 'declare-data-manage/correction-request-manage',
	//- ESG資訊揭露
	IndicatorDeclareManage = 'declare-data-manage/indicator-declare-manage',
	//- 永續報告書
	SustainReportDeclareManage = 'declare-data-manage/sustain-report-declare-manage',
	//- 溫室氣體排放及減量資訊
	GhgDeclareManage = 'declare-data-manage/ghg-declare-manage',
	//- 匯出檔案
	ExportCsvManage = 'declare-data-manage/export-csv-manage',
	//- 報告書產製
	SustainReportProduceManage = 'declare-data-manage/sustain-report-produce-manage',
}

//* ESG指標管理
export enum IndicatorManageFeaturePermissionCodes {
	//- 指標列表
	IndicatorList = 'indicator-manage/indicator-list',
	//- 指標設定
	IndicatorSetting = 'indicator-manage/indicator-setting',
	//- 必要揭露指標設定
	NecessaryIndicator = 'indicator-manage/necessary-indicator',
}

//* 報告書底稿管理
export enum ReportManageFeaturePermissionCodes {
	//- 報告書底稿列表
	ReportList = 'report-manage/report-list',
	//- 中文版
	ReportCn = 'report-manage/report-cn',
	//- 英文版
	ReportEn = 'report-manage/report-en',
}

//* 行業別管理
export enum IndustryManageFeaturePermissionCodes {
	//- 行業別年度列表
	IndustryYearList = 'industry-manage/industry-year-list',
	//- 產業類別
	IndustryCategory = 'industry-manage/industry-category',
	//- 公司管理
	CompanyManage = 'industry-manage/company-manage',
}

//* 永續矩陣管理
export enum SustainMatrixManageFeaturePermissionCodes {
	//- 永續矩陣管理
	MatrixManage = 'sustain-matrix-manage/matrix-manage',
}

//* 問卷管理
export enum QuestionnaireManageFeaturePermissionCodes {
	//- 問卷設定
	QuestionnaireSetting = 'questionnaire-manage/questionnaire-setting',
	//- 問卷回復
	QuestionnaireReply = 'questionnaire-manage/questionnaires-reply',
}

//* 教育宣導資源管理
export enum PropagandaManageFeaturePermissionCodes {
	//- 類型管理
	EduResourceDirectory = 'edu-resource-manage/edu-resource-directory',
	//- 檔案管理
	EduResourceFile = 'edu-resource-manage/edu-resource-file',
}

//* 角色管理
export enum RoleManageFeaturePermissionCodes {
	//- 角色管理
	RoleManage = 'role-manage/role-manage',
}

//* 系統管理
export enum SystemManageFeaturePermissionCodes {
	//- 系統功能管理
	SystemFunctionManage = 'system-manage/system-function-manage',
	//- 系統操作紀錄
	SystemLog = 'system-manage/system-log',
}

//> 管理後台 end --------------------------------------------

export const FeaturePermissionCodes = {
	//> 管理後台
	//* 首頁
	Home: HomeFeaturePermissionCodes,
	//* 申報資料管理
	DeclareDataManage: DeclareDataManageFeaturePermissionCodes,
	//* ESG指標管理
	IndicatorManage: IndicatorManageFeaturePermissionCodes,
	//* 報告書底稿管理
	ReportManage: ReportManageFeaturePermissionCodes,
	//* 行業別管理
	IndustryManage: IndustryManageFeaturePermissionCodes,
	//* 永續矩陣管理
	SustainMatrixManage: SustainMatrixManageFeaturePermissionCodes,
	//* 問卷管理
	QuestionnaireManage: QuestionnaireManageFeaturePermissionCodes,
	//* 教育宣導管理
	EduPropagandaManage: PropagandaManageFeaturePermissionCodes,
	//* 角色管理
	RoleManage: RoleManageFeaturePermissionCodes,
	//* 系統管理
	SystemManage: SystemManageFeaturePermissionCodes,
};

//* ---------------------------- Feature end  ---------------------------- *//

//* ---------------------------- Action start ---------------------------- *//
//> 管理後台 start --------------------------------------------
//* 首頁
export enum HomeActionsPermissionCodes {
	//- 檢視
	DashboardRead = 'dashboard/read',
}

//* 申報資料管理
export enum DeclareDataManageActionsPermissionCodes {
	//- 異動申請管理 - 檢視
	CorrectionRequestManageRead = 'correction-request-manage/read',
	//- 異動申請管理 - 權限開啟
	CorrectionRequestManageSetPermission = 'correction-request-manage/set-permission',
	//- ESG資訊揭露 - 檢視
	IndicatorDeclareManageRead = 'indicator-declare-manage/read',
	//- ESG資訊揭露 - 權限開啟
	IndicatorDeclareManageSetPermission = 'indicator-declare-manage/set-permission',
	//- 永續報告書 - 檢視
	SustainReportDeclareManageRead = 'sustain-report-declare-manage/read',
	//- 永續報告書 - 權限開啟
	SustainReportDeclareManageSetPermission = 'sustain-report-declare-manage/set-permission',
	//- 溫室氣體排放及減量資訊 - 檢視
	GhgDeclareManageRead = 'ghg-declare-manage/read',
	//- 溫室氣體排放及減量資訊 - 權限開啟
	GhgDeclareManageSetPermission = 'ghg-declare-manage/set-permission',
	//- 匯出檔案 - 檢視
	ExportCsvManageRead = 'export-csv-manage/read',
	//- 報告書產製資訊 - 檢視
	SustainReportProduceManageRead = 'sustain-report-produce-manage/read',
	//- 報告書產製資訊 - 權限開啟
	SustainReportProduceManageSetPermission = 'sustain-report-produce-manage/set-permission',
}

//* 指標管理
export enum IndicatorManageActionsPermissionCodes {
	//- 指標列表 - 檢視
	IndicatorListRead = 'indicator-list/read',
	//- 指標列表 - 新增
	IndicatorListCreate = 'indicator-list/create',
	//- 指標列表 - 編輯
	IndicatorListUpdate = 'indicator-list/update',
	//- 指標列表 - 刪除
	IndicatorListDelete = 'indicator-list/delete',
	//- 指標設定 - 檢視
	IndicatorSettingRead = 'indicator-setting/read',
	//- 指標設定 - 新增
	IndicatorSettingCreate = 'indicator-setting/create',
	//- 指標設定 - 編輯
	IndicatorSettingUpdate = 'indicator-setting/update',
	//- 指標設定 - 刪除
	IndicatorSettingDelete = 'indicator-setting/delete',
	//- 指標設定 - 狀態變更
	IndicatorSettingSetStatus = 'indicator-setting/set-status',
	//- 指標設定 - 檔案匯入
	IndicatorSettingImport = 'indicator-setting/import',
	//- 必要揭露指標設定 - 檢視
	NecessaryIndicatorRead = 'necessary-indicator/read',
	//- 必要揭露指標設定 - 編輯
	NecessaryIndicatorUpdate = 'necessary-indicator/update',
}

//* 報告書底稿管理
export enum ReportManageActionsPermissionCodes {
	//- 報告書底稿列表 - 檢視
	ReportListRead = 'report-list/read',
	//- 報告書底稿列表 - 新增
	ReportListCreate = 'report-list/create',
	//- 報告書底稿列表 - 編輯
	ReportListUpdate = 'report-list/update',
	//- 報告書底稿列表 - 刪除
	ReportListDelete = 'report-list/delete',
	//- 中文版 - 檢視
	ReportCnRead = 'report-cn/read',
	//- 中文版 - 編輯
	ReportCnUpdate = 'report-cn/update',
	//- 英文版 - 檢視
	ReportEnRead = 'report-en/read',
	//- 英文版 - 編輯
	ReportEnUpdate = 'report-en/update',
}

//* 行業別管理
export enum IndustryManageActionsPermissionCodes {
	//- 行業別年度列表 - 檢視
	IndustryYearListRead = 'industry-year-list/read',
	//- 行業別年度列表 - 刪除
	IndustryYearListDelete = 'industry-year-list/delete',
	//- 產業類別 - 檢視
	IndustryCategoryRead = 'industry-category/read',
	//- 產業類別 - 新增
	IndustryCategoryCreate = 'industry-category/create',
	//- 產業類別 - 編輯
	IndustryCategoryUpdate = 'industry-category/update',
	//- 產業類別 - 刪除
	IndustryCategoryDelete = 'industry-category/delete',
	//- 產業類別 - 批次匯入
	IndustryCategoryImport = 'industry-category/import',
	//- 公司管理 - 檢視
	CompanyManageRead = 'company-manage/read',
	//- 公司管理 - 新增
	CompanyManageCreate = 'company-manage/create',
	//- 公司管理 - 編輯
	CompanyManageUpdate = 'company-manage/update',
	//- 公司管理 - 刪除
	CompanyManageDelete = 'company-manage/delete',
	//- 公司管理 - 批次匯入
	CompanyManageImport = 'company-manage/import',
}

//* 永續矩陣管理
export enum SustainMatrixManageActionsPermissionCodes {
	//- 永續矩陣管理 - 檢視
	MatrixManageRead = 'matrix-manage/read',
	//- 永續矩陣管理 - 新增
	MatrixManageCreate = 'matrix-manage/create',
	//- 永續矩陣管理 - 編輯
	MatrixManageUpdate = 'matrix-manage/update',
	//- 永續矩陣管理 - 刪除
	MatrixManageDelete = 'matrix-manage/delete',
	//- 永續矩陣管理 - 批次匯入
	MatrixManageImport = 'matrix-manage/import',
}

//* 問卷管理
export enum QuestionnaireManageActionsPermissionCodes {
	//- 問卷設定 - 檢視
	QuestionnaireSettingRead = 'questionnaire-setting/read',
	//- 問卷設定 - 新增
	QuestionnaireSettingCreate = 'questionnaire-setting/create',
	//- 問卷設定 - 編輯
	QuestionnaireSettingUpdate = 'questionnaire-setting/update',
	//- 問卷設定 - 刪除
	QuestionnaireSettingDelete = 'questionnaire-setting/delete',
	//- 問卷設定 - 狀態變更
	QuestionnaireSettingSetStatus = 'questionnaire-setting/set-status',
	//- 問卷回復 - 檢視
	QuestionnaireReplyRead = 'questionnaire-reply/read',
}

//* 教育宣導管理
export enum EduPropagandaManageActionsPermissionCodes {
	//- 類型管理 - 檢視
	EduPropagandaCategoryRead = 'edu-resource-directory/read',
	//- 類型管理 - 新增
	EduPropagandaCategoryCreate = 'edu-resource-directory/create',
	//- 類型管理 - 編輯
	EduPropagandaCategoryUpdate = 'edu-resource-directory/update',
	//- 類型管理 - 刪除
	EduPropagandaCategoryDelete = 'edu-resource-directory/delete',
	//- 檔案管理 - 檢視
	EduPropagandaFileRead = 'edu-resource-file/read',
	//- 檔案管理 - 新增
	EduPropagandaFileCreate = 'edu-resource-file/create',
	//- 檔案管理 - 編輯
	EduPropagandaFileUpdate = 'edu-resource-file/update',
	//- 檔案管理 - 刪除
	EduPropagandaFileDelete = 'edu-resource-file/delete',
}

//* 角色管理
export enum RoleManageActionsPermissionCodes {
	//- 角色管理 - 檢視
	RoleManageRead = 'role-manage/read',
	//- 角色管理 - 新增
	RoleManageCreate = 'role-manage/create',
	//- 角色管理 - 編輯
	RoleManageUpdate = 'role-manage/update',
	//- 角色管理 - 刪除
	RoleManageDelete = 'role-manage/delete',
}

//* 系統管理
export enum SystemManageActionsPermissionCodes {
	//- 系統管理 - 檢視
	SystemFunctionManageRead = 'system-function-manage/read',
	//- 系統管理 - 狀態變更
	SystemFunctionManageSetStatus = 'system-function-manage/set-status',
	//- 系統紀錄 - 檢視
	SystemLogRead = 'system-log/read',
}
//> 管理後台 end --------------------------------------------

export const ActionsPermissionCodes = {
	//> 管理後台
	Home: HomeActionsPermissionCodes,
	DeclareDataManage: DeclareDataManageActionsPermissionCodes,
	IndicatorManage: IndicatorManageActionsPermissionCodes,
	ReportManage: ReportManageActionsPermissionCodes,
	IndustryManage: IndustryManageActionsPermissionCodes,
	SustainMatrixManage: SustainMatrixManageActionsPermissionCodes,
	QuestionnaireManage: QuestionnaireManageActionsPermissionCodes,
	RoleManage: RoleManageActionsPermissionCodes,
	SystemManage: SystemManageActionsPermissionCodes,
	EduPropagandaManage: EduPropagandaManageActionsPermissionCodes,
};

//* ---------------------------- Action end  ---------------------------- *//
