import {  ManuscriptRequestModel, SustainRreportManuscriptManageDataModel, SustainRreportManuscriptManageListModel, SustainRreportManuscriptModel, SustainReportDefaultTreeNodeRequestModel,SustainReportManuscriptDefaultDataModel } from "models/admin/manuscriptModel";
import { HttpClient } from "services/httpClient";


const httpClient = new HttpClient();

/** 取得永續報告書底稿清單 */
const getManuscriptManageList = (data:ManuscriptRequestModel) => {
    return httpClient.post(`SustainRreportManuscriptManage/list`,data);
}

/** 批次編輯永續報告書底稿清單 */
const editManuscriptManageList = (data: SustainRreportManuscriptManageListModel[]) => {
    return httpClient.post(`SustainRreportManuscriptManage/editList`, data);
}

/** 取得報告書底稿管理資訊 */
const getManuscriptManageDataById = (manageId:string)=>{
    return httpClient.get(`SustainRreportManuscriptManage/data?manageId=${manageId}`);
}

/** 新增報告書底稿管理資訊 */
const addManuscriptManageData = (data:SustainRreportManuscriptModel)=>{
    return httpClient.post(`SustainRreportManuscriptManage/data`, data);
}

/** 批次刪除報告書底稿管理資訊 */
const deleteManuscriptManageDataByIds = (manageIds: string[])=>{
    return httpClient.post(`SustainRreportManuscriptManage/deleteData`, manageIds);
}

/** 批次複製報告書底稿管理資訊 */
const copyManuscriptManageDataByIds = (manageIds: string[])=>{
    return httpClient.post(`SustainRreportManuscriptManage/copy`, manageIds);
}

/** 編輯報告書底稿管理資訊 */
const editManuscriptManageData = (data:SustainRreportManuscriptManageDataModel)=>{
    return httpClient.post(`SustainRreportManuscriptManage/editData`, data);
}

/** 取得章節預設樹狀表 */
const getDefaultSustainReportTree = (manageId: string)=>{
  return httpClient.get(`SustainRreportManuscriptManage/defaultTree?manageId=${manageId}`);
}

/** 編輯章節樹狀表 */
const editSustainReportTree = (data: SustainReportDefaultTreeNodeRequestModel)=>{
  return httpClient.post(`SustainRreportManuscriptManage/defaultTree`, data);
}

/** 取得flat章節預設樹狀表 */
const getDefaultSustainReportFlatTree = (manageId: string)=>{
  return httpClient.get(`SustainRreportManuscriptManage/defaultTreeFlat?manageId=${manageId}`);
}

/** 取得章節預設Data */
const getDefaultChapterData = (defaultDataId: string | null, defaultId:string)=>{
  if (defaultDataId) {
    return httpClient.get(`SustainRreportManuscriptManage/defaultData?defaultDataId=${defaultDataId}&defaultId=${defaultId}`);
  }
  return httpClient.get(`SustainRreportManuscriptManage/defaultData?defaultId=${defaultId}`);
}

/** 編輯章節預設Data */
const editDefaultChapterData = (request: SustainReportManuscriptDefaultDataModel)=>{
  return httpClient.post(`SustainRreportManuscriptManage/defaultData`, request);
}

/** 取得 Gri Standard */
const getGriStandard = ()=>{
  return httpClient.get(`SustainRreportManuscriptManage/griStandard`);
}

/** 櫃買同步年度底稿 */
const replaceOtcYear = (manageId: string) => {
  return httpClient.post(`SustainRreportManuscriptManage/replaceOtcYear?manageId=${manageId}`);
}

/** 取得 必要揭露章節設定 */
const getDisclosureSetting = (year: string | null, type: number)=>{
    year = year === null ? '' : year;
  return httpClient.get(`SustainRreportManuscriptManage/necessary?year=${year}&marketType=${type}`);
}

const editDisclosureSetting = (data: any, manageId: string | null) => {
  return httpClient.post(`SustainRreportManuscriptManage/necessary/${manageId}`, data);
}

const ManuscriptService = {
    getManuscriptManageList,
    editManuscriptManageList,
    getManuscriptManageDataById,
    addManuscriptManageData,
    deleteManuscriptManageDataByIds,
    copyManuscriptManageDataByIds,
    editManuscriptManageData,
    getDefaultSustainReportTree,
    editSustainReportTree,
    getDefaultSustainReportFlatTree,
    getDefaultChapterData,
    editDefaultChapterData,
    getGriStandard,
    replaceOtcYear,
    getDisclosureSetting,
    editDisclosureSetting
}

export default ManuscriptService;
