//* ---------------------------- React start ---------------------------- *//
import { FC, useEffect, useState } from 'react';
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import moment from 'moment';
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from './TransactionRequest.module.scss';
import Dropdown from 'components/base/Dropdown/Dropdown';
import { OptionModel, BaseResponse } from 'models/baseModel';
import ScrollToTopButton from 'components/button/ScrollToTopButton/ScrollToTopButton';
import DeclarationService from 'services/admin/declarationService';
import SearchInput from 'components/base/SearchInput/SearchInput';
import {
	DeclareDataRequestModel,
	RequestTypeEnum,
	CorrectionStatusEnum,
	DeclareDataMangeModel,
} from 'models/admin/declarationModel';
import VisuallLoading from 'components/common/VisuallLoading/VisuallLoading';
import { success2Alert, error2Alert, successAlert } from 'utils/otherToast';
import ConfirmModal, { ConfirmModalProps } from 'components/base/ConfirmModal/ConfirmModal';
import TablePagination from 'components/base/TablePagination/TablePagination';
import { PaginationModel } from 'models/baseModel';
import mopsEsgService from 'services/inquiry/infoDisclosure/mopsEsgService';
import { CommonService } from 'services/common/commonService';
import { AxiosResponse } from 'axios';
import { ResponseCode } from 'models/responseCodeModel';
import useUserInfoStore from 'state/useUserInfoStore';
import { usePermissionContext } from 'context/PermissionProvider';
import { DeclareDataManageActionsPermissionCodes } from 'models/auth/permissionModel';
import LinkIconButton from 'components/button/LinkIconButton/LinkIconButton';
//* ---------------------------- local end ---------------------------- *//

//- setDeclareDataRequestModel props
const OperationHeaderComponent: FC<{
	declareDataRequestModel: DeclareDataRequestModel;
	setDeclareDataRequestModel: React.Dispatch<React.SetStateAction<DeclareDataRequestModel>>;
	declareYearOptions: OptionModel[];
	requestTypeOptions: OptionModel[];
	correctionStatusOptions: OptionModel[];
	getDeclareDataCorrectionRequestExcel: UseMutationResult<
		AxiosResponse<any, any>,
		Error,
		DeclareDataRequestModel,
		unknown
	>;
}> = ({
	declareDataRequestModel,
	setDeclareDataRequestModel,
	declareYearOptions,
	requestTypeOptions,
	correctionStatusOptions,
	getDeclareDataCorrectionRequestExcel,
}) => {
	//* ---------------------------- state start ---------------------------- *//

	//* ---------------------------- state end ---------------------------- *//

	return (
		<div className={`${styles['operation-header']}`}>
			<div className={`left`}>
				<div className={`select-item`} style={{ width: '160px' }}>
					<Dropdown
						placeholder={'年度'}
						defaultId={
							declareDataRequestModel.declareYear === undefined
								? 'undefined'
								: declareDataRequestModel.declareYear?.toString()
						}
						options={declareYearOptions}
						isFloatTitle={true}
						clearIconDisplay={false}
						onChange={(e) => {
							setDeclareDataRequestModel((prev) => ({
								...prev,
								declareYear: declareYearOptions.find((o) => o.id === e)?.enumKey ?? undefined,
							}));
						}}
					/>
				</div>
				<div className={`select-item`} style={{ width: '240px' }}>
					{/* <span>類別</span> */}
					<Dropdown
						placeholder={'類別'}
						defaultId={
							declareDataRequestModel.requestType === undefined
								? 'undefined'
								: declareDataRequestModel.requestType?.toString()
						}
						options={requestTypeOptions}
						isFloatTitle={true}
						clearIconDisplay={false}
						onChange={(e) => {
							setDeclareDataRequestModel((prev) => ({
								...prev,
								requestType: requestTypeOptions.find((o) => o.id === e)?.enumKey ?? undefined,
							}));
						}}
					/>
				</div>
				<div className={`select-item`} style={{ width: '160px' }}>
					{/* <span>狀態</span> */}
					<Dropdown
						placeholder={'狀態'}
						defaultId={
							declareDataRequestModel.status === undefined ? 'undefined' : declareDataRequestModel.status?.toString()
						}
						options={correctionStatusOptions}
						isFloatTitle={true}
						clearIconDisplay={false}
						onChange={(e) => {
							setDeclareDataRequestModel((prev) => ({
								...prev,
								status: correctionStatusOptions.find((o) => o.id === e)?.enumKey ?? undefined,
							}));
						}}
					/>
				</div>
				<div className={`select-item`} style={{ width: '300px' }}>
					<SearchInput
						onClick={(e) => {
							setDeclareDataRequestModel((prev) => ({
								...prev,
								companyInfo: e,
							}));
						}}
						placeholder={'搜尋公司代號/公司名稱'}
					/>
				</div>
			</div>
			<div className={`right`}>
				<div className={`select-item`}>
					<LinkIconButton
						imgName='export-icon.svg'
						text='匯出 Excel'
						onClick={() => {
							getDeclareDataCorrectionRequestExcel.mutate(declareDataRequestModel);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

const TableComponent: FC<{
	declareDataCorrectionRequestList: DeclareDataMangeModel[] | undefined;
	isLoading: boolean;
	requestTypeOptions: OptionModel[];
	correctionStatusOptions: OptionModel[];
	openDeclareDataCorrectionRequestPermission: UseMutationResult<BaseResponse<any>, Error, string, unknown>;
	setDeclareDataRequestModel: React.Dispatch<React.SetStateAction<DeclareDataRequestModel>>;
}> = ({
	declareDataCorrectionRequestList,
	isLoading,
	requestTypeOptions,
	correctionStatusOptions,
	openDeclareDataCorrectionRequestPermission,
	setDeclareDataRequestModel,
}) => {
	//* ---------------------------- state start ---------------------------- *//
	const [selectItemId, setSelectItemId] = useState<string>();
	const [modal, setModal] = useState<ConfirmModalProps>({
		show: false,
		handleClose: () => {},
		handleConfirm: () => {},
	});
	const { PermissionService } = usePermissionContext();
	const { detectEveryActionPermission } = PermissionService;
	//* ---------------------------- state end ---------------------------- *//

	//- setModal
	useEffect(() => {
		setModal((prev) => ({
			...prev,
			loading: openDeclareDataCorrectionRequestPermission.isPending,
			handleClose: () => {
				setSelectItemId(undefined);
				setModal((prev) => ({
					...prev,
					show: false,
				}));
			},
			handleConfirm: () => {
				console.log('selectItemId', selectItemId);
				openDeclareDataCorrectionRequestPermission.mutate(selectItemId!, {
					onSuccess: (res) => {
						console.log('openDeclareDataCorrectionRequestPermission!!!!');
						if (res.code === 200 && res.success) {
							setSelectItemId(undefined);
							setModal((prev) => ({
								...prev,
								show: false,
							}));
						}
					},
				});
			},
		}));
	}, [modal.show, selectItemId, openDeclareDataCorrectionRequestPermission]);

	//- handleSort
	const handleSort = (sortKey: string) => {
		setDeclareDataRequestModel((prev) => ({
			...prev,
			sortType: prev.sortKey === sortKey ? !prev.sortType : prev.sortType ?? true,
			sortKey: sortKey,
		}));
	};

	return (
		<div className={`${styles['transaction-table-box']} transaction-table-scroll scroll`}>
			<ConfirmModal {...modal}>
				<div>確認是否開啟權限​?</div>
			</ConfirmModal>
			{isLoading ? <VisuallLoading /> : null}

			<ScrollToTopButton targetClassName={'transaction-table-scroll'} bottom={90} right={35} />
			<table
				aria-label='table'
				className={`${styles['transaction-table']} ${styles['transaction-table-uni']} table-container sticky-table`}>
				<thead>
					<tr>
						<th scope='col'>
							<div className='cur-pointer' onClick={() => handleSort('RequestType')}>
								<span>類別</span>
								<img src='/assets/images/buttonIcon/sort-icon.svg' alt='sort-icon.svg' />
							</div>
						</th>
						<th scope='col'>
							<div className='cur-pointer' onClick={() => handleSort('CompanyCode')}>
								<span>公司代號</span>
								<img src='/assets/images/buttonIcon/sort-icon.svg' alt='sort-icon.svg' />
							</div>
						</th>
						<th scope='col'>公司名稱</th>
						<th scope='col'>申請日期</th>
						<th scope='col'>申請原因</th>
						<th scope='col'>權限開通時間</th>
						<th scope='col'>重新申報日期</th>
						<th scope='col'>狀態</th>
						<th scope='col'>權限開啟​</th>
					</tr>
				</thead>
				<tbody>
					{declareDataCorrectionRequestList?.length === 0 ? (
						<tr>
							<td colSpan={9}>查無資料</td>
						</tr>
					) : (
						declareDataCorrectionRequestList?.map((item, index) => (
							<tr key={index}>
								<td>{requestTypeOptions.find((e) => e.enumKey === item.requestType)?.text}</td>
								<td>{item.companyCode}</td>
								<td>{item.companyName}</td>
								<td>{item.applyDate ? moment(item.applyDate).format('yyyy-MM-DD') : '-'}</td>
								<td>{item.applyReason}</td>
								<td>
									{item.permissionActivationTime
										? moment(item.permissionActivationTime).format('yyyy-MM-DD HH:mm')
										: '-'}
								</td>
								<td>{item.editDeclareDate ? moment(item.editDeclareDate).format('yyyy-MM-DD') : '-'}</td>
								<td>{correctionStatusOptions.find((e) => e.enumKey === item.status)?.text}</td>
								<td>
									{detectEveryActionPermission([
										DeclareDataManageActionsPermissionCodes.CorrectionRequestManageSetPermission,
									]) && !item.permissionActivationTime ? (
										<button
											className='secondary'
											onClick={() => {
												setSelectItemId(item.id);
												setModal((prev) => ({
													...prev,
													title: '確認',
													show: true,
												}));
											}}>
											開通
										</button>
									) : null}
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>
		</div>
	);
};

const TransactionRequest: FC = () => {
	//* ---------------------------- state start ---------------------------- *//
	const [declareYearOptions, setDeclareYearOptions] = useState<OptionModel[]>([]);
	// const [declareYearOptions] = useState<OptionModel[]>(() => {
	//   const declareYearOptionsList = [];
	//   const today = new Date();
	//   for (let i = today.getFullYear(); i >= 2021; i--) {
	//     declareYearOptionsList.push({
	//       id: i.toString(),
	//       text: `${i.toString()} 年度`,
	//       enumKey: i,
	//     });
	//   }
	//   return [
	//     {
	//       id: "undefined",
	//       text: "全部",
	//       enumKey: undefined,
	//     },
	//     ...declareYearOptionsList,
	//   ];
	// });
	const [requestTypeOptions] = useState<OptionModel[]>(() => {
		const enumValues = Object.values(RequestTypeEnum);
		const requestTypeDescriptions: Record<RequestTypeEnum, string> = {
			[RequestTypeEnum.SustainReport]: '永續報告書',
			[RequestTypeEnum.GhgEmissionAndReduction]: '溫室氣體排放及減量',
			[RequestTypeEnum.ESGDisclosure]: 'ESG資訊揭露',
		};

		const requestTypeOptionsList = enumValues
			.filter((e) => !isNaN(Number(e)))
			.map((key) => ({
				id: key.toString(),
				text: requestTypeDescriptions[key as RequestTypeEnum],
				enumKey: key,
			})) as OptionModel[];

		return [
			{
				id: 'undefined',
				text: '全部',
				enumKey: undefined,
			},
			...requestTypeOptionsList,
		];
	});
	const [correctionStatusOptions] = useState<OptionModel[]>(() => {
		const enumValues = Object.values(CorrectionStatusEnum);
		const correctionStatusDescriptions: Record<CorrectionStatusEnum, string> = {
			[CorrectionStatusEnum.AppliedUnactivated]: '已申請未開通',
			[CorrectionStatusEnum.ActivatedUndeclared]: '已開通未申報',
			[CorrectionStatusEnum.Declared]: '已重新申報',
		};

		const correctionStatusOptionsList = enumValues
			.filter((e) => !isNaN(Number(e)))
			.map((key) => ({
				id: key.toString(),
				text: correctionStatusDescriptions[key as CorrectionStatusEnum],
				enumKey: key,
			})) as OptionModel[];

		return [
			{
				id: 'undefined',
				text: '全部',
				enumKey: undefined,
			},
			...correctionStatusOptionsList,
		];
	});
	const [declareDataRequestModel, setDeclareDataRequestModel] = useState<DeclareDataRequestModel>({
		page: 1,
		pageSize: 10,
		searchKey: null,
		sortKey: null,
		sortType: undefined,
		declareYear: undefined,
		requestType: undefined,
		status: undefined,
		companyInfo: undefined,
	});
	const [declareDataCorrectionRequestList, setDeclareDataCorrectionRequestList] = useState<{
		count: number;
		list: DeclareDataMangeModel[];
	}>({
		count: 0,
		list: [],
	});
	const [tablePagination, setTablePagination] = useState<PaginationModel>({
		page: 1,
		pageSize: 10,
		total: 0,
	});
	//* ---------------------------- state end ---------------------------- *//

	//- getDeclareDataCorrectionRequestList
	const { mutate: getDeclareDataCorrectionRequestListMutate, isPending: getDeclareDataCorrectionRequestListIsPending } =
		useMutation({
			mutationFn: (request: DeclareDataRequestModel) => DeclarationService.getDeclareDataCorrectionRequestList(request),
			onSuccess: (res) => {
				console.log('getDeclareDataCorrectionRequestList', res);
				if (res.code === 200 && res.success) {
					setDeclareDataCorrectionRequestList({
						count: res.count!,
						list: res.data,
					});
				} else {
					setDeclareDataCorrectionRequestList({
						count: 0,
						list: [],
					});
				}
			},
			onError: (err) => {
				console.log('getDeclareDataCorrectionRequestList', err);
			},
		});

	//- getDeclareDataCorrectionRequestExcel
	const getDeclareDataCorrectionRequestExcel = useMutation({
		mutationFn: (request: DeclareDataRequestModel) => DeclarationService.getDeclareDataCorrectionRequestExcel(request),
		onSuccess: (res) => {
			if (res.status !== ResponseCode.ServerErrorInternal) {
				successAlert('下載成功');
				CommonService.downloadByStream(res);
			} else {
				error2Alert(res.data.message);
			}
		},
		onError: (err) => {
			console.log('getDeclareDataCorrectionRequestExcel', err);
			error2Alert('執行失敗');
		},
	});

	//- openDeclareDataCorrectionRequestPermission
	const openDeclareDataCorrectionRequestPermission = useMutation({
		mutationFn: (id: string) => DeclarationService.openDeclareDataCorrectionRequestPermission(id),
		onSuccess: (res) => {
			console.log('openDeclareDataCorrectionRequestPermission', res);
			if (res.code === 200 && res.success) {
				success2Alert(res.message);
				getDeclareDataCorrectionRequestListMutate(declareDataRequestModel);
			} else {
				error2Alert(res.message);
			}
		},
		onError: (err) => {
			console.log('openDeclareDataCorrectionRequestPermission', err);
			error2Alert('執行失敗');
		},
	});

	//- getYearList
	const { mutate: getYearListMutate, isPending: getYearListIsPending } = useMutation({
		mutationFn: () => DeclarationService.getYearList(),
		onSuccess: (res) => {
			console.log('getYearList', res);
			if (res.code === 200 && res.success) {
				const declareYearOptionsList = res.data
					.filter((e: number, i: number, self: number[]) => self.indexOf(e) === i)
					.map((e: number) => ({
						id: e.toString(),
						text: `${e.toString()} 年度`,
						enumKey: e,
					})) as OptionModel[];
				setDeclareYearOptions([
					{
						id: 'undefined',
						text: '全部',
						enumKey: undefined,
					},
					...declareYearOptionsList,
				]);
			}
		},
		onError: (err) => {
			console.log('getYearList', err);
			error2Alert('執行失敗');
		},
	});

	// -useEffect declareDataRequestModel
	useEffect(() => {
		getDeclareDataCorrectionRequestListMutate(declareDataRequestModel);
		getYearListMutate();
	}, [declareDataRequestModel, getDeclareDataCorrectionRequestListMutate, getYearListMutate]);

	// -useEffect declareDataCorrectionRequestList
	useEffect(() => {
		setTablePagination((prev) => {
			if (declareDataCorrectionRequestList?.list.length) {
				return {
					...prev,
					page: declareDataRequestModel.page ?? 1,
					pageSize: declareDataRequestModel.pageSize ?? 10,
					total: declareDataCorrectionRequestList.count,
				};
			} else {
				return {
					...prev,
					page: 0,
					total: 0,
				};
			}
		});
	}, [declareDataCorrectionRequestList, declareDataRequestModel]);

	return (
		<div className={`${styles['transaction-request-box']}`}>
			{getDeclareDataCorrectionRequestExcel.isPending || getYearListIsPending ? <VisuallLoading /> : null}
			<OperationHeaderComponent
				declareDataRequestModel={declareDataRequestModel}
				setDeclareDataRequestModel={setDeclareDataRequestModel}
				declareYearOptions={declareYearOptions}
				requestTypeOptions={requestTypeOptions}
				correctionStatusOptions={correctionStatusOptions}
				getDeclareDataCorrectionRequestExcel={getDeclareDataCorrectionRequestExcel}
			/>
			<TableComponent
				declareDataCorrectionRequestList={declareDataCorrectionRequestList.list}
				isLoading={getDeclareDataCorrectionRequestListIsPending}
				requestTypeOptions={requestTypeOptions}
				correctionStatusOptions={correctionStatusOptions}
				openDeclareDataCorrectionRequestPermission={openDeclareDataCorrectionRequestPermission}
				setDeclareDataRequestModel={setDeclareDataRequestModel}
			/>
			<div className={`${styles['table-pagination-box']}`}>
				<TablePagination
					total={tablePagination.total!}
					onChange={(e) => {
						setDeclareDataRequestModel((prev) => ({
							...prev,
							page: e.page,
							pageSize: e.pageSize,
						}));
					}}
					page={tablePagination.page}
					pageSize={tablePagination.pageSize}
				/>
			</div>
		</div>
	);
};

export default TransactionRequest;
