import React, { useEffect, useState, useRef } from "react";
import styles from "./NecessarySettingModal.module.scss";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
import { useMutation } from "@tanstack/react-query";
import OperatingRecordService from "services/admin/operatingRecordService"
import {  } from "models/admin/operatingRecordModel"
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { SettingModel,} from "models/admin/operatingRecordModel"
import ManuscriptService from "services/admin/manuscriptService";
import { error2Alert, success2Alert } from "utils/otherToast";

export interface NecessarySettingModalProps {
 show: boolean;
 onClose: () => void;
 title: string;
 recordId: string;
}

const NecessarySettingModal: React.FC<NecessarySettingModalProps> = ({
  show,
  onClose,
  title,
  recordId
}) => {
  const [modifiedData, setModifiedData] = useState<Array<SettingModel>>([])
  const [isLoading, setIsloading ]= useState<boolean>(false)
  const closeModal = () => {
    onClose();
  }
  const [modal, setModal] = useState<ContentModalProps>({
    show: false,
    size: "xl",
    customClass: "open-draftRecord-modal",
    handleClose: closeModal,
    title: title,
  });

  /******************* Effect區塊 START *******************/
  useEffect(() => {
    setModal((prev) => ({ ...prev, show: show, title: title }));
  }, [show])

  useEffect(()=> {
    if (recordId) {
      getRecordData(recordId)
    }
  },[recordId])

  useEffect(()=> {
    console.log('modifiedData',modifiedData);
    
    setIsloading(false)
  },[modifiedData])

  
  /******************* Effect區塊 END *******************/

  const {mutate:getRecordData, isPending: getDataPending} = useMutation({
    mutationFn: (id:any) => 
      OperatingRecordService.getRecordData(id),
    onSuccess: (res) => {
      setIsloading(true)
      const modifiedDataAfterParse:SettingModel[] = JSON.parse(res.data.modifiedData);
      const originDataAfterParse:SettingModel[] = JSON.parse(res.data.originData);

      const updatedModifiedData = modifiedDataAfterParse.map((modifiedItem) => {
        const originItem = originDataAfterParse.find(
          (origin) => origin.IndustryId === modifiedItem.IndustryId
        );
      
        if (originItem) {
          const modifiedReportIndicators = modifiedItem.ReportIndicator;
          const originReportIndicators = originItem.ReportIndicator;
      
          // 找出 `Under2BType` 或 `Over2BType` 不同的項目
          const differingIndicators = modifiedReportIndicators.map((modifiedIndicator) => {
            const originIndicator = originReportIndicators.find(
              (originIndicator) => originIndicator.Id === modifiedIndicator.Id
            );
      
            if (originIndicator && (
              originIndicator.Under2BType !== modifiedIndicator.Under2BType ||
              originIndicator.Over2BType !== modifiedIndicator.Over2BType
            )) {
              return {
                ...modifiedIndicator,
                Over2BType: originIndicator.Over2BType,
                Under2BType: originIndicator.Under2BType,
                NewUnder2BType: modifiedIndicator.Under2BType,
                NewOver2BType: modifiedIndicator.Over2BType,
              };
            }
      
            return modifiedIndicator;
          });
      
          // 將新的 `differingIndicators` 賦值給 `ReportIndicator`
          return {
            ...modifiedItem,
            ReportIndicator: differingIndicators,
          };
        }
      
        return modifiedItem;
      });
      
      setModifiedData(updatedModifiedData);
      
      
    },
    onError: (err) => { console.log(err);
    },
  });
  


  return (
    <ContentModal {...modal}>
      { isLoading || getDataPending ? (
          <VisuallLoading />
        ) : null}
      <div className={`${styles["open-NecessarySetting-modal-content"]}`}>
        

        <div className="result-box scroll">
          {/**左方固定title */}
          <div className="fix-table-box settingTable-block">
              <table
              aria-label="查詢結果table"
              className="table-container sticky-table"
              >
              <thead>
                <tr>
                    <th scope="col">項目 \ 行業別</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="capital-title">資本額</td>
                </tr>

                {!modifiedData || modifiedData.length === 0 ? <tr><td>無資料</td></tr> : 
                  modifiedData[0].ReportIndicator.map((left:any, i:any) => {
                    return (
                    <tr key={`tr_${i}_left`}>
                      <td><div>{left.Title}</div></td>
                    </tr>
                    );
                  })
                }
                
              </tbody>
            </table>
          </div>

          {/**右方卷軸資料 */}
          <div className="settingTable-block2">
            <table
              key='table2'
              aria-label="查詢結果table"
              className="table-container sticky-table"
              >
                <thead>
                  <tr>
                    {!modifiedData || modifiedData.length === 0 ? <></> : 
                      modifiedData.map((tit, i) => {
                        return (
                          <th colSpan={2} key={`th_${i}_title`}>{tit.Name}</th>
                        );
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr>

                    {!modifiedData || modifiedData.length === 0 ? <></> : 
                      modifiedData.map((tit, i) => {
                        return (
                          <React.Fragment key={`tr_${i}_title`}>
                            <td className="capital-title">20億以上</td>
                            <td className="capital-title">20億以下</td>
                          </React.Fragment>
                        );
                      })
                    }

                  </tr>
                  { !modifiedData || modifiedData.length === 0 ? <></> :
                      modifiedData[0].ReportIndicator.map((indic, i) => {
                          return (
                            <tr key={`tr_${i}_left`}>
                              {modifiedData.map((item, ti) => { 
                                return (<React.Fragment key={`tr_map_${ti}`}>
                                {
                                  item.ReportIndicator.map((indicSub, vi) => {
                                    if(indic.Id === indicSub.Id) {
                                        return (
                                          <React.Fragment key={`tr_${vi}`}>
                                            {/**20億以上 */}
                                            <td className={indicSub.NewOver2BType !== null && indicSub.NewOver2BType !== undefined && indicSub.NewOver2BType !== indicSub.Over2BType ? 'changedTable' : ''}>
                                              <div className="icon_container" >
                                                <div className={`lightBlue-icon_${indicSub.Over2BType}`}></div>
                                                {indicSub.NewOver2BType !== null && indicSub.NewOver2BType !== undefined && indicSub.NewOver2BType !== indicSub.Over2BType && (
                                                  <>
                                                    {indicSub.Over2BType !== null && (
                                                      <div className="greaterIcon"></div>
                                                    )}
                                                    <div className={`icon_${indicSub.NewOver2BType}`}></div>
                                                  </>
                                                )}
                                              </div>
                                            </td>
                                            {/**20億以下 */}
                                            <td className={indicSub.NewUnder2BType !== null && indicSub.NewUnder2BType !== undefined && indicSub.NewUnder2BType !== indicSub.Under2BType ? 'changedTable' : ''}>
                                            <div className="icon_container">
                                                <div className={`lightBlue-icon_${indicSub.Under2BType}`}></div>
                                                {indicSub.NewUnder2BType != null && indicSub.NewUnder2BType !== undefined && indicSub.NewUnder2BType !== indicSub.Under2BType && (
                                                <>
                                                {indicSub.Under2BType !== null && (
                                                   <div className="greaterIcon"></div>
                                                )}
                                                <div className={`icon_${indicSub.NewUnder2BType}`}></div>
                                                </>
                                              )}
                                              </div>
                                            </td>
                                          </React.Fragment>
                                        )
                                    }
                                  })
                                }
                                </React.Fragment>)
                                })
                              }
                            </tr>
                          )
                      })
                  }
                  
                </tbody>
            </table>
          </div>
          
        </div>
      </div>
    </ContentModal>
  );
};

export default NecessarySettingModal;