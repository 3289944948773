'use client';
//* ---------------------------- React start ---------------------------- *//
import { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from './OpenDeclarationModal.module.scss';
import Dropdown from 'components/base/Dropdown/Dropdown';
import ContentModal, { ContentModalProps } from 'components/base/ContentModal/ContentModal';
import { OptionModel, RequestType } from 'models/baseModel';
import {
	DeclareSettingDataRequestModel,
	RequestTypeEnum,
	DeclareSettingDataModel,
} from 'models/admin/declarationModel';
import DeclarationService from 'services/admin/declarationService';
import VisuallLoading from 'components/common/VisuallLoading/VisuallLoading';
import NormalInput from 'components/base/NormalInput/NormalInput';
import { success2Alert, error2Alert } from 'utils/otherToast';
import mopsEsgService from 'services/inquiry/infoDisclosure/mopsEsgService';
//* ---------------------------- local end ---------------------------- *//

export interface OpenDeclarationModelProps {}

export interface OpenDeclarationModelRefs {
	openModal: (declareSettingDataRequestModel: DeclareSettingDataRequestModel) => void;
	closeModal: () => void;
}

const OpenDeclarationModel = forwardRef((props: OpenDeclarationModelProps, ref) => {
	//* ---------------------------- state start ---------------------------- *//
	const [modal, setModal] = useState<ContentModalProps>({
		show: false,
		size: 'lg',
		customClass: 'open-declaration-modal',
		handleClose: () => {
			setModal((prev) => ({ ...prev, show: false }));
		},
	});
	const [declareYearOptions, setDeclareYearOptions] = useState<OptionModel[]>([]);
	// const [declareYearOptions] = useState<OptionModel[]>(() => {
	//   const declareYearOptionsList = [];
	//   const today = new Date();
	//   for (let i = today.getFullYear(); i >= 2021; i--) {
	//     declareYearOptionsList.push({
	//       id: i.toString(),
	//       text: `${i.toString()} 年度`,
	//       enumKey: i,
	//     });
	//   }
	//   return [
	//     // {
	//     //   id: "undefined",
	//     //   text: "全部",
	//     //   enumKey: undefined,
	//     // },
	//     ...declareYearOptionsList,
	//   ];
	// });
	const [declareSettingDataRequestModel, setDeclareSettingDataRequestModel] =
		useState<DeclareSettingDataRequestModel>();
	const [declareSettingData, setDeclareSettingData] = useState<DeclareSettingDataModel>();
	const declareSettingDataRequestModelRef = useRef<DeclareSettingDataRequestModel>();
	// const [value, onChange] = useState<Value>([new Date(), new Date()]);
	//* ---------------------------- state end ---------------------------- *//
	//* ---------------------------- api start ---------------------------- *//
	//- getDeclareSettingData
	const { mutate: getDeclareSettingDataMutate, isPending: getDeclareSettingDataIsPending } = useMutation({
		mutationFn: (request: DeclareSettingDataRequestModel) => DeclarationService.getDeclareSettingData(request),
		onSuccess: (res) => {
			console.log('getDeclareSettingData', res);
			if (res.code === 200 && res.success) {
				// success2Alert(res.message);
				setDeclareSettingData(res.data);
			} else {
				// error2Alert(res.message);
			}
		},
		onError: (err) => {
			console.log('getDeclareSettingData', err);
			// error2Alert("執行失敗");
		},
	});
	//- editDeclareSettingData
	const { mutate: editDeclareSettingDataMutate, isPending: editDeclareSettingDataIsPending } = useMutation({
		mutationFn: (request: DeclareSettingDataModel) => DeclarationService.editDeclareSettingData(request),
		onSuccess: (res) => {
			console.log('editDeclareSettingData', res);
			if (res.code === 200 && res.success) {
				success2Alert(res.message);
				//- close modal
				closeModal();
			} else {
				error2Alert(res.message);
			}
		},
		onError: (err) => {
			console.log('editDeclareSettingData', err);
			error2Alert('執行失敗');
		},
	});
	//- getYearList
	const { mutate: getYearListMutate, isPending: getYearListIsPending } = useMutation({
		mutationFn: (type: RequestType) => mopsEsgService.getYearList(type),
		onSuccess: (res) => {
			// console.log("getYearList", res);
			// if (res.code === 200 && res.success) {
			//   //- 過濾res.data相同的年度
			//   const declareYearOptionsList = res.data
			//     .filter(
			//       (e: number, i: number, self: number[]) => self.indexOf(e) === i
			//     )
			//     .map((e: number) => ({
			//       id: e.toString(),
			//       text: `${e.toString()} 年度`,
			//       enumKey: e,
			//     })) as OptionModel[];
			//   setDeclareYearOptions([
			//     {
			//       id: "undefined",
			//       text: "全部",
			//       enumKey: undefined,
			//     },
			//     ...declareYearOptionsList,
			//   ]);
			// }
		},
		onError: (err) => {
			console.log('getYearList', err);
			error2Alert('執行失敗');
		},
	});
	//* ---------------------------- api end ---------------------------- *//
	//* ---------------------------- function start ---------------------------- *//
	const openModal = (declareSettingDataRequestModel: DeclareSettingDataRequestModel) => {
		let modalTitle = '';
		switch (declareSettingDataRequestModel.requestType) {
			case RequestTypeEnum.ESGDisclosure:
				modalTitle = 'ESG資訊揭露申報開放';
				break;
			case RequestTypeEnum.SustainReport:
				modalTitle = '永續報告書申報開放';
				break;
			case RequestTypeEnum.GhgEmissionAndReduction:
				modalTitle = '溫室氣體排放及減量資訊申報開放';
				break;
			default:
				break;
		}
		setModal((prev) => ({ ...prev, show: true, title: modalTitle }));
		//- 如果 declareSettingDataRequestModel 沒有 year 帶入 就帶入今年
		// if (!declareSettingDataRequestModel.declareYear) {
		//   declareSettingDataRequestModel.declareYear =
		//     declareYearOptions[0].enumKey!;
		// }
		// console.log(
		//   "declareSettingDataRequestModel",
		//   declareSettingDataRequestModel
		// );
		getYearListMutate(declareSettingDataRequestModel.requestType, {
			onSuccess: (res) => {
				console.log('getYearList', res);
				if (res.code === 200 && res.success) {
					const declareYearOptionsList = res.data
						.filter((e: number, i: number, self: number[]) => self.indexOf(e) === i)
						.map((e: number) => ({
							id: e.toString(),
							text: `${e.toString()} 年度`,
							enumKey: e,
						})) as OptionModel[];
					setDeclareYearOptions([...declareYearOptionsList]);
					if (!declareSettingDataRequestModel.declareYear) {
						declareSettingDataRequestModel.declareYear = declareYearOptionsList.sort(
							(a, b) => b.enumKey! - a.enumKey!
						)[0].enumKey!;
						// console.log(
						//   "declareSettingDataRequestModel",
						//   declareSettingDataRequestModel
						// );
					}
					setDeclareSettingDataRequestModel(declareSettingDataRequestModel);
				}
			},
		});
	};
	const closeModal = () => {
		setModal((prev) => ({ ...prev, show: false }));
	};
	const editDeclareSettingData = () => {
		if (!declareSettingData) return;
		editDeclareSettingDataMutate(declareSettingData);
	};
	//* ---------------------------- function end ---------------------------- *//

	//* ---------------------------- hook start ---------------------------- *//
	//- useImperativeHandle
	useImperativeHandle(
		ref,
		(): OpenDeclarationModelRefs => ({
			openModal: openModal,
			closeModal: closeModal,
		})
	);
	//- useEffect
	useEffect(() => {
		if (!declareSettingDataRequestModel) return;
		getDeclareSettingDataMutate(declareSettingDataRequestModel);
		declareSettingDataRequestModelRef.current = declareSettingDataRequestModel;
	}, [declareSettingDataRequestModel, getDeclareSettingDataMutate]);

	//* ---------------------------- hook end ---------------------------- *//

	return (
		<ContentModal {...modal}>
			{getDeclareSettingDataIsPending || editDeclareSettingDataIsPending || getYearListIsPending ? (
				<VisuallLoading />
			) : null}
			<div className={`${styles['open-declaration-modal-content']}`}>
				<div className={`content-box`}>
					<div className='row-box'>
						<div className='title'>開放申報年度</div>
						<div className='data-input' style={{ width: '300px' }}>
							<Dropdown
								placeholder={'年度'}
								defaultId={declareSettingDataRequestModelRef.current?.declareYear?.toString()}
								options={declareYearOptions}
								isFloatTitle={false}
								clearIconDisplay={false}
								onChange={(e) => {
									setDeclareSettingDataRequestModel((prev) => ({
										...prev!,
										declareYear: declareYearOptions.find((o) => o.id === e)?.enumKey ?? undefined,
									}));
									setDeclareSettingData((prev) => ({
										...prev!,
										declareYear: declareYearOptions.find((o) => o.id === e)?.enumKey ?? undefined,
									}));
									setModal((prev) => ({
										...prev,
										declareYear: declareYearOptions.find((o) => o.id === e)?.enumKey ?? undefined,
									}));
								}}
							/>
						</div>
					</div>
					<div className='row-box'>
						<div className='title'>開放申報日期</div>
						<div className='data-input'>
							<DateRangePicker
								className={'custom-date-range-picker'}
								onChange={(e: any) => {
									setDeclareSettingData((prev) => ({
										...prev!,
										declareStartDate: e![0] ? moment(e![0]).format() : undefined,
										declareEndDate: e![1] ? moment(e![1]).format() : undefined,
									}));
								}}
								value={[
									declareSettingData?.declareStartDate ? new Date(declareSettingData?.declareStartDate) : null,
									declareSettingData?.declareEndDate ? new Date(declareSettingData?.declareEndDate) : null,
								]}
							/>
						</div>
					</div>
					<div className='row-box'>
						<div className='title'>報告書檔案大小限制</div>
						<div className='data-input' style={{ width: '300px' }}>
							<NormalInput
								placeholder='請填寫'
								defaultValue={declareSettingData?.reportLimitSize}
								unit='MB'
								type='number'
								onChange={(e: number) => {
									setDeclareSettingData((prev) => ({
										...prev!,
										reportLimitSize: e,
									}));
								}}
							/>
						</div>
					</div>
				</div>
				<div className={`footer-box`}>
					<button
						className={`secondary`}
						onClick={() => {
							closeModal();
						}}>
						取消
					</button>
					<button
						className={`default`}
						onClick={() => {
							editDeclareSettingData();
						}}>
						確定
					</button>
				</div>
			</div>
		</ContentModal>
	);
});

export default OpenDeclarationModel;
