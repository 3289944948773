import { PrintElementType } from "models/common/printElementModel";
import { ResponseCode } from "models/responseCodeModel";
import { error2Alert } from "utils/otherToast";

/** 開新視窗 */
const openNewWindow = (targetId: string, isPrint = false) => {
    const date = new Date();
    const win = window.open(
        "",
        "newWin" + date.getDate() + date.getTime()
    );
    if (win) {
        win.document.write(
            `<!DOCTYPE html><html><head></head><body></body></html>`
        );

        // 引入指定檔案url
        const lochref = `${window.location.protocol}//${window.location.host}`;

        // 引入全域css
        // const link = win.document.createElement("link");
        // link.setAttribute(
        //   "href",
        //   `${lochref}/scss/all.scss`
        // );
        // link.rel = "stylesheet";
        // win.document.head.appendChild(link);

        // const link = win.document.createElement("link");
        // link.rel = "stylesheet";
        // link.setAttribute(
        //   "href",
        //   `${lochref}/printScss/EsgIndicatorInfoPrint.scss?v=${new Date().getTime()}`
        // );
        // win.document.head.appendChild(link);


        let cssFileUrl = getCssFileUrl(lochref, targetId);
        if (cssFileUrl) {
            // 暫時先讀css 後續改為scss to css
            convertCssToStyle(cssFileUrl)
                .then(p => {

                    // 更換style (目前容易帶入亂碼的css 使用local的關係)
                    // 如果基礎的style 可以轉換就轉換，轉換不成功的屬性就吃靜態檔案的
                    copyStyles(document, win.document);

                    const styleTag = document.createElement('style');
                    styleTag.textContent = p;
                    win.document.head.appendChild(styleTag);
                    // 預設樣式
                    const div = document.createElement("div");
                    div.style.padding = "1rem";
                    div.style.background = "linear-gradient(180deg, #D9F5FF 0%, #BCE0F3 100%)";

                    // 目標id
                    const target = document.getElementById(targetId);
                    if (target) {
                        // 複製一個DOM 避免影響原本target
                        let newTarget = target.cloneNode(true) as HTMLElement;
                        newTarget = removeSpecifyTag(newTarget, targetId);
                        div.innerHTML = newTarget.outerHTML;
                    }
                    // 外圍給個div底色
                    win.document.body.appendChild(div);
                    win.document.body.style.margin = "0px";


                    if (isPrint) {
                        setTimeout(() => {
                            win.print();
                            win.document.close();
                        }, 50);
                    }
                })
                .catch(p => {
                    console.log('讀本地css 失敗', p);
                });
        } else {
            console.log('找不到css檔案')
        }
    }
}

/** 取得css檔案 */
const getCssFileUrl = (lochref: string, targetId: string): string => {
    let url = `${lochref}/printCss/`
    let cssFile = '';
    switch (targetId) {
        case PrintElementType.EsgOverviewForm:
            cssFile = 'EsgOverviewFormPrint.css';
            break;
        case PrintElementType.GenerateReportBox:
            cssFile = 'GenerateReportBoxPrint.css';
            break;
        case PrintElementType.IndividualTableBox:
            cssFile = 'IndividualTablePrint.css';
            break;
        case PrintElementType.SummaryTableBox:
        case PrintElementType.SummaryFormBox:
            cssFile = 'SummaryTablePrint.css';
            break;
        default:
            return '';
    }
    return `${url}${cssFile}?${new Date().getTime()}`;
}


/** css 檔案轉換 */
const convertCssToStyle = (cssFilePath: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        fetch(cssFilePath)
            .then(p => {
                if (p.status === ResponseCode.SuccessOK) {
                    return p.text();
                } else {
                    error2Alert('載入錯誤，請聯絡管理員');
                }
            }).then(data => {
                resolve(data || "");
            }).catch(err => {
                console.error('Error:', err);
            });
    });

}

/** 轉換css to style  */
const copyStyles = (sourceDoc: any, targetDoc: any) => {
    Array.from(sourceDoc.styleSheets).forEach((styleSheet: any) => {
        try {
            if (styleSheet.cssRules) {
                const newStyleEl = sourceDoc.createElement("style");
                Array.from(styleSheet.cssRules).forEach((cssRule: any) => {
                    newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
                });


                targetDoc.head.appendChild(newStyleEl);
            } else {
                const newLinkEl = sourceDoc.createElement("link");
                newLinkEl.rel = "stylesheet";
                newLinkEl.href = styleSheet.href;
                targetDoc.head.appendChild(newLinkEl);
            }
        } catch (e) {
            console.log(e);
        }
    });
};

/** 移除特定tag */
const removeSpecifyTag = (html: HTMLElement, targetId: string): HTMLElement => {

    // 移除對應元素
    const removeElement = (elements: HTMLCollectionOf<Element>) => {
        Array.from(elements).forEach(item => {
            item.parentNode?.removeChild(item);
        });
    }

    let newHtml = html;
    if (newHtml) {
        switch (targetId) {
            case PrintElementType.EsgOverviewForm:
                //#region 申報作業總覽移除列印按鈕
                let tools = newHtml.getElementsByClassName('content-tool');
                removeElement(tools);
                //#endregion
                break;
            case PrintElementType.SummaryFormBox:
                //#region 移除伸展的icon
                let imgs = newHtml.getElementsByClassName('extend-img');
                removeElement(imgs);
                //#endregion
                break;
        }
    }
    return newHtml;
}

/** 打開新視窗by url */
const openNewWindowByParam = (paramUrl: string, isPrint = false) => {
    let url = `${window.location.origin}${window.location.pathname}?${paramUrl}`;
    if (isPrint) {
        url += `&isPrint=y`;
    }
    window.open(url);
}


export const PrintService = {
    openNewWindow,
    openNewWindowByParam
}