import React, { FC, useEffect, useState } from "react";
import styles from "./ReportGenerationList.module.scss";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { normalDate3 } from "utils/dateTimeFormat";
import TablePagination from "components/base/TablePagination/TablePagination";
import ProblemReportContent from "components/otherModule/ProblemReportContent/ProblemReportContent";
import { ListRequestModel, StreamFileType } from "models/baseModel";
import { Link, useNavigate } from "react-router-dom";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { useMutation } from "@tanstack/react-query";
import SustainReportProduceService from "services/application/sustainReportDeclare/sustainReportProduce";
import { ResponseCode } from "models/responseCodeModel";
import {
  ReportProduceAction,
  ReportProduceFileModel,
  ReportProduceStatus,
  SustainReportProduceInfoModel,
  SustainReportProduceShowModel,
  SustainReportProduceStatusModel,
} from "models/application/sustainReportDeclare/sustainReportProduceModel";
import { error2Alert, success2Alert } from "utils/otherToast";
import {
  Multilingual,
  SustainReportExportModel,
} from "models/application/sustainReportDeclare/sustainReportDeclareModel";
import SustainReportDeclareService from "services/application/sustainReportDeclare/sustainReportDeclare";
import { CommonService } from "services/common/commonService";

interface ReportGenerationListProps {}

const ReportGenerationList: FC<ReportGenerationListProps> = () => {
  let reportData: any[] = [
    {
      id: 1,
      year: 112,
      lastEditTime: new Date(),
      statusName: "未產製",
    },
  ];
  const [param, setParam] = useState<ListRequestModel>({
    page: 1,
    pageSize: 10,
    searchKey: null,
    sortKey: null,
  });
  /** 清單資料 */
  const [listData, setListData] = useState<SustainReportProduceShowModel[]>([]);
  const navigate = useNavigate();
  /** 基本狀態資訊 */
  const [statusData, setStatusData] = useState<SustainReportProduceInfoModel>({
    produceId: "",
    year: 0,
    produceStatus: ReportProduceStatus.NotStarted,
    stepStatus: ReportProduceAction.StartProduce,
    produceStatusName: "",
  });
  /** 範例檔案 */
  const [refFileData, setRefFileData] = useState<ReportProduceFileModel[]>([]);

  useEffect(() => {
    getSustainReportProduceList.mutate(param);
    getReportProduceFileList.mutate();
  }, []);

  //#region 方法區塊

  /** 前往指定info 頁面 */
  const gotoDetail = (item: SustainReportProduceShowModel) => {
    if (item.produceStatus === ReportProduceStatus.NotStarted) {
      // 更改狀態後再往下一步驟
      editStatus.mutate(
        {
          produceId: item.id,
          action: ReportProduceAction.StartProduce,
        },
        {
          onSuccess: (res) => {
            if (res.success && res.code === ResponseCode.SuccessOK) {
              gotoTarget(item.id, 1);
            } else {
              console.log(res);
              error2Alert(res.message);
            }
          },
        }
      );
    } else {
      getStatus.mutate(item.id);
    }
  };

  /** 前往目的地 */
  const gotoTarget = (id: string, step: number) => {
    navigate(`/apply/report-generation/${id}/info/step${step}`);
  };

  /** 根據type取得按鈕text文字 */
  const getBtnText = (type: ReportProduceStatus) => {
    let text = "";
    switch (type) {
      case ReportProduceStatus.InProgress:
      case ReportProduceStatus.Completed:
        text = "編輯";
        break;
      case ReportProduceStatus.NotStarted:
      default:
        text = "開始產製";
        break;
    }
    return text;
  };

  /** 下載報告書 */
  const downloadFile = (id: string) => {
    exportPOCReport.mutate({
      multilingual: Multilingual.Chinese,
      produceId: id,
    });
  };
  //#endregion

  //#region API區塊
  /** 永續報告書產製清單 */
  const getSustainReportProduceList = useMutation({
    mutationFn: (model: ListRequestModel) =>
      SustainReportProduceService.getSustainReportProduceList(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportProduceShowModel[] = res.data;
        setListData([...data]);
        console.log(data);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 編輯狀態 */
  const editStatus = useMutation({
    mutationFn: (model: SustainReportProduceStatusModel) =>
      SustainReportProduceService.editStatus(model),
    onError: (err) => {
      console.log(err);
    },
  });

  /** 永續報告書產製清單 */
  const getStatus = useMutation({
    mutationFn: (procedureId: string) =>
      SustainReportProduceService.getStatus(procedureId),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportProduceInfoModel = res.data;
        setStatusData({ ...data });
        let step = 1;
        switch (data.stepStatus) {
          case ReportProduceAction.ReportGenerate:
            step = 2;
            break;
          case ReportProduceAction.SelectStyle:
            step = 3;
            break;
          case ReportProduceAction.ExportReport:
            step = 4;
            break;
          case ReportProduceAction.StartProduce:
          default:
            step = 1;
            break;
        }

        gotoTarget(data.produceId, step);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 匯出報告書 */
  const exportPOCReport = useMutation({
    mutationFn: (data: SustainReportExportModel) =>
      SustainReportDeclareService.exportPOCReport(data),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        success2Alert("匯出成功");
        CommonService.downloadByStream(res, StreamFileType.Docx);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 取得報告書產製的所有參考檔案 */
  const getReportProduceFileList = useMutation({
    mutationFn: () => SustainReportProduceService.getReportProduceFileList(),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const resData: ReportProduceFileModel[] = res.data;
        setRefFileData(resData);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 匯出參考資料報告書 */
  const getReportProduceFileFileStream = useMutation({
    mutationFn: (fileId: string) =>
      SustainReportProduceService.getReportProduceFileFileStream(fileId),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        success2Alert("匯出成功");
        CommonService.downloadByStream(res, StreamFileType.Pdf);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  return (
    <div className="no-nav-content-box">
      {(getSustainReportProduceList.isPending ||
        getStatus.isPending ||
        editStatus.isPending ||
        exportPOCReport.isPending ||
        getReportProduceFileFileStream.isPending ||
        getReportProduceFileList.isPending) && <VisuallLoading />}
      <div className={styles["report-generation-list-box"]}>
        <div className="title mb-20-p">永續報告書產製</div>
        <div className="report-generation-content-box scroll">
          <div className="top-desc mb-20-p">
            報告書產製為系統輔助功能，上市櫃公司仍應進行鑑別利害關係人、重大主題分析、永續相關資料蒐集、報告書彙整編撰等作業。
            <br />
            說明：請先完成當年度「企業ESG資訊揭露申報作業」，ESG指標將自動帶入報告書底稿中，若您未完成申報，將無法自動帶入。
          </div>
          <div className="result-box scroll">
            <div className="table-box">
              {false && <VisuallLoading></VisuallLoading>}
              <table
                aria-label="查詢結果table"
                className="table-container sticky-table"
              >
                <thead>
                  <tr>
                    <th scope="col">年度</th>
                    <th scope="col">最後編輯日期</th>
                    <th scope="col">狀態</th>
                    <th scope="col">功能</th>
                  </tr>
                </thead>
                <tbody>
                  {listData.map((p, itemIndex: number) => {
                    return (
                      <tr key={p.id}>
                        <td>{p.produceYear}</td>
                        <td>{normalDate3(p.modifiedProduceTime)}</td>
                        <td>{p.produceStatusName}</td>
                        <td className="func-td">
                          <LinkIconButton
                            imgName="right-arrow.svg"
                            text={getBtnText(p.produceStatus)}
                            imgPosition="img-right"
                            onClick={() => gotoDetail(p)}
                          />
                          {p.produceStatus ===
                            ReportProduceStatus.Completed && (
                            <LinkIconButton
                              imgName="download-icon.svg"
                              text={"下載報告書"}
                              imgPosition="img-right"
                              onClick={() => {
                                downloadFile(p.id);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="table-pagination mb-20-p">
            <TablePagination
              page={param.page}
              pageSize={param.pageSize}
              onChange={(e) => {
                const tmpReq = {
                  ...param,
                  ...e,
                };
                setParam(tmpReq);
              }}
              total={reportData?.length}
            />
          </div>
          <div className="desc">
            參考資料：
            <div className="ref-desc">
              {refFileData.map((file, index) => {
                return (
                  <Link
                    key={file.id}
                    to={"/"}
                    onClick={($event) => {
                      $event.preventDefault();
                      getReportProduceFileFileStream.mutate(file.id);
                    }}
                  >
                    {index + 1}.{file.fileName}
                    <br />
                  </Link>
                );
              })}
            </div>
            <br />
            GRI標準：
            <div className="ref-desc">
              <img
                className="gri-img"
                alt=""
                src="/assets/images/declaration/reportGenetation/generation-gri-bg.png"
              />
              <div>
                1.有關 GRI
                標準的最新版本，包括修訂後的通用標準、改編的主題標準、行業標準、其建議和指導部分以及
                GRI 標準術語表，請訪問
                <Link
                  target="_blank"
                  to={
                    "https://www.globalreporting.org/how-to-use-the-gri-standards/resource-center"
                  }
                  onClick={($event) => {}}
                >
                  GRI 資源中心
                </Link>
                。
                <br />
              </div>
              <div>
                2.GRI標準係臺灣證券交易所經 GRI 授權使用。GRI 作為 GRI
                標準的版權授權方，已驗證並確認ESG數位平台中 GRI
                標準的真實性和準確性。此驗證僅限於確保所授權內容的完整性、真實性和準確性。因此，GRI
                不就ESG數位平台或由平台產出的任何產品的正確性、合規性、可靠性、適用性或品質作出任何明示或暗示的陳述或保證；亦不對授權使用者使用
                GRI
                版權內容的情況作出任何明示或暗示的陳述，並明確否認任何由授權使用者產生的報告符合
                GRI 標準報告要求的暗示或明示的陳述。
              </div>
            </div>
            <br />
            <ProblemReportContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportGenerationList;
