//* ---------------------------- React start ---------------------------- *//
import { FC, useEffect, useState, useRef } from 'react';
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from './EsgDisclosure.module.scss';
import Dropdown from 'components/base/Dropdown/Dropdown';
import { OptionModel, BaseResponse, RequestType } from 'models/baseModel';
import ScrollToTopButton from 'components/button/ScrollToTopButton/ScrollToTopButton';
import DeclarationService from 'services/admin/declarationService';
import SearchInput from 'components/base/SearchInput/SearchInput';
import {
	DeclareStatusEnum,
	EsgDisclosureDataModel,
	EsgDisclosureDataRequestModel,
	RequestTypeEnum,
	DeclareSettingDataRequestModel,
} from 'models/admin/declarationModel';
import VisuallLoading from 'components/common/VisuallLoading/VisuallLoading';
import { success2Alert, error2Alert, successAlert } from 'utils/otherToast';
import ConfirmModal, { ConfirmModalProps } from 'components/base/ConfirmModal/ConfirmModal';
import TablePagination from 'components/base/TablePagination/TablePagination';
import { PaginationModel } from 'models/baseModel';
import OpenDeclarationModel, {
	OpenDeclarationModelRefs,
} from 'components/otherModule/OpenDeclarationModal/OpenDeclarationModal';
import { CommonService } from 'services/common/commonService';
import mopsEsgService from 'services/inquiry/infoDisclosure/mopsEsgService';
import { AxiosResponse } from 'axios';
import { ResponseCode } from 'models/responseCodeModel';
import useUserInfoStore from 'state/useUserInfoStore';
import { ActionsPermissionCodes } from 'models/auth/permissionModel';
import EmptyWaitPage from 'components/common/EmptyWaitPage/EmptyWaitPage';
import { usePermissionContext } from 'context/PermissionProvider';
import { DeclareDataManageActionsPermissionCodes } from 'models/auth/permissionModel';
import LinkIconButton from 'components/button/LinkIconButton/LinkIconButton';
//* ---------------------------- local end ---------------------------- *//

//- setDeclareDataRequestModel props
const OperationHeaderComponent: FC<{
	esgDisclosureDataRequestModel: EsgDisclosureDataRequestModel;
	setDeclareDataRequestModel: React.Dispatch<React.SetStateAction<EsgDisclosureDataRequestModel>>;
	declareYearOptions: OptionModel[];
	declareStatusEnumOptions: OptionModel[];
	getEsgDisclosureExcel: UseMutationResult<AxiosResponse<any, any>, Error, EsgDisclosureDataRequestModel, unknown>;
	openOpenDeclarationModal: () => void;
}> = ({
	esgDisclosureDataRequestModel,
	setDeclareDataRequestModel,
	declareYearOptions,
	declareStatusEnumOptions,
	getEsgDisclosureExcel,
	openOpenDeclarationModal,
}) => {
	//* ---------------------------- state start ---------------------------- *//
	const { PermissionService } = usePermissionContext();
	const { detectEveryActionPermission } = PermissionService;
	//* ---------------------------- state end ---------------------------- *//

	return (
		<div className={`${styles['operation-header']}`}>
			<div className={`left`}>
				<div className={`select-item`} style={{ width: '160px' }}>
					<Dropdown
						placeholder={'年度'}
						defaultId={
							esgDisclosureDataRequestModel.declareYear === undefined
								? 'undefined'
								: esgDisclosureDataRequestModel.declareYear?.toString()
						}
						options={declareYearOptions}
						isFloatTitle={true}
						clearIconDisplay={false}
						onChange={(e) => {
							setDeclareDataRequestModel((prev) => ({
								...prev,
								declareYear: declareYearOptions.find((o) => o.id === e)?.enumKey ?? undefined,
							}));
						}}
					/>
				</div>
				<div className={`select-item`} style={{ width: '160px' }}>
					{/* <span>狀態</span> */}
					<Dropdown
						placeholder={'狀態'}
						defaultId={
							esgDisclosureDataRequestModel.status === undefined
								? 'undefined'
								: esgDisclosureDataRequestModel.status?.toString()
						}
						options={declareStatusEnumOptions}
						isFloatTitle={true}
						clearIconDisplay={false}
						onChange={(e) => {
							setDeclareDataRequestModel((prev) => ({
								...prev,
								status: declareStatusEnumOptions.find((o) => o.id === e)?.enumKey ?? undefined,
							}));
						}}
					/>
				</div>
				<div className={`select-item`} style={{ width: '300px' }}>
					<SearchInput
						onClick={(e) => {
							setDeclareDataRequestModel((prev) => ({
								...prev,
								searchInfo: e,
							}));
						}}
						placeholder={'搜尋公司代號/公司名稱'}
					/>
				</div>
			</div>
			<div className={`right`}>
				<div className={`select-item`}>
					<LinkIconButton
						imgName='export-icon.svg'
						text='匯出 Excel'
						onClick={() => {
							getEsgDisclosureExcel.mutate(esgDisclosureDataRequestModel);
						}}
					/>
				</div>
				{detectEveryActionPermission([DeclareDataManageActionsPermissionCodes.IndicatorDeclareManageSetPermission]) ? (
					<div className={`select-item`}>
						<button
							className='default'
							onClick={() => {
								openOpenDeclarationModal();
							}}>
							開放申報
						</button>
					</div>
				) : null}
			</div>
		</div>
	);
};

const TableComponent: FC<{
	esgDisclosureDataList: EsgDisclosureDataModel[] | undefined;
	isLoading: boolean;
	declareStatusEnumOptions: OptionModel[];
	openDeclareDataCorrectionRequestPermission: UseMutationResult<BaseResponse<any>, Error, string, unknown>;
	setDeclareDataRequestModel: React.Dispatch<React.SetStateAction<EsgDisclosureDataRequestModel>>;
	esgDisclosureDataRequestModel: EsgDisclosureDataRequestModel;
}> = ({
	esgDisclosureDataList,
	isLoading,
	declareStatusEnumOptions,
	openDeclareDataCorrectionRequestPermission,
	setDeclareDataRequestModel,
	esgDisclosureDataRequestModel,
}) => {
	//* ---------------------------- state start ---------------------------- *//
	const [selectItemId, setSelectItemId] = useState<string>();
	const [modal, setModal] = useState<ConfirmModalProps>({
		show: false,
		handleClose: () => {},
		handleConfirm: () => {},
	});
	const navigate = useNavigate();
	//* ---------------------------- state end ---------------------------- *//

	//- setModal
	useEffect(() => {
		setModal((prev) => ({
			...prev,
			loading: openDeclareDataCorrectionRequestPermission.isPending,
			handleClose: () => {
				setSelectItemId(undefined);
				setModal((prev) => ({
					...prev,
					show: false,
				}));
			},
			handleConfirm: () => {
				console.log('selectItemId', selectItemId);
				openDeclareDataCorrectionRequestPermission.mutate(selectItemId!, {
					onSuccess: (res) => {
						console.log('openDeclareDataCorrectionRequestPermission!!!!');
						if (res.code === 200 && res.success) {
							setSelectItemId(undefined);
							setModal((prev) => ({
								...prev,
								show: false,
							}));
						}
					},
				});
			},
		}));
	}, [modal.show, selectItemId, openDeclareDataCorrectionRequestPermission]);

	//- handleSort
	const handleSort = (sortKey: string) => {
		setDeclareDataRequestModel((prev) => ({
			...prev,
			sortType: prev.sortKey === sortKey ? !prev.sortType : prev.sortType ?? true,
			sortKey: sortKey,
		}));
	};

	const navigateToDetailPage = (item: EsgDisclosureDataModel) => {
		if (!item?.id) return;
		navigate(`${item.id}/detail`, {
			state: {
				esgDisclosure_SelectedItemData: item,
				esgDisclosureDataRequestModel: esgDisclosureDataRequestModel,
			},
		});
	};

	return (
		<div className={`${styles['transaction-table-box']} transaction-table-scroll scroll`}>
			<ConfirmModal {...modal}>
				<div>確認是否開啟權限​?</div>
			</ConfirmModal>
			{isLoading ? <VisuallLoading /> : null}

			<ScrollToTopButton targetClassName={'transaction-table-scroll'} bottom={90} right={35} />
			<table aria-label='table' className={`${styles['transaction-table']} table-container sticky-table`}>
				<thead>
					<tr>
						<th scope='col'>
							<div className='cur-pointer' onClick={() => handleSort('CompanyCode')}>
								<span>公司代號</span>
								<img src='/assets/images/buttonIcon/sort-icon.svg' alt='sort-icon.svg' />
							</div>
						</th>
						<th scope='col'>公司名稱</th>
						<th scope='col'>最後編輯日期</th>
						<th scope='col'>首次申報日期</th>
						<th scope='col'>更正申報日期</th>
						<th scope='col'>狀態</th>
					</tr>
				</thead>
				<tbody>
					{esgDisclosureDataList?.length === 0 ? (
						<tr>
							<td colSpan={9}>查無資料</td>
						</tr>
					) : (
						esgDisclosureDataList?.map((item, index) => (
							<tr key={index} onClick={() => navigateToDetailPage(item)}>
								<td>{item.companyCode}</td>
								<td>{item.companyName}</td>
								<td>{item.lastEditDate ? moment(item.lastEditDate).format('yyyy-MM-DD') : '-'}</td>
								<td>{item.firstDeclareDate ? moment(item.firstDeclareDate).format('yyyy-MM-DD') : '-'}</td>
								<td>{item.editDeclareDate ? moment(item.editDeclareDate).format('yyyy-MM-DD') : '-'}</td>
								<td>{item.statusName}</td>
							</tr>
						))
					)}
				</tbody>
			</table>
		</div>
	);
};

const EsgDisclosure: FC = () => {
	//* ---------------------------- state start ---------------------------- *//
	const location = useLocation();
	const [declareYearOptions, setDeclareYearOptions] = useState<OptionModel[]>([]);
	// const [declareYearOptions] = useState<OptionModel[]>(() => {
	//   const declareYearOptionsList = [];
	//   const today = new Date();
	//   for (let i = today.getFullYear(); i >= 2021; i--) {
	//     declareYearOptionsList.push({
	//       id: i.toString(),
	//       text: `${i.toString()} 年度`,
	//       enumKey: i,
	//     });
	//   }
	//   return [
	//     {
	//       id: "undefined",
	//       text: "全部",
	//       enumKey: undefined,
	//     },
	//     ...declareYearOptionsList,
	//   ];
	// });
	const [declareStatusEnumOptions] = useState<OptionModel[]>(() => {
		const enumValues = Object.values(DeclareStatusEnum);
		const declareStatusEnumDescriptions: Record<DeclareStatusEnum, string> = {
			[DeclareStatusEnum.UnDeclared]: '未申報',
			[DeclareStatusEnum.Declaring]: '申報中',
			[DeclareStatusEnum.Declared]: '已申報',
			[DeclareStatusEnum.ApplyForCorrection]: '申請更正',
			[DeclareStatusEnum.Correcting]: '更正中',
		};

		const declareStatusEnumOptionsList = enumValues
			.filter((e) => !isNaN(Number(e)))
			.map((key) => ({
				id: key.toString(),
				text: declareStatusEnumDescriptions[key as DeclareStatusEnum],
				enumKey: key,
			})) as OptionModel[];

		return [
			{
				id: 'undefined',
				text: '全部',
				enumKey: undefined,
			},
			...declareStatusEnumOptionsList,
		];
	});
	const [esgDisclosureDataRequestModel, setDeclareDataRequestModel] = useState<EsgDisclosureDataRequestModel>(() => {
		if (location?.state?.esgDisclosureDataRequestModel) {
			const stateRequest = CommonService.deepClone(location.state.esgDisclosureDataRequestModel);
			return stateRequest;
		}
		return {
			page: 1,
			pageSize: 10,
			searchKey: null,
			sortKey: null,
			sortType: undefined,
			declareYear: undefined,
			status: undefined,
			searchInfo: undefined,
		};
	});
	const [esgDisclosureDataList, setEsgDisclosureDataList] = useState<{
		count: number;
		list: EsgDisclosureDataModel[];
	}>({
		count: 0,
		list: [],
	});
	const [tablePagination, setTablePagination] = useState<PaginationModel>({
		page: 1,
		pageSize: 10,
		total: 0,
	});
	const useOpenDeclarationModelRef = useRef<OpenDeclarationModelRefs>(null);
	//* userInfo
	const { userInfo } = useUserInfoStore();
	//* permission
	const { permission } = userInfo;
	//* actionCodes
	const { actionCodes } = permission;
	//* ---------------------------- state end ---------------------------- *//
	//* ---------------------------- api start ---------------------------- *//
	//- getEsgDisclosureList
	const { mutate: getDeclareDataCorrectionRequestListMutate, isPending: getDeclareDataCorrectionRequestListIsPending } =
		useMutation({
			mutationFn: (request: EsgDisclosureDataRequestModel) => DeclarationService.getEsgDisclosureList(request),
			onSuccess: (res) => {
				console.log('getEsgDisclosureList', res);
				if (res.code === 200 && res.success) {
					setEsgDisclosureDataList({
						count: res.count!,
						list: res.data,
					});
				} else {
					setEsgDisclosureDataList({
						count: 0,
						list: [],
					});
				}
			},
			onError: (err) => {
				console.log('getEsgDisclosureList', err);
			},
		});

	//- getEsgDisclosureExcel
	const getEsgDisclosureExcel = useMutation({
		mutationFn: (request: EsgDisclosureDataRequestModel) => DeclarationService.getEsgDisclosureExcel(request),
		onSuccess: (res) => {
			console.log('getEsgDisclosureExcel', res);
			if (res.status !== ResponseCode.ServerErrorInternal) {
				successAlert('下載成功');
				CommonService.downloadByStream(res);
			} else {
				error2Alert(res.data.message);
			}
		},
		onError: (err) => {
			console.log('getEsgDisclosureExcel', err);
			error2Alert('執行失敗');
		},
	});

	//- openDeclareDataCorrectionRequestPermission
	const openDeclareDataCorrectionRequestPermission = useMutation({
		mutationFn: (id: string) => DeclarationService.openDeclareDataCorrectionRequestPermission(id),
		onSuccess: (res) => {
			console.log('openDeclareDataCorrectionRequestPermission', res);
			if (res.code === 200 && res.success) {
				success2Alert(res.message);
				getDeclareDataCorrectionRequestListMutate(esgDisclosureDataRequestModel);
			} else {
				error2Alert(res.message);
			}
		},
		onError: (err) => {
			console.log('openDeclareDataCorrectionRequestPermission', err);
			error2Alert('執行失敗');
		},
	});

	//- getYearList
	const { mutate: getYearListMutate, isPending: getYearListIsPending } = useMutation({
		mutationFn: () => DeclarationService.getYearList(),
		onSuccess: (res) => {
			console.log('getYearList', res);
			if (res.code === 200 && res.success) {
				//- 過濾res.data相同的年度
				const declareYearOptionsList = res.data
					.filter((e: number, i: number, self: number[]) => self.indexOf(e) === i)
					.map((e: number) => ({
						id: e.toString(),
						text: `${e.toString()} 年度`,
						enumKey: e,
					})) as OptionModel[];
				setDeclareYearOptions([
					{
						id: 'undefined',
						text: '全部',
						enumKey: undefined,
					},
					...declareYearOptionsList,
				]);
			}
		},
		onError: (err) => {
			console.log('getYearList', err);
			error2Alert('執行失敗');
		},
	});
	//* ---------------------------- api end ---------------------------- *//
	//* ---------------------------- function start ---------------------------- *//
	const openOpenDeclarationModal = () => {
		const declareSettingDataRequestModel: DeclareSettingDataRequestModel = {
			requestType: RequestTypeEnum.ESGDisclosure,
			declareYear: esgDisclosureDataRequestModel.declareYear,
		};
		useOpenDeclarationModelRef.current?.openModal(declareSettingDataRequestModel);
	};
	//* ---------------------------- function end ---------------------------- *//
	//* ---------------------------- hook start ---------------------------- *//
	// -useEffect esgDisclosureDataRequestModel
	useEffect(() => {
		getDeclareDataCorrectionRequestListMutate(esgDisclosureDataRequestModel);
		getYearListMutate();
	}, [esgDisclosureDataRequestModel, getDeclareDataCorrectionRequestListMutate, getYearListMutate]);

	// -useEffect esgDisclosureDataList
	useEffect(() => {
		setTablePagination((prev) => {
			if (esgDisclosureDataList?.list.length) {
				return {
					...prev,
					page: esgDisclosureDataRequestModel.page ?? 1,
					pageSize: esgDisclosureDataRequestModel.pageSize ?? 10,
					total: esgDisclosureDataList.count,
				};
			} else {
				return {
					...prev,
					page: 0,
					total: 0,
				};
			}
		});
	}, [esgDisclosureDataList, esgDisclosureDataRequestModel]);
	//* ---------------------------- hook end ---------------------------- *//

	return (
		<>
			<div className={`${styles['transaction-request-box']}`}>
				{getEsgDisclosureExcel.isPending || getYearListIsPending ? <VisuallLoading /> : null}
				<OperationHeaderComponent
					esgDisclosureDataRequestModel={esgDisclosureDataRequestModel}
					setDeclareDataRequestModel={setDeclareDataRequestModel}
					declareYearOptions={declareYearOptions}
					declareStatusEnumOptions={declareStatusEnumOptions}
					getEsgDisclosureExcel={getEsgDisclosureExcel}
					openOpenDeclarationModal={openOpenDeclarationModal}
				/>
				<TableComponent
					esgDisclosureDataList={esgDisclosureDataList.list}
					isLoading={getDeclareDataCorrectionRequestListIsPending}
					declareStatusEnumOptions={declareStatusEnumOptions}
					openDeclareDataCorrectionRequestPermission={openDeclareDataCorrectionRequestPermission}
					setDeclareDataRequestModel={setDeclareDataRequestModel}
					esgDisclosureDataRequestModel={esgDisclosureDataRequestModel}
				/>
				<div className={`${styles['table-pagination-box']}`}>
					<TablePagination
						total={tablePagination.total!}
						onChange={(e) => {
							setDeclareDataRequestModel((prev) => ({
								...prev,
								page: e.page,
								pageSize: e.pageSize,
							}));
						}}
						page={tablePagination.page}
						pageSize={tablePagination.pageSize}
					/>
				</div>
			</div>
			<OpenDeclarationModel ref={useOpenDeclarationModelRef} />
		</>
	);
};

export default EsgDisclosure;
