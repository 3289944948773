//* ---------------------------- React start ---------------------------- *//

import React, { FC, useEffect, useRef, useState } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation } from "@tanstack/react-query";

//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./SustainEconomic.module.scss";
import { MarketOp } from "models/optionModel";
import mopsEsgService from "services/inquiry/infoDisclosure/mopsEsgService";
import { OptionModel, RequestType } from "models/baseModel";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import {
  MopsSustainQuestionnaireDataRequestModel,
  MopsSustainQuestionnaireModel,
  CompanyQuestionnaireDataModel,
  CompanyQuestionnaireDataListModel,
  MopsSustainQuestionnaireDataFieldModel,
  SustainEconomicFilterModel,
} from "models/inquiry/sustainEconomic/sustainEconomicModel";
import SustainEconomicService from "services/inquiry/sustainEconomic/SustainEconomicService";

//* ---------------------------- local end ---------------------------- *//

import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import Dropdown from "components/base/Dropdown/Dropdown";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import toast from "react-hot-toast";
import SustainReportService from "services/inquiry/sustainReport/SustainReportService";
import IconButton from "components/button/IconButton/IconButton";
import ProblemReportModal, {
  ProblemReportModalProps,
} from "components/otherModule/ProblemReportModal/ProblemReportModal";
import { submitGAEvent } from "services/common/GA";
import { useTranslation } from "react-i18next";
import { CommonService } from "services/common/commonService";

//* FilterComponent
interface FilterProps {
  searchChange: (param: any) => void;
  onLoadingChange: (isLoading: boolean) => void;
}

const FilterComponent: FC<FilterProps> = (props) => {
  //* ---------------------------- state start ---------------------------- *//
  //- yearOption
  const [yearOption, setYearOption] = useState<OptionModel[]>([]);
  //- industryOption
  const [industryOption, setIndustryOption] = useState<OptionModel[]>([]);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState<
    OptionModel[]
  >([]);
  //- companyOption
  const [companyOption, setCompanyOption] = useState<OptionModel[]>([]);
  const [filterCompanyOption, setFilterCompanyOption] = useState<OptionModel[]>(
    []
  );
  //- searchBtnDisabled
  const [searchBtnDisabled, setSearchBtnDisabled] = useState<boolean>(true);
  //- searchRequest
  const [searchRequest, setSearchRequest] =
    useState<SustainEconomicFilterModel>(new SustainEconomicFilterModel());
  const { t } = useTranslation();
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getYearList
  const { mutate: getYearListMutate, isPending: getYearListIsPending } =
    useMutation({
      mutationFn: () => mopsEsgService.getYearList(),
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          const tmpYears: OptionModel[] = [];
          res.data
            .filter(
              (e: number, i: number, self: number[]) => self.indexOf(e) === i
            )
            .forEach((yr: number) => {
              tmpYears.push({ id: yr.toString(), text: yr.toString() });
            });
          setYearOption(tmpYears);
          // setSearchRequest({
          //   ...searchRequest,
          //   year: tmpYears.map((y) => parseInt(y.id, 10)),
          // });
        } else {
          setYearOption([]);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });

  //- getIndustryCompanyCode
  const {
    mutate: getIndustryCompanyCodeMutate,
    isPending: getIndustryCompanyCodeIsPending,
  } = useMutation({
    mutationFn: (marketType: string) =>
      SustainReportService.getCompanyCodes(marketType),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        setCompanyOptionModel(res.data.companyNameList);
        setIndustryOptionModel(res.data.industryNameList);
      }
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  /**設置公司選項model */
  const setCompanyOptionModel = (datas: any[]): OptionModel[] => {
    let tmpCompany: OptionModel[] = [
      {
        id: "all",
        text: t("ALL_SELECT"),
      },
    ];
    datas.forEach((cp: any) => {
      tmpCompany.push({
        id: cp.companyName.split(" ")[0],
        text: cp.companyName,
        code: cp.industryCode,
      });
    });
    setCompanyOption(tmpCompany);
    setFilterCompanyOption(tmpCompany);
    return tmpCompany;
  };
  /** 設置產業別option*/
  const setIndustryOptionModel = (datas: any[]): OptionModel[] => {
    const tmpIndustry: OptionModel[] = [
      {
        id: "all",
        text: t("ALL_SELECT"),
      },
    ];
    datas.forEach((indus: any) => {
      tmpIndustry.push({
        id: indus.industryName,
        text: indus.industryName,
        code: indus.industryCode,
      });
    });
    setIndustryOption(tmpIndustry);
    return tmpIndustry;
  };
  /**改變市場別 */
  const handleMarketTypeChange = (value: string | null) => {
    let tmpreq = { ...searchRequest };
    if (value) {
      const market = Number.parseInt(value);
      getIndustryCompanyCodeMutate(value);
      tmpreq = {
        ...tmpreq,
        marketType: market,
        companyCodeList: [],
        industryNameList: [],
        industryName: null,
        companyCode: null,
      };
    } else {
      tmpreq = {
        ...tmpreq,
        marketType: undefined,
        companyCodeList: [],
        industryNameList: [],
        industryName: null,
        companyCode: null,
      };
    }
    setSearchRequest(tmpreq);
  };

  //- handleYearsChange
  const handleYearsChange = (e: string | null) => {
    if (e) {
      let tmpreq = { ...searchRequest };
      tmpreq.year = [parseInt(e, 10)];
      if (tmpreq.marketType !== null) {
      }
      setSearchRequest(tmpreq);
    }
  };

  /** 改變產業別 */
  const handleIndustryChange = (id: string | null) => {
    //setSearchBtnDisabled(true);
    let tmpreq = { ...searchRequest };
    if (id) {
      // 全選
      if (id === "all") {
        tmpreq.industryNameList = getNotIncludeAllIds(industryOption, null);
        setFilterCompanyOption(companyOption);
        setSelectedIndustryOption(industryOption);
      } else {
        tmpreq.industryNameList = [id];
        const selectedOption = industryOption.find(
          (option) => option.id === id
        );
        if (selectedOption && selectedOption.code) {
          let filterResult = CommonService.deepClone(
            companyOption.filter(
              (companyOption) => companyOption.code === selectedOption.code
            )
          );
          let tmpCompany: OptionModel[] = [
            {
              id: "all",
              text: t("ALL_SELECT"),
            },
          ];
          setFilterCompanyOption(tmpCompany.concat(filterResult));
          setSelectedIndustryOption([selectedOption]);
        }
      }
      tmpreq.industryName = id;
      tmpreq.companyCodeList = [];
      tmpreq.companyCode = null;
    } else {
      tmpreq.industryNameList = [];
      tmpreq.companyCodeList = [];
      tmpreq.companyCode = null;
      tmpreq.industryName = null;
    }
    setSearchRequest(tmpreq);
  };

  /** 改變公司代號 */
  const handleCompanyChange = (id: string | null) => {
    let tmpreq = { ...searchRequest };
    if (id) {
      // 單一產業別全選
      if (selectedIndustryOption.length === 1) {
        // 單一公司
        if (id !== "all") {
          tmpreq.companyCodeList = [id];
        } else {
          // 全選公司
          tmpreq.companyCodeList = getNotIncludeAllIds(
            companyOption,
            selectedIndustryOption[0].code as string
          );
        }
      }
      // 全產業別&全選公司
      else if (id === "all") {
        tmpreq.companyCodeList = getNotIncludeAllIds(companyOption, null);
      } else {
        tmpreq.companyCodeList = [id];
      }
      tmpreq.companyCode = id;
    } else {
      tmpreq.companyCodeList = [];
      tmpreq.companyCode = null;
    }
    setSearchRequest(tmpreq);
  };

  const getNotIncludeAllIds = (
    list: OptionModel[],
    industryCode: string | null
  ) => {
    return list
      .filter(
        (p) =>
          p.id !== "all" && (industryCode === null || p.code === industryCode)
      )
      .map((p) => p.id);
  };

  const disabledCondition = (param: SustainEconomicFilterModel): boolean => {
    let disabled = false;
    // 這邊以陣列為主(才是實際查詢資料)
    if (
      param.year !== null &&
      param.marketType !== null &&
      (param.companyCode || param.companyCodeList.length > 0) &&
      (param.industryName || param.industryNameList.length > 0)
    ) {
      disabled = false;
    } else {
      disabled = true;
    }
    setSearchBtnDisabled(disabled);
    return disabled;
  };

  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- init
  useEffect(() => {
    getYearListMutate();
  }, []);

  //- useEffect getIndustryCompanyCodeIsPending
  useEffect(() => {
    props.onLoadingChange(getIndustryCompanyCodeIsPending);
  }, [getIndustryCompanyCodeIsPending, props]);

  useEffect(() => {
    if (searchRequest) {
      disabledCondition(searchRequest);
    }
  }, [searchRequest]);
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <>
      <div className="mt-3">
        <Dropdown
          placeholder={`${t("MARKET_TYPE")}*`}
          isFloatTitle={true}
          options={MarketOp.filter((e, i) => i < 2)}
          defaultId={searchRequest.marketType?.toString()}
          onChange={handleMarketTypeChange}
        />
      </div>

      <div className="mt-3">
        <Dropdown
          placeholder={`${t("REPORTING_YEAR")}*`}
          options={yearOption}
          isFloatTitle={true}
          // defaultId={searchRequest.year?.toString()}
          onChange={handleYearsChange}
        />
      </div>

      <div className="mt-3">
        <Dropdown
          placeholder={t("INDUSTRY_TYPE")}
          options={industryOption}
          defaultId={searchRequest.industryName}
          isFloatTitle={true}
          onChange={handleIndustryChange}
        />
      </div>
      <div className="mt-3">
        <Dropdown
          placeholder={t("COMPANY_CODE")}
          options={filterCompanyOption}
          defaultId={searchRequest.companyCode}
          isFloatTitle={true}
          onChange={handleCompanyChange}
        />
      </div>

      <div className="mt-3">
        <button
          onClick={() => {
            props.searchChange(searchRequest);
          }}
          disabled={searchBtnDisabled}
          className={`default ` + `${searchBtnDisabled ? "disabled " : ""}`}
        >
          {t("INQUIRY")}
        </button>
      </div>
    </>
  );
};

//* EmptyComponent
const EmptyComponent: FC<{ alertWord: string }> = ({ alertWord }) => {
  return <div className="empty-box">{alertWord}</div>;
};

//* CompanyComponent
interface CompanyProps {
  companyQuestionnaireData: CompanyQuestionnaireDataModel[];
  openAllDetailBox: boolean;
}

const CompanyComponent: FC<CompanyProps> = (props) => {
  //* ---------------------------- state start ---------------------------- *//
  const { t } = useTranslation();
  const [companyQuestionnaireData, setCompanyQuestionnaireData] = useState<
    CompanyQuestionnaireDataModel[]
  >(props.companyQuestionnaireData);
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  //- hideDetail
  const hideDetail = (item: CompanyQuestionnaireDataModel) => {
    setCompanyQuestionnaireData((prev) => {
      return prev.map((i) => {
        if (i.companyName === item.companyName) {
          i.isCollapse = !i.isCollapse;
        }
        return i;
      });
    });
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  //- useEffect openAllDetailBox
  useEffect(() => {
    setCompanyQuestionnaireData((prev) => {
      return prev.map((i) => {
        i.isCollapse = props.openAllDetailBox;
        return i;
      });
    });
  }, [props.openAllDetailBox]);

  useEffect(() => {
    if (props.companyQuestionnaireData) {
      setCompanyQuestionnaireData(props.companyQuestionnaireData);
    }
  }, [props.companyQuestionnaireData]);

  //* ---------------------------- hook end ---------------------------- *//

  return (
    <div>
      {companyQuestionnaireData.map((company, companyIndex) => {
        return (
          <div key={companyIndex} className="companyItem">
            <div className="section-title" onClick={() => hideDetail(company)}>
              <div>
                <img
                  alt="extend"
                  className={company.isCollapse ? "hide" : ""}
                  src="/assets/images/declaration/application/extend-up-icon.svg"
                />
              </div>
              <div>{company.companyName}</div>
            </div>
            {!company.isCollapse && (
              <>
                {company.questionnaireDataList.map((item, itemIndex) => {
                  return (
                    <div
                      key={itemIndex}
                      className={`section-content ${itemIndex && "not-first"}`}
                    >
                      <div className="questionnaire-name">
                        {item.sustainQuestionnaireName}
                      </div>
                      {item.comment ? (
                        <div className="message">{item.comment}</div>
                      ) : null}
                      <div className="table-box scroll">
                        <table
                          aria-label={t("SEARCH_RESULT")}
                          className="table-container inquiry-table"
                        >
                          <thead>
                            <tr>
                              {item.sustainQuestionnaireName !== "確信情形" && (
                                <th className="sitcky-col">
                                  {t("ACTIVITY_SERIAL_NUMBER")}
                                </th>
                              )}
                              {item.items[0].map((feild, feildIndex) => {
                                return <th key={feildIndex}>{feild.title}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {item.items.map((feild, filedIndex) => {
                              return (
                                <tr key={filedIndex}>
                                  {item.sustainQuestionnaireName !==
                                    "確信情形" && (
                                    <td className="sitcky-col">
                                      {filedIndex + 1}
                                    </td>
                                  )}
                                  {feild.map((col, colIndex) => {
                                    return (
                                      <td key={colIndex}>{col.value || "-"}</td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

//* SustainEconomic

const SustainEconomic: FC = () => {
  //* ---------------------------- state start ---------------------------- *//
  //- leftFilterHide
  const [leftFilterHide, setLeftFilterHide] = useState<boolean>(false);
  //- openAllDetailBox
  const [openAllDetailBox, setOpenAllDetailBox] = useState<boolean>(false);
  const { t } = useTranslation();
  //- alertWord
  const [alertWord, setAlertWord] = useState<string>("");
  //- searchRequest
  const searchRequest = useRef<MopsSustainQuestionnaireDataRequestModel>(
    new MopsSustainQuestionnaireDataRequestModel()
  );
  //- companyQuestionnaireData
  const [companyQuestionnaireData, setCompanyQuestionnaireData] = useState<
    CompanyQuestionnaireDataModel[]
  >([]);
  //- isIndustryCompanyCodePending
  const [isIndustryCompanyCodePending, setIsIndustryCompanyCodePending] =
    useState(false);

  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getMopsSustainQuestionnaireData
  const {
    mutate: getMopsSustainQuestionnaireDataMutate,
    isPending: getMopsSustainQuestionnaireDataIsPending,
  } = useMutation({
    mutationFn: (request: MopsSustainQuestionnaireDataRequestModel) =>
      SustainEconomicService.getMopsSustainQuestionnaireData(request),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        if (res.data.length === 0) {
          setCompanyQuestionnaireData([]);
          setAlertWord(t("NO_INFORMATION_FOUND"));
        } else {
          //- companyQuestionnaireData init
          const groupAndMergeData = (
            data: MopsSustainQuestionnaireModel[]
          ): CompanyQuestionnaireDataModel[] => {
            const groupedByCompany: {
              [key: string]: MopsSustainQuestionnaireModel[];
            } = {};

            // 按 companyName 分组
            data.forEach((item) => {
              if (!groupedByCompany[item.companyName]) {
                groupedByCompany[item.companyName] = [];
              }
              groupedByCompany[item.companyName].push(item);
            });

            return Object.entries(groupedByCompany).map(
              ([companyName, companyData]) => {
                const groupedByQuestionnaire: {
                  [key: string]: MopsSustainQuestionnaireModel[];
                } = {};

                // 按 sustainQuestionnaireId 分组
                companyData.forEach((item) => {
                  if (!groupedByQuestionnaire[item.sustainQuestionnaireId]) {
                    groupedByQuestionnaire[item.sustainQuestionnaireId] = [];
                  }
                  groupedByQuestionnaire[item.sustainQuestionnaireId].push(
                    item
                  );
                });

                const questionnaireDataList: CompanyQuestionnaireDataListModel[] =
                  Object.entries(groupedByQuestionnaire).map(
                    ([sustainQuestionnaireId, questionnaireData]) => {
                      return {
                        items: questionnaireData.map(
                          (item) =>
                            item.items as MopsSustainQuestionnaireDataFieldModel[]
                        ),
                        sustainQuestionnaireId,
                        sustainQuestionnaireName:
                          questionnaireData[0].sustainQuestionnaireName,
                        comment: questionnaireData[0].comment,
                        companyName,
                      };
                    }
                  );

                return {
                  ...new CompanyQuestionnaireDataModel(),
                  companyName,
                  questionnaireDataList,
                };
              }
            );
          };

          const result = groupAndMergeData(res.data);
          console.log(result);

          setCompanyQuestionnaireData(result);
        }

        //- ga event
        submitGAEvent("SustainEconomic_Inquiry");
      } else {
        setCompanyQuestionnaireData([]);
        setAlertWord(t("NO_INFORMATION_FOUND"));
      }
    },
    onError: (err) => {
      toast.error(err.message);
      setCompanyQuestionnaireData([]);
      setAlertWord(t("NO_INFORMATION_FOUND"));
    },
  });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//
  //- handleIndustryCompanyCodeLoading
  const handleIndustryCompanyCodeLoading = (isLoading: boolean) => {
    // console.log('handleIndustryCompanyCodeLoading',isLoading);
    setIsIndustryCompanyCodePending(isLoading);
  };
  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- component start ---------------------------- *//
  //* ---------------------------- component end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//

  useEffect(() => {
    // 初始化完翻譯再set
    setAlertWord(t("PLEASE_ENTER_SEARCH_CONDITION"));
  }, [t]);

  //!test
  useEffect(() => {
    // searchRequest.current.companyCodes = ["2727", "1233"];
    // searchRequest.current.yearList = [2024];
    // getMopsSustainQuestionnaireDataMutate(searchRequest.current);
  }, []);
  //* ---------------------------- hook end ---------------------------- *//

  //#region 問題回報modal
  const [problemModal, setProblemModal] = useState<ProblemReportModalProps>({
    show: false,
    handleClose: () => {
      setProblemModal({
        ...problemModal,
        show: false,
      });
    },
    size: "sm",
  });
  //#endregion

  return (
    <div className="no-nav-content-box">
      <div className={styles["sustainEconomic-box"]}>
        {(getMopsSustainQuestionnaireDataIsPending ||
          isIndustryCompanyCodePending) && <VisuallLoading />}
        {/* 左區塊 */}
        <div className={"left-box " + (leftFilterHide ? "hide" : "")}>
          <div style={{ display: leftFilterHide ? "none" : "block" }}>
            <div className="toggle-box">
              <div className="main-title">{t("SEARCH_CONDITION")}</div>
              <img
                alt=""
                src="/assets/images/buttonIcon/toggle-left-icon.svg"
                onClick={() => setLeftFilterHide((prev) => (prev = !prev))}
              />
            </div>
            <div className="search-item-box">
              <FilterComponent
                searchChange={(e) => {
                  searchRequest.current.yearList = e.year;
                  searchRequest.current.companyCodes = e.companyCodeList;
                  getMopsSustainQuestionnaireDataMutate(searchRequest.current);
                }}
                onLoadingChange={handleIndustryCompanyCodeLoading}
              />
            </div>
          </div>
          {leftFilterHide ? (
            <div className="img-tool">
              <img
                alt=""
                src="/assets/images/buttonIcon/toggle-right-icon.svg"
                onClick={() => setLeftFilterHide((prev) => (prev = !prev))}
              />
            </div>
          ) : null}
        </div>
        {/* 右區塊 */}
        <div className="right-box">
          {/* 上區塊 */}
          <div className="tool-box">
            <div className="result-title-box">
              <div className="main-title">{t("SEARCH_RESULT")}</div>
              <div className="sub-title">
                {companyQuestionnaireData
                  ? "(" + companyQuestionnaireData?.length + ")"
                  : ""}
              </div>
              {companyQuestionnaireData &&
              companyQuestionnaireData?.length > 0 ? (
                <>
                  <IconButton
                    imgName={
                      openAllDetailBox === true
                        ? "double-down-icon.svg"
                        : "double-up-icon.svg"
                    }
                    text={
                      openAllDetailBox === false
                        ? t("ALL_CONDENSED")
                        : t("EXPAND_ALL")
                    }
                    className="secondary"
                    onClick={() => setOpenAllDetailBox(!openAllDetailBox)}
                  />
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="tool-item-box">
              {/* <LinkIconButton
                imgName="print-icon.svg"
                text={t('PRINT_WEB_PAGE')}
                onClick={() => {}}
              />
              <LinkIconButton
                imgName="new-window-icon.svg"
                text={t('OPEN_NEW_WINDOW')}
                onClick={() => {}}
              />
              <LinkIconButton
                imgName="download-icon.svg"
                text={t('SAVE_AS_CSV')}
                onClick={() => {}}
              /> */}
              <LinkIconButton
                imgName="question-icon.svg"
                text={t("PROBLEM_REPORT")}
                onClick={() => {
                  setProblemModal({
                    ...problemModal,
                    show: true,
                  });
                }}
              />
            </div>
            <ProblemReportModal {...problemModal} />
          </div>
          <div className="result-box scroll">
            <ScrollToTopButton
              targetClassName={"result-box"}
              bottom={90}
              right={35}
            />
            {companyQuestionnaireData !== null &&
            companyQuestionnaireData.length > 0 ? (
              <CompanyComponent
                companyQuestionnaireData={companyQuestionnaireData}
                openAllDetailBox={openAllDetailBox}
              />
            ) : (
              <EmptyComponent alertWord={alertWord} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SustainEconomic;
