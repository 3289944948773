import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./RGstep1.module.scss";
import GroupTree from "pages/Application/ReportGeneration/ReportGenerationInfo/RGstep1/GroupTree/GroupTree";
import { v4 as uuid } from "uuid";
import { CommonService } from "services/common/commonService";
import SustainReportProduceService from "services/application/sustainReportDeclare/sustainReportProduce";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { ResponseCode } from "models/responseCodeModel";
import {
  FillType,
  ReportProduceAction,
  SustainReportManuscriptUserDataModel,
  SustainReportProduceStatusModel,
  SustainReportUserTreeNode,
  SustainReportUserTreeNodeRequestModel,
} from "models/application/sustainReportDeclare/sustainReportProduceModel";
import { error2Alert, success2Alert } from "utils/otherToast";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";

interface RGstep1Props {
  gotoStep: (step: number, status: ReportProduceAction) => void;
  showHeader: boolean;
  changeShowHeader: (show: boolean) => void;
}

const RGstep1: FC<RGstep1Props> = (props) => {
  const { gotoStep, showHeader, changeShowHeader } = props;
  /** 資料項目 */
  const [data, setData] = useState<SustainReportUserTreeNode[]>([]);
  /** 原始資料 */
  const [orgData, setOrgData] = useState<SustainReportUserTreeNode[]>([]);
  /** 是否全選 */
  const [isAll, setIsAll] = useState<boolean>(true);
  /** 路由param */
  const queryParam = useParams();
  /** 是否下一步 */
  const isNext = useRef<boolean>(false);
  /** 初始設置資料 */
  useEffect(() => {
    if (queryParam.id) {
      getUserSustainReportTree.mutate(queryParam.id);
    }
  }, []);

  useEffect(()=>{
    const listItems = document.querySelectorAll(".main-row");
    listItems.forEach((item, index) => {
      item.classList.remove("odd", "even");
      if (index % 2 === 0) {
        item.classList.add("even");
      } else {
        item.classList.add("odd");
      }
    });
  },[data])

  //#region 方法區塊

  /** 遞迴設置部分資料 */
  const setRecursionData = (items: SustainReportUserTreeNode[]) => {
    items.forEach((p: SustainReportUserTreeNode) => {
      // 是否有drag
      p.hasDrag = p.level > 0;
      p.show = true;
      if (p.children.length > 0) {
        setRecursionData(p.children);
      }
    });
  };

  /** 遞迴相關資料的flow */
  const setRecursionFlow = (items: SustainReportUserTreeNode[]) => {
    // 設置基本資料
    setRecursionData(items);
    initSelectAll(items);
    setData(CommonService.deepClone(items));
    setOrgData(CommonService.deepClone(items));
    // 確認是否有全選
  };

  /** 初始化全選 */
  const initSelectAll = (items: SustainReportUserTreeNode[]) => {
    let checkAll = true;

    const nextChecked = (childs: SustainReportUserTreeNode[]) => {
      childs.forEach((p) => {
        if (checkAll) {
          checkAll = p.isNeedFilled;
          if (checkAll && p.children.length > 0) {
            nextChecked(p.children);
          }
        }
      });
    };
    nextChecked(items);
    setIsAll(checkAll);
  };

  /** 點選item資訊 */
  const clickItem = (
    groupsIndex: number[],
    group: SustainReportUserTreeNode,
    type: string
  ) => {
    // 找出該物件對應的地方
    const getChildItem = (
      parent: SustainReportUserTreeNode[],
      position: number
    ): SustainReportUserTreeNode | null => {
      if (parent && position <= parent.length) {
        return parent[position];
      }
      return null;
    };

    /** 向下找 */
    const traverseDown = (node: SustainReportUserTreeNode, callback: any) => {
      // 遞迴歷遍子節點
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          traverseDown(child, callback);
        });
      }

      // 處理當前節點
      callback(node);
    };

    let choiceAll = true;

    /** 遍歷方法 */
    const traverseNestedArray = (
      array: SustainReportUserTreeNode[],
      selItem: SustainReportUserTreeNode
    ) => {
      array.forEach((node) => {
        traverseDown(node, (node: SustainReportUserTreeNode) => {
          if (node.userChapterId === selItem.userChapterId) {
            node.isNeedFilled = !node.isNeedFilled;
          } else {
            // 父層確認來自子層
            if (node.children.length > 0) {
              node.isNeedFilled = node.children.some(
                (item: SustainReportUserTreeNode) => item.isNeedFilled === true
              );
            }
          }
          // 任一沒選擇就代表尚未選擇全部
          if (choiceAll) {
            choiceAll = node.isNeedFilled;
          }
        });
      });
    };

    // /** 設定子層資料 */
    const setRecursionChecked = (
      itemData: SustainReportUserTreeNode[],
      checkStatus: boolean
    ) => {
      itemData.forEach((p) => {
        p.isNeedFilled = checkStatus;
        if (p.children.length > 0) {
          setRecursionChecked(p.children, checkStatus);
        }
      });
    };

    let newItem: SustainReportUserTreeNode | null;
    // 以下都是用orgData 去做更動
    groupsIndex.forEach((p, index: number) => {
      if (!newItem) {
        // 從原始資料取
        newItem = getChildItem(orgData, p);
      } else {
        newItem = getChildItem(newItem.children, p);
      }

      if (newItem) {
        // 找到最後
        if (index === groupsIndex.length - 1) {
          if (type === "extend") {
            // 展開項目
            newItem.show = !newItem.show;
          } else if (type === "checked") {
            // 將子層 遞迴設定確認
            setRecursionChecked(newItem.children, !newItem.isNeedFilled);
            // 回頭 更新父層的節點確認所有checked
            traverseNestedArray(orgData, group);
            // 設定全選
            setIsAll(choiceAll);
            console.log(orgData);
          }
        }
      }
    });

    // console.log(groupsIndex);
    // console.log(orgData);
    // console.log(type);
    setOrgData([...orgData]);
    setData(CommonService.deepClone(orgData));
  };

  /** 全選 */
  const changeAll = () => {
    // 設置全選
    let newAll = !isAll;
    // 遞迴調整所有節點的內容
    const recursionShow = (itemData: SustainReportUserTreeNode[]) => {
      itemData.forEach((p) => {
        // 必要的不可更改
        if (p.fillType !== FillType.Necessary) {
          p.isNeedFilled = newAll;
        }
        if (p.children.length > 0) {
          recursionShow(p.children);
        }
      });
    };
    recursionShow(orgData);

    setOrgData(orgData);
    setData(CommonService.deepClone(orgData));
    setIsAll(newAll);
  };

  /** 處理暫存的事件 */
  const handleSave = () => {
    let model: SustainReportUserTreeNodeRequestModel = {
      produceId: queryParam.id!,
      sustainReportUserTreeNode: [...data],
    };

    editDefaultSustainReportTree.mutate(model);
  };
  //#endregion

  //#region API區塊
  /** 永續報告書產製清單 */
  const getUserSustainReportTree = useMutation({
    mutationFn: (procedureId: string) =>
      SustainReportProduceService.getUserSustainReportTree(procedureId),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportUserTreeNode[] = res.data;
        // 從第一層開始
        setRecursionFlow(CommonService.deepClone(data));
        console.log(data);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 編輯章節預設樹狀表 */
  const editDefaultSustainReportTree = useMutation({
    mutationFn: (model: SustainReportUserTreeNodeRequestModel) =>
      SustainReportProduceService.editDefaultSustainReportTree(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        console.log(res);
        if (isNext.current) {
          gotoStep(2, ReportProduceAction.ReportGenerate);
        } else {
          success2Alert(res.message);
        }
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  //#endregion

  return (
    <div className={`${styles["rg-step1-box"]} scroll`}>
      {(getUserSustainReportTree.isPending ||
        editDefaultSustainReportTree.isPending) && <VisuallLoading />}
      <div className="title-box">
        <div className="title">
          <span>選擇報告書章節</span>
          <img
            className={showHeader ? "extend" : "non-extend"}
            alt=""
            src="/assets/images/buttonIcon/toggle-left-icon.svg"
            onClick={() => {
              changeShowHeader(!showHeader);
            }}
          />
        </div>
        <div className="tool-box">
          <div className="tool-item">
            <button
              className="secondary"
              onClick={() => {
                isNext.current = false;
                handleSave();
              }}
            >
              儲存
            </button>
          </div>
          <div className="tool-item">
            <button
              className="default"
              onClick={() => {
                isNext.current = true;
                handleSave();
              }}
            >
              下一步，製作報告書
            </button>
          </div>
        </div>
      </div>

      <div className="desc">
        永續報告書必要揭露章節包含GRI通用準則(GRI1~GRI3)、氣候專章(附表2)、產業別永續指標(附表1-1~1-14)。
        <br />
        章節分類說明：
        <span className="mark color1">「必要」</span>為永續報告書必需揭露章節，
        <span className="mark color2">「建議」</span>係依產業別建議揭露章節，
        <span className="mark color3">「其他」</span>則按公司需要可自行選擇。(
        <span className="mark color2">「建議」</span>及
        <span className="mark color3">「其他」</span>得視公司實際情況調整之)
        <br />
        舉例來說：對半導體業而言，供應鏈管理為常見之重大主題，系統列為
        <span className="mark color2">「建議」</span>
        ；童工非半導體業重要主題，系統列為
        <span className="mark color3">「其他」</span>。<br />
        <span className="operator-desc">
          操作說明：部分章節可透過
          <LinkIconButton
            className={"icon-move"}
            imgName={"hambur-icon.svg"}
            text={""}
            onClick={() => {}}
          />
          進行拖曳重新排列順序。
        </span>
      </div>

      <div className="table-result scroll">
        <table
          aria-label="查詢結果table"
          className="table-container sticky-table"
        >
          <thead>
            <tr>
              <th style={{ width: "48px" }}>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={isAll}
                    onChange={($event) => {
                      changeAll();
                    }}
                  />
                  <span>&nbsp;</span>
                </label>
              </th>
              <th scope="col" style={{ width: "130px" }}>
                章節編號
              </th>
              <th scope="col">章節名稱</th>
              <th scope="col" style={{ width: "230px" }}>
                章節分類
              </th>
              <th scope="col" style={{ width: "230px" }}>
                收合
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  padding: 0,
                }}
                colSpan={5}
              >
                <GroupTree
                  group={data}
                  clickItem={clickItem}
                  setGroup={setData}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RGstep1;
