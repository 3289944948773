import React, { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { SideBarItemModel } from "../../../models/baseModel";
import SideBarItem from "./SideBarItem";
import styles from "./SideBar.module.scss";
interface SidebarProps {
  items: SideBarItemModel[];
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { items } = props;
  const [show,setShow] =useState<boolean>(true);
  return (
    <div className={`${styles["sidebar-box"]} ${show? styles.show:''} `}>
      <div className="extend-icon" onClick={()=>{
        setShow(!show)
      }}>
        <img className={`${show ?'': 'right' }`} alt="" src="/assets/images/buttonIcon/toggle-left-icon.svg" />
      </div>
      {
        show && <div className="sidebar-content-box scroll">
        <ul className="flex-column">
          {items.map((p, index) => {
            return <SideBarItem key={index} item={p} />;
          })}
        </ul>
      </div>
      }
     
    </div>
  );
};

export default Sidebar;
