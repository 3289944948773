import React, { useEffect, useState } from "react";
import styles from "./TableRecordModal.module.scss";
import ContentModal, { ContentModalProps } from "components/base/ContentModal/ContentModal";
import { Table } from "models/admin/operatingRecordModel";

export interface TableRecordModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  modifiedTableList: Table[];
  originTableList: Table[];
}

const TableRecordModal: React.FC<TableRecordModalProps> = ({
  show,
  onClose,
  title,
  modifiedTableList,
  originTableList,
}) => {
  const closeModal = () => {
    onClose();
  };

  const [modal, setModal] = useState<ContentModalProps>({
    show: false,
    size: "xl",
    customClass: "open-TableRecord-modal",
    handleClose: closeModal,
    title: title,
  });

  useEffect(() => {
    setModal((prev) => ({ ...prev, show: show, title: title }));
  }, [show, title]);

  const createEmptyTable = (rowSize: number, colSize: number) => {
    return Array.from({ length: rowSize }, () => Array.from({ length: colSize }, () => ({ value: '', isDifferent: false })));
  };

  const fillTable = (rows: any[][], cells: any[]) => {
    cells.forEach(cell => {
      if (cell.RowIndex < rows.length && cell.ColumnIndex < rows[0].length) {
        rows[cell.RowIndex][cell.ColumnIndex] = {
          value: cell.CellValue || '',
          isDifferent: cell.IsDifferent || false,
        };
      } else {
        console.warn(`Cell with RowIndex ${cell.RowIndex} or ColumnIndex ${cell.ColumnIndex} is out of bounds`);
      }
    });
  };

  return (
    <ContentModal {...modal}>
      <div className={`${styles["open-tableRecord-modal-content"]}`}>
        <div className="result-box scroll">
          {/* 編輯前 */}
          <div className="statusTitle">編輯前</div>
          <div className="originTableContainer" style={{ marginBottom: '40px' }}>
            {originTableList.length === 0 && <div>無表單資料</div>}
            {originTableList.map((table, index) => {
              const rows = createEmptyTable(table.RowSize, table.ColumnSize);
              fillTable(rows, table.Cells);
              const modifiedTable = modifiedTableList[index] || { Cells: [], RowSize: table.RowSize, ColumnSize: table.ColumnSize };

              return (
                <div className="originTable" key={table.TableId} style={{ margin: '20px 10px', borderBottom: index < originTableList.length - 1 ? '2px solid #DBE8EF' : 'none' }}>
                  <div
                    className={`tableTitle ${modifiedTable && table.TableName !== modifiedTable.TableName ? 'originTableNameDiff' : ''}`}
                    style={{ marginBottom: '5px' }}
                  >
                    {table.TableName}
                  </div>
                  <table>
                    <tbody>
                      {rows.map((row, rowIndex) => (
                        <tr key={`${table.TableId}-row-${rowIndex}`} className={rowIndex % 2 === 0 ? '' : 'odd-row'}>
                          {row.map((cell, colIndex) => (
                            <td
                              key={`${table.TableId}-cell-${rowIndex}-${colIndex}`}
                              className={cell.isDifferent ? 'changedOnOrigin' : ''}
                            >
                              {cell.value}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>

          {/* 編輯後 */}
          <div className="statusTitle">編輯後</div>
          <div className="modifiedTableContainer">
            {modifiedTableList.length === 0 && <div>無表單資料</div>}
            {modifiedTableList.map((table, index) => {
              const rows = createEmptyTable(table.RowSize, table.ColumnSize);
              fillTable(rows, table.Cells);
              const orginTable = originTableList[index] || { Cells: [], RowSize: table.RowSize, ColumnSize: table.ColumnSize };

              return (
                <div className="modifiedTable" key={table.TableId} style={{ margin: '20px 10px', borderBottom: index < modifiedTableList.length - 1 ? '2px solid #DBE8EF' : 'none' }}>
                  <div
                    className={`tableTitle ${orginTable && table.TableName !== orginTable.TableName ? 'modifiedTableNameDiff' : ''}`}
                    style={{ marginBottom: '5px' }}
                  >
                    {table.TableName}
                  </div>
                  <table>
                    <tbody>
                      {rows.map((row, rowIndex) => (
                        <tr key={`${table.TableId}-row-${rowIndex}`} className={rowIndex % 2 === 0 ? '' : 'odd-row'}>
                          {row.map((cell, colIndex) => (
                            <td
                              key={`${table.TableId}-cell-${rowIndex}-${colIndex}`}
                              className={cell.isDifferent ? 'changedOnModified' : ''}
                            >
                              {cell.value}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ContentModal>
  );
};

export default TableRecordModal;