import React, { useEffect, useRef, useState } from "react";
import styles from "./OperatingRecord.module.scss";
import { SerchDateModel } from "models/admin/manuscriptModel";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import ManuscriptService from "services/admin/manuscriptService";
import { useMutation } from "@tanstack/react-query";
import { error2Alert, success2Alert } from "utils/otherToast";
import useUserInfoStore from "state/useUserInfoStore";
import Industry from "pages/Admin/Industry/Industry/Industry";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import Dropdown from "components/base/Dropdown/Dropdown";
import TablePagination from "components/base/TablePagination/TablePagination";
import OperatingRecordModal from "components/otherModule/OperatingRecordModal/OperatingRecordModal";
import DraftRecordModal from "components/otherModule/DraftRecordModal/DraftRecordModal";
import { Prev } from "react-bootstrap/esm/PageItem";
import { OperatingRacordListRequestModel, RecordList, PopupModel } from "models/admin/operatingRecordModel"
import OperatingRecordService from "services/admin/operatingRecordService"
import NecessarySettingModal from "components/otherModule/NecessarySettingModal/NecessarySettingModal";
import { CommonService } from "services/common/commonService";

const OperatingRecord: React.FC = () => {
  const [serchDate, setSerchDate] = useState<SerchDateModel>();
  // modal
  const [openOperatingRecordModal, setOpenOperatingRecordModal] = useState<boolean>(false);
  const [openDraftRecordModal, setOpenDraftRecordModal] = useState<boolean>(false);
  const [openNecessarySettingModal, setOpenNecessarySettingModal] = useState<boolean>(false);
  const [optionPlaceHolder, setOptionPlaceHolder] = useState<string>()
  // title
  const [popupModalModel, setPopupModalModel] = useState<PopupModel>({
    OperatingTitle: "",
    OperatingRecordId: "",
    DraftTitle: "",
    DraftRecordId: "",
    SettingTitle: "",
    SettingRecordId: "",
  });
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [reqFormData, setReqFormData] = useState<OperatingRacordListRequestModel>({
    page: 1,
    pageSize: 10,
  });
  const [paginationData, setPaginationData] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  })
  const [recordList, setRecordList] = useState<RecordList[]>([])
  const options = [
    { id: "4", text: "全部", enumKey: null },
    { id: "0", text: "年度列表", enumKey: 0 },
    { id: "1", text: "編輯章節", enumKey: 1 },
    { id: "2", text: "編輯底稿", enumKey: 2 },
    { id: "3", text: "必要揭露章節設定", enumKey: 3 },
  ];

  useEffect(() => {
    console.log('reqFormData', reqFormData);

    getRecordList(reqFormData)
  }, [reqFormData])


  const openOperatingModal = (title: string, recordId: string) => {
    setOpenOperatingRecordModal(true);
    setPopupModalModel((prev) => {
      return {
        ...prev,

        OperatingTitle: title,
        OperatingRecordId: recordId,
      }
    });
  };

  const openDraftModal = (title: string, recordId: string) => {
    setOpenDraftRecordModal(true);
    setPopupModalModel((prev) => {
      return {
        ...prev,
        DraftTitle: title,
        DraftRecordId: recordId,
      }
    });
  }

  const openSettingModal = (title: string, recordId: string) => {
    setOpenNecessarySettingModal(true)
    setPopupModalModel((prev) => {
      return {
        ...prev,
        SettingTitle: title,
        SettingRecordId: recordId,
      }
    });
  }

  const closeOperatingModal = () => {
    setOpenOperatingRecordModal(false);
    setOpenDraftRecordModal(false);
    setOpenNecessarySettingModal(false);
  };

  const { mutate: getRecordList, isPending: getDataPending } = useMutation({
    mutationFn: (req: OperatingRacordListRequestModel) =>
      OperatingRecordService.getOperatingRecordList(req),
    onSuccess: (res) => {
      setRecordList(res.data);
      console.log(res.data);

      setPaginationData((prev) => {
        return {
          ...prev,
          total: res.count ?? 0,
        }
      })
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const handleDropdownChange = (selectedId: any) => {

    const selectedOption = options.find(option => option.id === selectedId);
    if (selectedOption) {
      setReqFormData((prev) => ({
        ...prev,
        recordType: selectedOption.enumKey
      }));
      setOptionPlaceHolder(selectedOption.text)
    }

  };

  const exportToExcel = async () => {
    getSustainReportDeclareExcel(reqFormData)
  };

  const { mutate: getSustainReportDeclareExcel } = useMutation({
    mutationFn: (request: OperatingRacordListRequestModel) =>
      OperatingRecordService.getSustainReportDeclareExcel(request),
    onSuccess: (res) => {
      console.log("getSustainReportDeclareExcel", res);
      if (res.status !== 500) {
        CommonService.downloadByStream(res);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log("getSustainReportDeclareExcel", err);
      error2Alert("執行失敗");
    },
  });


  return (
    <div style={{ maxWidth: "calc(100vw - 260px)", height: "100%" }}>
      <OperatingRecordModal
        show={openOperatingRecordModal}
        title={popupModalModel.OperatingTitle}
        recordId={popupModalModel.OperatingRecordId}
        onClose={closeOperatingModal}
      />
      <DraftRecordModal
        show={openDraftRecordModal}
        title={popupModalModel.DraftTitle}
        recordId={popupModalModel.DraftRecordId}
        onClose={closeOperatingModal}
      />
      <NecessarySettingModal
        show={openNecessarySettingModal}
        title={popupModalModel.SettingTitle}
        recordId={popupModalModel.SettingRecordId}
        onClose={closeOperatingModal}
      />
      {getDataPending ? (<VisuallLoading />) : null}
      <div className={styles["OperatingRecord"]}>
        {/** 標題與編輯 */}
        <div className="title">系統操作紀錄</div>
        <div className="data-input d-flex" style={{ margin: "20px 0px" }}>
          <div className="d-flex">
            <DateRangePicker
              className={"custom-date-range-picker"}
              onChange={(e: any) => {
                if (e === null) {
                  console.log('e === null');
                  setReqFormData((prev) => ({
                    ...prev!,
                    startTime: undefined,
                    endTime: undefined,
                  }));

                } else {
                  setReqFormData((prev) => ({
                    ...prev!,
                    startTime: e![0] ? moment(e![0]).format() : undefined,
                    endTime: e![1] ? moment(e![1]).format() : undefined,
                  }));
                }
              }}
              value={[
                reqFormData?.startTime
                  ? new Date(reqFormData?.startTime)
                  : null,
                reqFormData?.endTime
                  ? new Date(reqFormData?.endTime)
                  : null,
              ]}
            />
            <div className={`select-item`} style={{ marginLeft: "16px" }}>
              <Dropdown
                placeholder={optionPlaceHolder ? optionPlaceHolder : "頁面"}
                options={options}
                isFloatTitle={false}
                onChange={(e) => handleDropdownChange(e)}
              />
            </div>
          </div>

          <div className="file-upload">
            <button
              className="upload-btn"
              onClick={exportToExcel} // 更新此處
            >
              <img
                src="/assets/images/buttonIcon/upload-btn-icon.png"
                alt="upload"
              />{" "}
              匯出 EXCEL
            </button>
          </div>
        </div>
        {/** 主表區 */}
        <div className="result-box scroll">
          <table className="main_table">
            <thead>
              <tr>
                <th className="th" style={{ borderLeft: "1px solid #DBE8EF" }}>
                  {" "}
                  建立時間{" "}
                </th>
                <th className="th">頁面</th>
                <th className="th">內容</th>
                <th className="th">登入帳號</th>
                <th className="th" style={{ borderRight: "1px solid #DBE8EF" }}>
                  IP
                </th>
              </tr>
            </thead>
            <tbody>
              {recordList.map((item, index) => (
                <tr key={index}>
                  <td className={index % 2 === 0 ? "even-class" : "odd-class"}>
                    {item.creatTime}
                  </td>
                  <td className={index % 2 === 0 ? "even-class" : "odd-class"}>
                    {item.pageDescription}
                  </td>
                  <td className={index % 2 === 0 ? "even-class" : "odd-class"}>
                    {item.content === "編輯章節" && (
                      <button
                        onClick={() => openOperatingModal("操作紀錄-編輯章節", item.recordId || "")}
                        className="chapters_button"
                      >
                        {item.content}
                      </button>
                    )}
                    {item.content === "編輯必要設定" && (
                      <button
                        onClick={() => openSettingModal("操作紀錄-編輯必要設定", item.recordId || "")}
                        className="chapters_button"
                      >
                        {item.content}
                      </button>
                    )}
                    {(item.content === "編輯底稿") && (
                      <button
                        onClick={() => openDraftModal("操作紀錄-編輯底稿", item.recordId || "")}
                        className="chapters_button"
                      >
                        {item.content}
                      </button>
                    )}
                    {item.content !== "編輯章節" &&
                      item.content !== "編輯底稿" &&
                      item.content !== "編輯必要設定" &&
                      item.content}
                  </td>
                  <td className={index % 2 === 0 ? "even-class" : "odd-class"}>
                    {item.userId}
                  </td>
                  <td className={index % 2 === 0 ? "even-class" : "odd-class"}>
                    {item.ipAdress}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* 分頁 */}
        <div className="pad-10 table-pagination">
          <TablePagination
            page={paginationData.page}
            pageSize={paginationData.pageSize}
            onChange={(e) => {
              setReqFormData((prev) => ({
                ...prev,
                page: e.page,
                pageSize: e.pageSize,
              }));
            }}
            total={paginationData.total}
          />
        </div>
      </div>
    </div>
  );
};

export default OperatingRecord;
