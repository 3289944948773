import React, {
  FC,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./RGstep2.module.scss";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { v4 as uuid } from "uuid";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import SustainReportProduceService from "services/application/sustainReportDeclare/sustainReportProduce";
import { ResponseCode } from "models/responseCodeModel";
import {
  ReportProduceAction,
  SustainReportManuscriptUserDataModel,
  SustainReportUserFlatTreeNode,
  SustainReportUserDataRequestModel,
  SustainReportUserPreviewModel,
  FileRequestModel,
  SustainReportProduceInfoModel,
  ReportProduceStatus,
} from "models/application/sustainReportDeclare/sustainReportProduceModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { CommonService } from "services/common/commonService";
import { error2Alert, success2Alert } from "utils/otherToast";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import StorageName from "constants/storageName";
import NormalInput from "components/base/NormalInput/NormalInput";
import BatchUploadModal, {
  BatchUploadModalProps,
} from "components/base/BatchUploadModal/BatchUploadModal";
import { PrintService } from "services/common/printService";
import { PrintElementType } from "models/common/printElementModel";
interface RGstep2Props {
  gotoStep: (step: number, status: ReportProduceAction) => void;
  onRef: any;
  showHeader: boolean;
  changeShowHeader: (show: boolean) => void;
}

const RGstep2: FC<RGstep2Props> = (props) => {
  const { gotoStep, showHeader, changeShowHeader } = props;
  /** 選擇樹狀節點 */
  const [selTreeItem, setSelTreeItem] =
    useState<SustainReportUserFlatTreeNode>();
  /** 總覽點選 */
  const [isOverView, setIsOverView] = useState<boolean>(true);
  /** 總覽資料 */
  const [overviewData, setOverviewData] = useState<
    SustainReportUserPreviewModel[]
  >([]);
  /** 路由參數 */
  const params = useParams();
  const [queryParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  /** 操作的樹 */
  const [treeData, setTreeData] = useState<SustainReportUserFlatTreeNode[]>([]);
  /** 彈窗內容 */
  const [popContent, setPopContent] = useState<string | null>("");
  /** 細節資料 */
  const [infoData, setInfoData] =
    useState<SustainReportManuscriptUserDataModel>({
      userChapterId: "",
      userChapterDataId: "",
      title: null,
      griStandards: null,
      content: null,
      griIndex: null,
      referenceExample: null,
      tableList: [],
      parameterList: [],
    });

  /** 設置預覽資料 */
  const [previewTreeItem, setPreivewTreeItem] =
    useState<SustainReportManuscriptUserDataModel>();

  /** 狀態資料 */
  const [statusInfoData, setStatusIndoData] =
    useState<SustainReportProduceInfoModel>({
      produceId: "",
      year: 0,
      produceStatus: ReportProduceStatus.NotStarted,
      stepStatus: ReportProduceAction.StartProduce,
      produceStatusName: "",
    });
  /** 是否全部填寫完畢 */
  const [fillAll, setFillAll] = useState<boolean>(false);
  /** 預覽資料 */
  const [previewData, setPreviewData] =
    useState<SustainReportUserPreviewModel>();
  /** pop modal內容 */
  const [popModal, setPopModal] = useState<ContentModalProps>({
    show: false,
    handleClose: () => {
      setPopModal({
        ...popModal,
        show: false,
      });
    },
    title: "",
    size: "lg",
  });
  /** preview modal內容 */
  const [previewPopModal, setPreviewPopModal] = useState<ContentModalProps>({
    show: false,
    handleClose: () => {
      setPreviewPopModal({
        ...previewPopModal,
        show: false,
      });
    },
    title: "",
    size: "lg",
  });
  /** 錯誤modal */
  const [errorModal, setErrorModal] = useState<ContentModalProps>({
    show: false,
    handleClose: () => closeErrorModal(),
    title: "指標匯入格式錯誤，請確認",
    size: "lg",
  });
  /** 隱藏樹狀區塊 */
  const [hideTree, setHideTree] = useState(false);
  /**  重整之前第一次編輯(點擊樹狀) */
  const firstEdit = useRef<boolean>(true);
  /** 是否下一步 */
  const isNext = useRef<boolean>(false);
  const [dataConfirmModal, setDataConfirmModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: false,
      });
    },
    handleConfirm: () => {},
    title: "資料確認",
  });
  /** 確認的訊息 */
  const [confirmMsg, setConfirmMsg] = useState<string>();
  /** 錯誤訊息 */
  const [errorMsg, setErrorMsg] = useState<String>();

  /** 批次上傳物件 */
  const [batchModal, setBatchModal] = useState<BatchUploadModalProps>({
    show: false,
    handleClose: () => {
      setBatchModal({
        ...batchModal,
        show: false,
      });
    },
    handleUpload: (e) => {
      uploadFile(e);
    },
    sampleDownloadUrl: "",
    uploadLoading: false,
    isExternalSample: true,
  });

  /** 暴露部分方法給外部使用 */
  useImperativeHandle(props.onRef, () => {
    return {
      openLeaveConfirm: openLeaveConfirm,
    };
  });

  useEffect(() => {
    if (params.id) {
      getUserSustainReportTreeFlat.mutate(params.id);
      getStatus.mutate(params.id);
    }
  }, []);

  //#region 樹狀相關
  interface TreeItemProps {
    item: SustainReportUserFlatTreeNode;
    key: string;
  }
  // 樹狀item
  const TreeItem: FC<TreeItemProps> = (props) => {
    const { item, key } = props;
    // 基礎樹狀
    let selImages = "blue";
    let isActive = selTreeItem?.userChapterId === item.userChapterId;
    if (isActive) {
      selImages = "white";
    }

    return (
      <div
        key={key}
        className={`tree-item `}
        style={{
          paddingLeft: item.level > 1 ? "24px" : "0",
        }}
        onClick={($event) => {
          $event.stopPropagation();
          clickTreeItemFlow(item);
        }}
      >
        <div className={`main-row ` + `${isActive ? "active" : ""} `}>
          {item.isFinished ? (
            <img
              className="check-img"
              alt=""
              src={`/assets/images/declaration/application/${selImages}-check-icon.svg`}
            />
          ) : (
            <div style={{ width: "21.6px" }}></div>
          )}
          <div>
            {item.chapterString} {item.title}
          </div>
        </div>

        {<Container data={item} />}
      </div>
    );
  };

  const Container: FC<{ data: SustainReportUserFlatTreeNode }> = (props) => {
    const { data } = props;

    return (
      <div key={`container_${data.userChapterId}`}>
        {(data.children ?? []).map((p, itenIndex: number) => {
          return <TreeItem key={`item_${p.userChapterId}`} item={p} />;
        })}
      </div>
    );
  };

  // 樹狀資訊
  const TreeComponent: FC<{ data: SustainReportUserFlatTreeNode[] }> = (
    props
  ) => {
    const { data } = props;

    return (
      <>
        {(data || [])?.map((item, index: number) => (
          <div className="category-box" key={item.userChapterId}>
            <div className={`tree-title `}>
              {item.chapterString} {item.title}
            </div>
            {item.children?.map((p, itenIndex: number) => {
              return <TreeItem key={`item_${p.userChapterId}`} item={p} />;
            })}
          </div>
        ))}
      </>
    );
  };
  //#endregion

  //#region API區塊
  /** 永續報告書狀態 */
  const getStatus = useMutation({
    mutationFn: (procedureId: string) =>
      SustainReportProduceService.getStatus(procedureId),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportProduceInfoModel = res.data;
        setStatusIndoData({ ...data });
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  /** 取得章節樹狀表  */
  const getUserSustainReportTreeFlat = useMutation({
    mutationFn: (procedureId: string) =>
      SustainReportProduceService.getUserSustainReportTreeFlat(procedureId),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportUserFlatTreeNode[] = res.data;

        // 預設
        setTreeFlow(data);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 取得章節下資料 */
  const getUserSustainReportData = useMutation({
    mutationFn: (model: SustainReportUserDataRequestModel) =>
      SustainReportProduceService.getUserSustainReportData(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportManuscriptUserDataModel = res.data;
        if (data) {
          setInfoData(data);
        }
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 編輯章節下資料 */
  const editUserSustainReportData = useMutation({
    mutationFn: (model: SustainReportManuscriptUserDataModel) =>
      SustainReportProduceService.editUserSustainReportData(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        if (isNext.current) {
          gotoStep(3, ReportProduceAction.SelectStyle);
        } else {
          success2Alert(res.message);
          getUserSustainReportTreeFlat.mutate(params.id!);
        }
      } else {
        error2Alert(res.message);
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 將章節下資料還原成預設 */
  const editUserSustainReportDataDefault = useMutation({
    mutationFn: (model: SustainReportUserDataRequestModel) =>
      SustainReportProduceService.editUserSustainReportDataDefault(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        success2Alert(res.message);
        if (selTreeItem) {
          clickTreeItem(selTreeItem);
        }
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 取得報告書總覽 */
  const getUserSustainReportPreview = useMutation({
    mutationFn: (id: string) =>
      SustainReportProduceService.getUserSustainReportPreview(id),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        let resData: SustainReportUserPreviewModel[] = res.data;
        // 濾掉第一筆根目錄
        resData = resData.slice(1, resData.length);
        setOverviewData([...resData]);
      } else {
        console.log(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 批次匯入範本檔案 (帶入現有資料) */
  const getReportProduceImportExcelExampleFileStream = useMutation({
    mutationFn: (model: FileRequestModel) =>
      SustainReportProduceService.getReportProduceImportExcelExampleFileStream(
        model
      ),
    onSuccess: (res) => {
      if (res.status !== ResponseCode.ServerErrorInternal) {
        success2Alert("匯出成功");
        CommonService.downloadByStream(res);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 批次匯入excel或csv檔 */
  const reportProduceImportExcel = useMutation({
    mutationFn: (model: any) =>
      SustainReportProduceService.reportProduceImportExcel(model),
    onSuccess: (x) => {
      let newModel = {};
      if (x.success) {
        if (x.code === 500) {
          setErrorMsg(x.message);
          newModel = {
            show: true,
          };
          setErrorModal({
            ...errorModal,
            show: true,
          });
        } else {
          newModel = {
            uploadLoading: false,
            show: false,
          };
          getUserSustainReportTreeFlat.mutate(params.id!);
          success2Alert(x.message);
        }
      } else {
        newModel = {
          uploadLoading: false,
        };
        error2Alert(x.message);
      }

      setBatchModal({
        ...batchModal,
        ...newModel,
      });
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  //#region 方法區塊
  /** 點擊樹狀流程 */
  const clickTreeItemFlow = (item: SustainReportUserFlatTreeNode) => {
    setIsOverView(false);
    // 編輯情境要跳出資訊
    openLeaveConfirm(() => {
      clickTreeItem(item);
    });
  };

  /** 點擊樹狀 */
  const clickTreeItem = (item: SustainReportUserFlatTreeNode) => {
    // 設置樹狀item資料
    setSelTreeItem(item);
    // 第一次編輯
    firstEdit.current = true;

    // 這邊是範本(尚未填寫資料並不一定有userChapterDataId)
    queryParams.set("chapterId", item.userChapterId);
    setSearchParams(queryParams);
    getUserSustainReportData.mutate({
      userChapterId: item.userChapterId,
      userChapterDataId: item.userChapterDataId,
    });
  };

  /** 設置單一節點預覽資料 */
  const setPreivewItemFlow = (item: SustainReportManuscriptUserDataModel) => {
    // 深複製
    const newItem = CommonService.deepClone(item);

    // 純文字 須把\n -> <br/> html 才能正常顯示
    if (newItem.content) {
      newItem.content = newItem.content.replaceAll("\n", "<br/>");
    } else {
      newItem.content = "";
    }
    // title
    newItem.content = `<div class="title">${selTreeItem?.chapterString} ${newItem.title}</div> ${newItem.content}`;
    //#region 取代內文參數
    if (newItem.parameterList && newItem.parameterList.length > 0) {
      newItem.parameterList.forEach((p) => {
        let key = `{${p.parameterName}}`;

        if (newItem.content) {
          newItem.content = newItem.content.replaceAll(key, p.value);
        }
      });
    }
    //#endregion

    //#region 取代table 內的參數
    if (newItem.tableList && newItem.tableList.length > 0) {
      newItem.tableList.forEach((table) => {
        // 取得所有cell val (有參數再替換cell 內容，不然跳過)
        if (newItem.parameterList && newItem.parameterList.length > 0) {
          newItem.parameterList.forEach((p) => {
            let key = `{${p.parameterName}}`;

            table.cells.forEach((cell) => {
              if (cell.cellValue) {
                cell.cellValue = cell.cellValue.replaceAll(key, p.value);
              }
            });
          });
        }

        // render table 內容
        let tableStr = `<table aria-label="${table.tableName}" class="table-container preview-table mt-20-p"><tobdy>`;

        Array.from({ length: table.rowSize }).forEach(
          (row, rowIndex: number) => {
            tableStr += `<tr>`;
            Array.from({
              length: table.columnSize,
            }).forEach((col, colIndex: number) => {
              const cellVal =
                table.cells.find(
                  (cell) =>
                    cell.rowIndex === rowIndex && cell.columnIndex === colIndex
                )?.cellValue || "";
              tableStr += `<td>${cellVal}</td>`;
            });
            tableStr += `</tr>`;
          }
        );
        tableStr += "</tbody></table>";
        newItem.content += tableStr;
      });
    }
    //#endregion

    // 設定樹狀預覽
    setPopContent(newItem.content);
  };

  /** 設置總覽預覽資料 */
  const setPreviewOverviewFlow = (item: SustainReportUserPreviewModel[]) => {
    const newItem = CommonService.deepClone(item);
    let allPreviewContent = "";
    // 總覽沒有table
    newItem.forEach((p) => {
      // 純文字 須把\n -> <br/> html 才能正常顯示
      if (p.content) {
        p.content = p.content.replaceAll("\n", "<br/>");
      } else {
        p.content = "";
      }
      // 這邊content 已經取代過躥數
      p.content = `<div class="title">${p.chapterString} ${p.title}</div> ${p.content}<br/>`;
      allPreviewContent += p.content;
    });

    setPopContent(allPreviewContent);
  };

  /** 取得樹狀下的遞迴item */
  const getRecursionItem = (
    node: SustainReportUserFlatTreeNode,
    id: string
  ): SustainReportUserFlatTreeNode | null => {
    // 遞迴歷遍子節點
    if (node.userChapterId === id) {
      return node;
    }

    for (let child of node.children) {
      const found = getRecursionItem(child, id);
      if (found) {
        return found;
      }
    }

    return null;
  };

  /** 取得樹狀下的遞迴item */
  const getRecursionFillInfo = (
    datas: SustainReportUserFlatTreeNode[]
  ): boolean => {
    let filled = true;

    const nextRecursionItemFilled = (
      childs: SustainReportUserFlatTreeNode[]
    ) => {
      childs.forEach((p) => {
        if (filled) {
          // 第一層樹跳過不判斷
          if (p.level > 0) {
            filled = p.isFinished;
          }
          if (filled && p.children.length > 0) {
            nextRecursionItemFilled(p.children);
          }
        }
      });
    };

    nextRecursionItemFilled(datas);
    return filled;
  };

  /** 預設樹狀相關流程 */
  const setTreeFlow = (datas: SustainReportUserFlatTreeNode[]) => {
    // 撇除根目錄
    setTreeData(CommonService.deepClone(datas));
    setFillAll(getRecursionFillInfo(datas));

    if (datas.length > 0) {
      setIsOverView(false);
      const chapterId = queryParams.get("chapterId");
      if (chapterId) {
        let defaultItem: SustainReportUserFlatTreeNode | null;
        // 遞迴找
        datas.forEach((p, index: number) => {
          if (!defaultItem) {
            defaultItem = getRecursionItem(p, chapterId);
          }
          // 有存在地端
          if (index === datas.length - 1 && defaultItem) {
            clickTreeItem(defaultItem);
          }
        });
      } else {
        // 帶第0筆 (第一層不可預設)
        let pass = false;
        datas.forEach((p) => {
          // 避免大類沒有第一筆資料
          if (!pass) {
            if (p.children.length > 0) {
              pass = true;
              clickTreeItem(p.children[0]);
            }
          }
        });
      }
    }
  };

  /** 點選總覽 */
  const clickOverview = () => {
    // 第一次編輯
    firstEdit.current = true;
    setIsOverView(true);
    // 移除queryParam
    queryParams.delete("chapterId");
    setSearchParams(queryParams);
    if (selTreeItem) {
      setSelTreeItem({
        ...selTreeItem,
        userChapterId: "all",
      });
    }

    getUserSustainReportPreview.mutate(params.id!);
  };

  /** 改變表格數值 */
  const changeCellVal = (
    tableId: string,
    rowIndex: number,
    colIndex: number,
    val: string
  ) => {
    if (infoData.tableList) {
      let newTableList = infoData.tableList?.map((table) => {
        if (table.tableId === tableId) {
          let cell = table.cells.find(
            (p) => p.rowIndex === rowIndex && p.columnIndex === colIndex
          );
          if (cell) {
            cell.cellValue = val;
          }
        }
        return table || [];
      });

      infoData.tableList = newTableList;

      setInfoData({
        ...infoData,
      });
    }
  };

  /** 儲存資料 */
  const saveData = () => {
    /**
     * griIndex & referenceExample 等不能由使用者修改的參數
     * 改為空避免防火牆擋住
     * */
    let resultData = CommonService.deepClone(infoData);
    resultData = {
      ...resultData,
      griIndex: "",
      referenceExample: "",
    };
    editUserSustainReportData.mutate(resultData);
  };

  /** 設定已編輯過 */
  const setAlreadyEdit = () => {
    firstEdit.current = false;
  };

  /** 下載檔案 */
  const downloadSample = (isImport: boolean) => {
    if (statusInfoData) {
      const model: FileRequestModel = {
        year: statusInfoData.year + 1910,
        isImport: isImport,
      };
      getReportProduceImportExcelExampleFileStream.mutate(model);
    } else {
      lostDataErr();
    }
  };

  /** 錯誤訊息 */
  const lostDataErr = () => {
    error2Alert("缺少參數資訊");
  };

  /** 上傳檔案 */
  const uploadFile = (files: any[]) => {
    const postData = new FormData();
    for (const file of files) {
      postData.append("file", file);
    }
    setBatchModal({
      ...batchModal,
      uploadLoading: true,
    });
    reportProduceImportExcel.mutate({
      year: statusInfoData.year + 1910,
      file: postData,
    });
  };

  /** 打開modal */
  const handleOpenModal = () => {
    if (infoData) {
      setBatchModal({
        ...batchModal,
        uploadLoading: false,
        show: true,
        // 更新新的方法進去，否則會出錯
        handleUpload(file) {
          uploadFile(file);
        },
      });
    } else {
      lostDataErr();
    }
  };

  /** 關閉錯誤modal */
  const closeErrorModal = () => {
    setErrorModal((prev) => {
      return {
        ...prev,
        show: !prev.show,
      };
    });
  };

  /** 打開離開確認 */
  const openLeaveConfirm = (callback: any | null) => {
    if (!firstEdit.current) {
      setDataConfirmModal({
        ...dataConfirmModal,
        show: true,
        handleConfirm() {
          if (callback) {
            callback();
          }
          setDataConfirmModal({
            ...dataConfirmModal,
            show: false,
          });
        },
      });
      setConfirmMsg("資料尚未儲存，確定要離開嗎?");
    } else {
      if (callback) {
        callback();
      }
    }
  };

  // 搜尋區塊隱藏
  const handleHideTree = () => {
    setHideTree((prev) => (prev = !prev));
  };

  /** 章節預覽 */
  const chapterPreview = (itemOverView: boolean) => {
    // 全預覽 or 單章節
    if (itemOverView) {
      setPreviewOverviewFlow(overviewData);
    } else {
      setPreivewItemFlow(infoData);
    }
    setPreviewPopModal({
      ...previewPopModal,
      title: "章節預覽",
      show: true,
    });
  };
  //#endregion

  return (
    <div className={styles["rg-step2-box"]}>
      {(getUserSustainReportTreeFlat.isPending ||
        getUserSustainReportData.isPending ||
        editUserSustainReportData.isPending ||
        getUserSustainReportPreview.isPending ||
        editUserSustainReportDataDefault.isPending ||
        getReportProduceImportExcelExampleFileStream.isPending ||
        reportProduceImportExcel.isPending) && <VisuallLoading />}
      <div className="title-box">
        <div className="title">
          <span>製作永續報告書</span>
          <img
            className={showHeader ? "extend" : "non-extend"}
            alt=""
            src="/assets/images/buttonIcon/toggle-left-icon.svg"
            onClick={() => {
              changeShowHeader(!showHeader);
            }}
          />
        </div>
        <div className="tool-box">
          <div className="tool-item">
            <LinkIconButton
              imgName={"export-icon.svg"}
              text={"匯出"}
              onClick={() => {
                downloadSample(false);
              }}
            />
          </div>
          <div className="tool-item">
            <LinkIconButton
              imgName={"batch-import-icon.svg"}
              text={"批次匯入"}
              onClick={handleOpenModal}
            />
          </div>
          <div className="tool-item">
            <button
              className="secondary"
              onClick={() => {
                gotoStep(1, ReportProduceAction.SelectChapter);
              }}
            >
              上一步，選擇章節
            </button>
          </div>
          <div className="tool-item">
            <button
              className={`default ` + `${!fillAll ? "disabled " : ""}`}
              disabled={!fillAll}
              onClick={() => {
                isNext.current = true;
                saveData();
              }}
            >
              下一步，選擇樣板
            </button>
          </div>
        </div>
      </div>
      <div className="result-box">
        <div className={`left-box ` + `${hideTree ? "hide" : ""} `}>
          <div style={{ display: hideTree ? "none" : "block", height: "100%" }}>
            <div className={`toggle-box`}>
              <div></div>
              <img
                className="toggle-img"
                alt=""
                src="/assets/images/buttonIcon/toggle-left-icon.svg"
                onClick={handleHideTree}
              />
            </div>
            <div className={`tree-box scroll `}>
              {/* 報告書總覽 */}
              <div
                onClick={() => {
                  openLeaveConfirm(clickOverview);
                }}
                className={
                  `overview-title-item ` + `${isOverView ? "active" : ""}`
                }
              >
                <img
                  className=""
                  alt="overview"
                  src={
                    `/assets/images/declaration/application/` +
                    `${
                      isOverView
                        ? "white-overview-icon.svg"
                        : "black-overview-icon.svg"
                    }`
                  }
                />
                <div>報告書總覽</div>
              </div>
              <TreeComponent data={treeData} />
            </div>
          </div>

          {/* 隱藏區塊的圖案 */}
          {hideTree && (
            <div className="img-tool">
              <img
                className="toggle-img"
                alt=""
                src="/assets/images/buttonIcon/toggle-right-icon.svg"
                onClick={handleHideTree}
              />
            </div>
          )}
        </div>
        <div className="center-line"></div>
        {/* 總覽 */}
        {isOverView && (
          <div className={`right-box scroll ` + `${hideTree ? "w-100" : ""} `}>
            <div className="overview-box">
              <div className="title-box">
                <div className="title">報告書總覽</div>
                <div className="tool-box">
                  <div className="tool-item">
                    <LinkIconButton
                      imgName="print-icon.svg"
                      text="列印網頁"
                      onClick={() => {
                        PrintService.openNewWindow(
                          PrintElementType.GenerateReportBox,
                          true
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-tool-box">
                <div className="modal-item">
                  <button
                    className="secondary"
                    onClick={() => {
                      chapterPreview(true);
                    }}
                  >
                    內容預覽
                  </button>
                </div>
              </div>
              <div id="generate-report-box" className="overview-info-box">
                {overviewData.map((p, index) => {
                  return (
                    <div
                      key={`overview-item_${index}`}
                      className="overview-item"
                    >
                      <div className="overview-title">
                        {p.chapterString} {p.title}
                      </div>
                      <div className="overview-content">{p.content}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {/* 非總覽 */}
        {!isOverView && (
          <div className={`right-box scroll ` + `${hideTree ? "w-100" : ""} `}>
            <div className="non-overview-box">
              <div className="title-box">
                <div className="title">
                  <div>
                    {selTreeItem?.chapterString} {infoData.title}
                  </div>
                </div>
                <div className="tool-box w120-p ">
                  <button
                    className="default"
                    onClick={() => {
                      isNext.current = false;
                      saveData();
                    }}
                  >
                    儲存
                  </button>
                </div>
              </div>
              <div className="modal-tool-box">
                {infoData.griIndex && (
                  <div className="modal-item">
                    <button
                      className="secondary"
                      onClick={() => {
                        setPopModal({
                          ...popModal,
                          title: "GRI指標",
                          show: true,
                        });
                        setPopContent(infoData.griIndex);
                      }}
                    >
                      GRI指標
                    </button>
                  </div>
                )}
                {infoData.referenceExample && (
                  <div className="modal-item">
                    <button
                      className="secondary"
                      onClick={() => {
                        setPopModal({
                          ...popModal,
                          title: "參考範例",
                          show: true,
                        });
                        setPopContent(infoData.referenceExample);
                      }}
                    >
                      參考範例
                    </button>
                  </div>
                )}
                <div className="modal-item">
                  <button
                    className="secondary"
                    onClick={() => {
                      chapterPreview(false);
                    }}
                  >
                    內容預覽
                  </button>
                </div>
              </div>

              <div className="manuscript-box">
                <div className="manuscript-info mb-20-p mt-20-p">
                  <div className="title">底稿撰寫</div>
                  <div className="content">
                    1.　底稿{"{XXX}"}
                    中，將自動帶入本年度申報之企業ESG資訊，公司可自行調整。底稿[XXX]中，請公司自行填入相關資訊。若有表格不敷使用，請於匯出報告書後自行調整。
                  </div>
                </div>
                {infoData.parameterList &&
                  infoData.parameterList?.length > 0 && (
                    <div className="ref-info mb-20-p">
                      <div>2.「企業ESG資訊」帶入內容：</div>
                      <div className="content">
                        {infoData.parameterList?.map((p, index) => {
                          return (
                            <div key={index}>
                              {index + 1}.{p.parameterName}：{p.value}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                <div className="mb-20-p">
                  <div className="d-flex justify-content-end">
                    <LinkIconButton
                      imgName={"refresh2-icon.svg"}
                      text={"回復預設底稿"}
                      onClick={() => {
                        if (selTreeItem) {
                          setDataConfirmModal({
                            ...dataConfirmModal,
                            show: true,
                            handleConfirm() {
                              // 樹狀userChapterDataId 可能是null 所以要取info資訊的
                              editUserSustainReportDataDefault.mutate({
                                userChapterId: infoData.userChapterId,
                                userChapterDataId: infoData.userChapterDataId,
                              });
                              setDataConfirmModal({
                                ...dataConfirmModal,
                                show: false,
                              });
                            },
                          });
                          setConfirmMsg("確認回復預設底稿?");
                        }
                      }}
                    />
                  </div>
                  <textarea
                    className="custom-textarea scroll"
                    rows={10}
                    value={infoData.content || ""}
                    onChange={($event) => {
                      setAlreadyEdit();
                      setInfoData({
                        ...infoData,
                        content: $event.target.value,
                      });
                    }}
                  ></textarea>
                </div>
                <div className="dynamic-table-box mb-20-p">
                  {infoData.tableList?.map((table) => {
                    return (
                      <div key={table.tableId}>
                        <div>{table.tableName}</div>
                        <table
                          aria-label="查詢結果table"
                          className="table-container mb-20-p"
                        >
                          <tbody>
                            {Array.from({ length: table.rowSize }).map(
                              (row, rowIndex: number) => {
                                return (
                                  <tr key={rowIndex}>
                                    {Array.from({
                                      length: table.columnSize,
                                    }).map((col, colIndex: number) => {
                                      return (
                                        <td key={`${rowIndex}_${colIndex}`}>
                                          <NormalInput
                                            isFloatTitle={false}
                                            placeholder=""
                                            defaultValue={
                                              table.cells.find(
                                                (cell) =>
                                                  cell.rowIndex === rowIndex &&
                                                  cell.columnIndex === colIndex
                                              )?.cellValue || ""
                                            }
                                            onChange={(e) => {
                                              setAlreadyEdit();
                                              changeCellVal(
                                                table.tableId,
                                                rowIndex,
                                                colIndex,
                                                e
                                              );
                                            }}
                                          />
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              }
                            )}
                            <tr></tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* 參考範例 or GRI 準則說明  */}
      <ContentModal {...popModal}>
        <div
          className="scroll rg-step2-content-desc-box"
          dangerouslySetInnerHTML={CommonService.sanitizedData(popContent)}
        ></div>
      </ContentModal>
      {/* preview modal */}
      <ContentModal {...previewPopModal}>
        <div
          className="scroll rg-step2-preview-box"
          dangerouslySetInnerHTML={CommonService.sanitizedData(popContent)}
        ></div>
      </ContentModal>
      {/* 資料尚未儲存modal */}
      <ConfirmModal {...dataConfirmModal}>
        <div>{confirmMsg}</div>
      </ConfirmModal>
      {/* 批次匯入彈窗 */}
      <BatchUploadModal
        {...batchModal}
        handleDownloadExternalSample={() => {
          downloadSample(true);
        }}
      />
      <ContentModal {...errorModal}>
        <div
          style={{
            maxHeight: "200px",
            overflow: "auto",
            whiteSpace: "pre-line",
          }}
        >
          {errorMsg}
        </div>
      </ContentModal>
    </div>
  );
};

export default RGstep2;
