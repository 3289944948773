export class SustainEconomicFilterModel {
  marketType?: number;
  year: number[] = [];
  industryNameList: string[] = [];
  companyCodeList: string[] = [];
  /** 前台使用 */
  industryName?: string | null;
  companyCode?: string | null;
}

export class MopsSustainQuestionnaireDataRequestModel {
  companyCodes: string[] = [];
  yearList: number[] = [];
}

export class MopsSustainQuestionnaireModel {
  items: MopsSustainQuestionnaireDataFieldModel[] = [];
  sustainQuestionnaireId: string = '';
  sustainQuestionnaireName: string = '';
  comment: string = '';
  companyName: string = '';
}

//-export webuse companyQuestionnaireDataModel
export class CompanyQuestionnaireDataModel {
  //- web use
  isCollapse: boolean = false;
  companyName: string = '';
  questionnaireDataList: CompanyQuestionnaireDataListModel[] = [];
}

//-export webuse companyQuestionnaireDataModel
export class CompanyQuestionnaireDataListModel {
  items: MopsSustainQuestionnaireDataFieldModel[][] = [];
  sustainQuestionnaireId: string = '';
  sustainQuestionnaireName: string = '';
  comment: string = '';
  companyName: string = '';
}

//-export webuse companyQuestionnaireDataModel
export interface MopsSustainQuestionnaireDataFieldModel {
  title: string;
  value: string;
  /** 後端定義代碼 */
  code: string;
}
