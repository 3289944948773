import React, { useEffect, useRef, useState } from "react";
import styles from "./DisclosureSettings.module.scss";
import { DisclosureSettingIndicatorModel, DisclosureSettingModel } from "models/admin/manuscriptModel";
import toast from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import ManuscriptService from "services/admin/manuscriptService";
import { useMutation } from "@tanstack/react-query";
import { error2Alert, success2Alert } from "utils/otherToast";
import useUserInfoStore from "state/useUserInfoStore";
import Industry from "pages/Admin/Industry/Industry/Industry";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";

const DisclosureSettings: React.FC = () => {
  const params = useParams();
  const manageId = params.id || null;
  const [searchParams] = useSearchParams();
  const year = searchParams.get('year');
  const { userInfo, setUserInfo } = useUserInfoStore();
  //const id = param.id;
  const [isEdit, setIsEdit] = useState(false);
  const [editType, setEditType] = useState<string | null>(null);
  const [data, setData] = useState<Array<DisclosureSettingModel>>([]);

  useEffect(()=>{
    console.log('data',data);
    
  },[data])

  const {
    mutate: getDisclosureSettingMutate,
    isPending: getDisclosureSettingPending,
  } = useMutation({
    mutationFn: (request: {
      year: string | null;
      marketType: number;
    }) =>
      ManuscriptService.getDisclosureSetting(
        request.year,
        request.marketType
      ),
    onSuccess: (res) => {
      // console.log("getDefaultChapterData", res);
      if (res.code === 200 && res.success) {
        setData(res.data);
      } else {
        error2Alert(res.message);
      }
      // initPageState();
    },
    onError: (err) => {
      // initPageState();
      console.log("getDefaultChapterData", err);
      error2Alert("執行失敗");
    },
  });

  const {
    mutate: editDisclosureSetting,
    isPending: editPending,
  } = useMutation({
    mutationFn: (request: 
              { data: Array<DisclosureSettingModel>, 
                manageId: string | null}) =>
      ManuscriptService.editDisclosureSetting(request.data, manageId),
    onSuccess: (res) => {
      // console.log("getDefaultChapterData", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
      } else {
        error2Alert(res.message);
      }
      // initPageState();
    },
    onError: (err) => {
      // initPageState();
      console.log("getDefaultChapterData", err);
      error2Alert("執行失敗");
    },
  });

  const handleType = (industryId: string, item: DisclosureSettingIndicatorModel, type: string) => {
    if(!editType) {
      toast.error('請先選擇類別');
    } else {
      const tmpData = [...data];
      tmpData.forEach((t: DisclosureSettingModel) => {
        t.reportIndicator.forEach((rind: DisclosureSettingIndicatorModel) => {
          if(rind.id === item.id && industryId === t.industryId) {
            if(type==='over') {
              rind.over2BType = parseInt(editType, 10);
            } else {
              rind.under2BType = parseInt(editType, 10);
            }
          }
        });
      });
      setData(tmpData);
    }
  }

  const save = () => {
    console.log(data);
    editDisclosureSetting({data: data, manageId: manageId});
  }

  const cancel = () => {
    setIsEdit(false);
    setEditType(null);
    getDisclosureSettingMutate({year: year || null, marketType: userInfo.marketType});
  }

  useEffect(() => {
    //console.log(userInfo.marketType);
    getDisclosureSettingMutate({year: year || null, marketType: userInfo.marketType});
  }, []);

  return (
    <div style={{maxWidth: 'calc(100vw - 260px)', height: '100%'}}>
      {getDisclosureSettingPending ||
        editPending ? (
          <VisuallLoading />
        ) : null}
      <div className={styles["DisclosureSettings"]}>
        {/** 標題與編輯 */}
        <div className="d-flex justify-content-between">
            <div className="title">各行業之必要揭露章節設定</div>
            <div className="d-flex">
              {isEdit ? (
                <>
                  <button className="secondary" onClick={() => {cancel()}}>
                    取消
                  </button>
                  <button className="default" onClick={() => {save()}}>
                    儲存
                  </button>
                </>
              ) : (
              <button className={`default ${data.length === 0 ? "disabled" : ""}`}
                      disabled={data.length === 0}
                      onClick={() => {setIsEdit(true)}}>
                編輯
              </button>
              )}
            </div>
        </div>
        {/** 設定安紐 */}
        <div className="d-flex justify-content-start mt-3 mb-3 setting">
          {isEdit ? (
            <>
              <button className={`require ${editType === '0' ? "active" : ""}`}  onClick={() => {setEditType('0')}}>必要揭露</button>
              <button className={`recommend ${editType === '1' ? "active" : ""}`} onClick={() => {setEditType('1')}}>建議揭露</button>
              <button className={`other ${editType === '2'? "active" : ""}`} onClick={() => {setEditType('2')}}>其他</button>
            </>
          ) : (
            <>
            <div className="require icon-desc">必要揭露</div>
            <div className="recommend icon-desc">建議揭露</div>
            <div className="other icon-desc">其他</div>
            </>
          )}
        </div>
        {/** 主表區 */}
        <div className="result-box scroll">

          {/**左方固定title */}
          <div className="fix-table-box settingTable-block">
            <table
              aria-label="查詢結果table"
              className="table-container sticky-table"
              >
              <thead>
                <tr>
                    <th scope="col">項目 \ 行業別</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="capital-title">資本額</td>
                </tr>

                {!data || data.length === 0 ? <tr><td>無資料</td></tr> : 
                  data[0].reportIndicator.map((left, i) => {
                    return (
                    <tr key={`tr_${i}_left`}>
                      <td><div>{left.title}</div></td>
                    </tr>
                    );
                  })
                }
                
              </tbody>
            </table>
          </div>

          {/**右方卷軸資料 */}
          <div className="settingTable-block2">
            <table
              key='table2'
              aria-label="查詢結果table"
              className="table-container sticky-table"
              >
                <thead>
                  <tr>
                    {!data || data.length === 0 ? <></> : 
                      data.map((tit, i) => {
                        return (
                          <th colSpan={2} key={`th_${i}_title`}>{tit.name}</th>
                        );
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  <tr>

                    {!data || data.length === 0 ? <></> : 
                      data.map((tit, i) => {
                        return (
                          <React.Fragment key={`tr_${i}_title`}>
                            <td className="capital-title">20億以上</td>
                            <td className="capital-title">20億以下</td>
                          </React.Fragment>
                        );
                      })
                    }

                  </tr>
                  { !data || data.length === 0 ? <></> :
                      data[0].reportIndicator.map((indic, i) => {
                          return (
                            <tr key={`tr_${i}_left`}>
                              {data.map((item, ti) => { 
                                return (<React.Fragment key={`tr_map_${ti}`}>
                                {
                                  item.reportIndicator.map((indicSub, vi) => {
                                    if(indic.id === indicSub.id) {
                                      if(isEdit) {
                                        return (
                                          <React.Fragment key={`tr_${vi}`}>
                                            {/**20億以上 */}
                                            <td
                                                className="cur-pointer"
                                                onClick={()=> handleType(item.industryId, indicSub, 'over')}>
                                              <div className={`icon_${indicSub.over2BType}`}></div>
                                            </td>
                                            {/**20億以下 */}
                                            <td
                                              className="cur-pointer"
                                              onClick={()=> handleType(item.industryId, indicSub, 'under')}>
                                              <div className={`icon_${indicSub.under2BType}`}></div>
                                            </td>
                                          </React.Fragment>
                                        )
                                      } else {
                                        return (
                                          <React.Fragment key={`tr_${vi}`}>
                                            {/**20億以上 */}
                                            <td>
                                              <div className={`icon_${indicSub.over2BType}`}></div>
                                            </td>
                                            {/**20億以下 */}
                                            <td>
                                              <div className={`icon_${indicSub.under2BType}`}></div>
                                            </td>
                                          </React.Fragment>
                                        )
                                      }
                                    }
                                  })
                                }
                                </React.Fragment>)
                                })
                              }
                            </tr>
                          )
                      })
                  }
                  
                </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  );
};

export default DisclosureSettings;
