import "./scss/all.scss";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import VisuallLoading from "./components/common/VisuallLoading/VisuallLoading";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AxiosInterceptor } from "./context/AxiosProvider";
import ToasterProvider from "context/ToastProvider";
import { GTM, Gtag } from "./services/common/GA";

// 取得index.html 原點
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// 創建實體
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      console.log(`queryCache error: ${error}`);
    },
  }),
  // 只有mutate會執行，如果用mutateAsync 需自行catch
  mutationCache: new MutationCache({
    onError: (error) => {
      console.log(`mutationCache error: ${error}`);
    },
  }),
});
queryClient.setDefaultOptions({
  queries: {
    refetchOnWindowFocus: false,
    retry: 0,
  },
  mutations: {
    // 暫時不新增 (會導致傳的error 報錯)
    throwOnError: false,
  },
});

// 渲染組件
root.render(
  <>
    {/* <React.StrictMode>*/}
    {/* Suspense 語言從外部 加載時效果理想 */}
    <Suspense fallback={<VisuallLoading loadText={"Loading..."} />}>
      <GTM />
      <Gtag />
      <ToasterProvider />
      <AxiosInterceptor>
        <QueryClientProvider client={queryClient}>
          <App />
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </AxiosInterceptor>
    </Suspense>
    {/*   </React.StrictMode>*/}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
