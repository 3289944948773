import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./AdminReportGenerationDetail.module.scss";
import ContentModal, {
  ContentModalProps,
} from "components/base/ContentModal/ContentModal";
import { useParams, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import SustainReportProduceService from "services/application/sustainReportDeclare/sustainReportProduce";
import { ResponseCode } from "models/responseCodeModel";
import {
  SustainReportManuscriptUserDataModel,
  SustainReportUserFlatTreeNode,
  SustainReportUserDataRequestModel,
  SustainReportUserPreviewModel,
} from "models/application/sustainReportDeclare/sustainReportProduceModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { CommonService } from "services/common/commonService";
import NormalInput from "components/base/NormalInput/NormalInput";
import BackTitle from "components/base/BackTitle/BackTitle";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import { PrintService } from "services/common/printService";
import { PrintElementType } from "models/common/printElementModel";
import DeclarationService from "services/admin/declarationService";
interface AdminReportGenerationDetailProps {}

const AdminReportGenerationDetail: FC<AdminReportGenerationDetailProps> = (
  props
) => {
  /** 選擇樹狀節點 */
  const [selTreeItem, setSelTreeItem] =
    useState<SustainReportUserFlatTreeNode>();
  /** 總覽點選 */
  const [isOverView, setIsOverView] = useState<boolean>(true);
  /** 總覽資料 */
  const [overviewData, setOverviewData] = useState<
    SustainReportUserPreviewModel[]
  >([]);
  /** 路由參數 */
  const params = useParams();
  const [queryParams, setSearchParams] = useSearchParams();
  /** 操作的樹 */
  const [treeData, setTreeData] = useState<SustainReportUserFlatTreeNode[]>([]);
  /** 彈窗內容 */
  const [popContent, setPopContent] = useState<string | null>("");
  /** 細節資料 */
  const [infoData, setInfoData] =
    useState<SustainReportManuscriptUserDataModel>({
      userChapterId: "",
      userChapterDataId: "",
      title: null,
      griStandards: null,
      content: null,
      griIndex: null,
      referenceExample: null,
      tableList: [],
      parameterList: [],
    });

  /** 是否全部填寫完畢 */
  const [fillAll, setFillAll] = useState<boolean>(false);
  /** pop modal內容 */
  const [popModal, setPopModal] = useState<ContentModalProps>({
    show: false,
    handleClose: () => {
      setPopModal({
        ...popModal,
        show: false,
      });
    },
    title: "",
    size: "lg",
  });
  /** 隱藏樹狀區塊 */
  const [hideTree, setHideTree] = useState(false);
  /** 清單頁帶過來的資訊 */
  const [compaynData, setCompanyData] = useState<string>('');
  useEffect(() => {
    if (params.id) {
      getUserSustainReportTreeFlat.mutate(params.id);
      getCompanyInfo.mutate(params.id);
    }
  }, []);

  //#region 樹狀相關
  interface TreeItemProps {
    item: SustainReportUserFlatTreeNode;
  }
  // 樹狀item
  const TreeItem: FC<TreeItemProps> = (props) => {
    const { item } = props;
    // 基礎樹狀
    let selImages = "blue";
    let isActive = selTreeItem?.userChapterId === item.userChapterId;
    if (isActive) {
      selImages = "white";
    }

    return (
      <div
        key={`item_${item.userChapterId}`}
        className={`tree-item `}
        style={{
          paddingLeft: item.level > 1 ? "24px" : "0",
        }}
        onClick={($event) => {
          $event.stopPropagation();
          clickTreeItemFlow(item);
        }}
      >
        <div className={`main-row ` + `${isActive ? "active" : ""} `}>
          {item.isFinished ? (
            <img
              className="check-img"
              alt=""
              src={`/assets/images/declaration/application/${selImages}-check-icon.svg`}
            />
          ) : (
            <div style={{ width: "21.6px" }}></div>
          )}
          <div>
            {item.chapterString} {item.title}
          </div>
        </div>

        {<Container data={item} />}
      </div>
    );
  };

  const Container: FC<{ data: SustainReportUserFlatTreeNode }> = (props) => {
    const { data } = props;

    return (
      <div key={`container_${data.userChapterId}`}>
        {(data.children ?? []).map((p, itenIndex: number) => {
          return <TreeItem item={p} />;
        })}
      </div>
    );
  };

  // 樹狀資訊
  const TreeComponent: FC<{ data: SustainReportUserFlatTreeNode[] }> = (
    props
  ) => {
    const { data } = props;

    return (
      <>
        {(data || [])?.map((item, index: number) => (
          <div className="category-box" key={item.userChapterId}>
            <div className={`tree-title `}>
              {item.chapterString} {item.title}
            </div>
            {item.children?.map((p, itenIndex: number) => {
              return <TreeItem key={p.userChapterId} item={p} />;
            })}
          </div>
        ))}
      </>
    );
  };
  //#endregion

  //#region API區塊
  /** 取得章節樹狀表  */
  const getUserSustainReportTreeFlat = useMutation({
    mutationFn: (procedureId: string) =>
      SustainReportProduceService.getUserSustainReportTreeFlat(procedureId),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportUserFlatTreeNode[] = res.data;
        // 預設
        setTreeFlow(data);
        // 包含根目錄
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 取得章節下資料 */
  const getUserSustainReportData = useMutation({
    mutationFn: (model: SustainReportUserDataRequestModel) =>
      SustainReportProduceService.getUserSustainReportData(model),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        const data: SustainReportManuscriptUserDataModel = res.data;
        console.log(data);
        if (data) {
          setInfoData(data);
        }
        console.log(data);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 取得報告書總覽 */
  const getUserSustainReportPreview = useMutation({
    mutationFn: (id: string) =>
      SustainReportProduceService.getUserSustainReportPreview(id),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        let resData: SustainReportUserPreviewModel[] = res.data;
        // 濾掉第一筆根目錄
        resData = resData.slice(1, resData.length);
        setOverviewData([...resData]);
      } else {
        console.log(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /** 取得公司資訊  */
  const getCompanyInfo = useMutation({
    mutationFn: (procedureId: string) =>
      DeclarationService.getCompanyInfo(procedureId),
    onSuccess: (res) => {
      if (res.success && res.code === ResponseCode.SuccessOK) {
        setCompanyData(res.data);
      } else {
        console.log(res);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
  //#endregion

  //#region 方法區塊
  /** 點擊樹狀流程 */
  const clickTreeItemFlow = (item: SustainReportUserFlatTreeNode) => {
    setIsOverView(false);
    clickTreeItem(item);
  };

  /** 點擊樹狀 */
  const clickTreeItem = (item: SustainReportUserFlatTreeNode) => {
    setSelTreeItem(item);
    // 這邊是範本(尚未填寫資料並不一定有userChapterDataId)
    queryParams.set("chapterId", item.userChapterId);
    setSearchParams(queryParams);
    getUserSustainReportData.mutate({
      userChapterId: item.userChapterId,
      userChapterDataId: item.userChapterDataId,
    });
  };

  /** 取得樹狀下的遞迴item */
  const getRecursionItem = (
    node: SustainReportUserFlatTreeNode,
    id: string
  ): SustainReportUserFlatTreeNode | null => {
    // 遞迴歷遍子節點
    if (node.userChapterId === id) {
      return node;
    }

    for (let child of node.children) {
      const found = getRecursionItem(child, id);
      if (found) {
        return found;
      }
    }

    return null;
  };

  /** 取得樹狀下的遞迴item */
  const getRecursionFillInfo = (
    datas: SustainReportUserFlatTreeNode[]
  ): boolean => {
    let filled = true;

    const nextRecursionItemFilled = (
      childs: SustainReportUserFlatTreeNode[]
    ) => {
      childs.forEach((p) => {
        if (filled) {
          // 第一層樹跳過不判斷
          if (p.level > 0) {
            filled = p.isFinished;
          }
          if (filled && p.children.length > 0) {
            nextRecursionItemFilled(p.children);
          }
        }
      });
    };

    nextRecursionItemFilled(datas);
    return filled;
  };

  /** 預設樹狀相關流程 */
  const setTreeFlow = (datas: SustainReportUserFlatTreeNode[]) => {
    // 撇除根目錄
    setTreeData(CommonService.deepClone(datas));
    setFillAll(getRecursionFillInfo(datas));

    if (datas.length > 0) {
      setIsOverView(false);
      const chapterId = queryParams.get("chapterId");
      if (chapterId) {
        let defaultItem: SustainReportUserFlatTreeNode | null;
        // 遞迴找
        datas.forEach((p, index: number) => {
          if (!defaultItem) {
            defaultItem = getRecursionItem(p, chapterId);
          }
          // 有存在地端
          if (index === datas.length - 1 && defaultItem) {
            clickTreeItem(defaultItem);
          }
        });
      } else {
        // 帶第0筆 (第一層不可預設)
        let pass = false;
        datas.forEach((p) => {
          // 避免大類沒有第一筆資料
          if (!pass) {
            if (p.children.length > 0) {
              pass = true;
              clickTreeItem(p.children[0]);
            }
          }
        });
      }
    }
  };

  /** 點選總覽 */
  const clickOverview = () => {
    setIsOverView(true);
    // 移除queryParam
    queryParams.delete("chapterId");
    setSearchParams(queryParams);
    if (selTreeItem) {
      setSelTreeItem({
        ...selTreeItem,
        userChapterId: "all",
      });
    }

    getUserSustainReportPreview.mutate(params.id!);
  };

  /** 改變表格數值 */
  const changeCellVal = (
    tableId: string,
    rowIndex: number,
    colIndex: number,
    val: string
  ) => {
    if (infoData.tableList) {
      let newTableList = infoData.tableList?.map((table) => {
        if (table.tableId === tableId) {
          let cell = table.cells.find(
            (p) => p.rowIndex === rowIndex && p.columnIndex === colIndex
          );
          if (cell) {
            cell.cellValue = val;
          }
        }
        return table || [];
      });

      infoData.tableList = newTableList;

      setInfoData({
        ...infoData,
      });
    }
  };

  // 搜尋區塊隱藏
  const handleHideTree = () => {
    setHideTree((prev) => (prev = !prev));
  };

  //#endregion

  return (
    <div className={styles["admin-report-generation-box"]}>
      {(getUserSustainReportTreeFlat.isPending ||
        getUserSustainReportData.isPending ||
        getUserSustainReportPreview.isPending ||
        getCompanyInfo.isPending) && <VisuallLoading />}
      <div className="title-box">
        <BackTitle
          title={`${compaynData}`}
          url={"/admin/declaration/report-generation"}
        />
      </div>
      <div className="result-box">
        <div className={`left-box ` + `${hideTree ? "hide" : ""} `}>
          <div style={{ display: hideTree ? "none" : "block", height: "100%" }}>
            <div className={`toggle-box`}>
              <div></div>
              <img
                className="toggle-img"
                alt=""
                src="/assets/images/buttonIcon/toggle-left-icon.svg"
                onClick={handleHideTree}
              />
            </div>
            <div className={`tree-box scroll `}>
              {/* 報告書總覽 */}
              <div
                onClick={() => {
                  clickOverview();
                }}
                className={
                  `overview-title-item ` + `${isOverView ? "active" : ""}`
                }
              >
                <img
                  className=""
                  alt="overview"
                  src={
                    `/assets/images/declaration/application/` +
                    `${
                      isOverView
                        ? "white-overview-icon.svg"
                        : "black-overview-icon.svg"
                    }`
                  }
                />
                <div>報告書總覽</div>
              </div>
              <TreeComponent data={treeData} />
            </div>
          </div>

          {/* 隱藏區塊的圖案 */}
          {hideTree && (
            <div className="img-tool">
              <img
                className="toggle-img"
                alt=""
                src="/assets/images/buttonIcon/toggle-right-icon.svg"
                onClick={handleHideTree}
              />
            </div>
          )}
        </div>
        <div className="center-line"></div>
        {/* 總覽 */}
        {isOverView && (
          <div className={`right-box ` + `${hideTree ? "w-100" : ""} `}>
            <div className="overview-box">
              <div className="title-box">
                <div className="title">報告書總覽</div>
                <div className="tool-box">
                  <div className="tool-item">
                    <LinkIconButton
                      imgName="print-icon.svg"
                      text="列印網頁"
                      onClick={() => {
                        PrintService.openNewWindow(
                          PrintElementType.GenerateReportBox,
                          true
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                id="generate-report-box"
                className="overview-info-box scroll"
              >
                {overviewData.map((p) => {
                  return (
                    <div className="overview-item">
                      <div className="overview-title">
                        {p.chapterString} {p.title}
                      </div>
                      <div className="overview-content">{p.content}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {/* 非總覽 */}
        {!isOverView && (
          <div className={`right-box ` + `${hideTree ? "w-100" : ""} `}>
            <div className="non-overview-box">
              <div className="title-box">
                <div className="title">
                  <div>
                    {selTreeItem?.chapterString} {infoData.title}
                  </div>
                </div>
              </div>

              {infoData.griIndex || infoData.referenceExample ? (
                <div className="modal-tool-box">
                  {infoData.griIndex ? (
                    <div className="modal-item">
                      <button
                        className="secondary"
                        onClick={() => {
                          setPopModal({
                            ...popModal,
                            title: "GRI指標",
                            show: true,
                          });
                          setPopContent(infoData.griIndex);
                        }}
                      >
                        GRI指標
                      </button>
                    </div>
                  ) : null}
                  {infoData.referenceExample ? (
                    <div className="modal-item">
                      <button
                        className="secondary"
                        onClick={() => {
                          setPopModal({
                            ...popModal,
                            title: "參考範例",
                            show: true,
                          });
                          setPopContent(infoData.referenceExample);
                        }}
                      >
                        參考範例
                      </button>
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className="manuscript-box scroll">
                <div className="manuscript-info mb-20-p mt-20-p">
                  <div className="title">底稿撰寫</div>
                  <div className="content">
                    1.　底稿{"{XXX}"}
                    中，將自動帶入本年度申報之企業ESG資訊，公司可自行調整。底稿[XXX]中，請公司自行填入相關資訊。若有表格不敷使用，請於匯出報告書後自行調整。
                  </div>
                </div>
                {infoData.parameterList &&
                  infoData.parameterList?.length > 0 && (
                    <div className="ref-info mb-20-p">
                      <div>2.「企業ESG資訊」帶入內容：</div>
                      <div className="content">
                        {infoData.parameterList?.map((p, index) => {
                          return (
                            <div key={index}>
                              {index + 1}.{p.parameterName}：{p.value}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                <div className="mb-20-p">
                  <textarea
                    className="custom-textarea"
                    rows={5}
                    disabled={true}
                    value={infoData.content || ""}
                    onChange={($event) => {
                      setInfoData({
                        ...infoData,
                        content: $event.target.value,
                      });
                    }}
                  ></textarea>
                </div>
                <div className="dynamic-table-box mb-20-p">
                  {infoData.tableList?.map((table) => {
                    return (
                      <div key={table.tableId}>
                        <div>{table.tableName}</div>
                        <table
                          aria-label="查詢結果table"
                          className="table-container mb-20-p"
                        >
                          <tbody>
                            {Array.from({ length: table.rowSize }).map(
                              (row, rowIndex: number) => {
                                return (
                                  <tr key={rowIndex}>
                                    {Array.from({
                                      length: table.columnSize,
                                    }).map((col, colIndex: number) => {
                                      return (
                                        <td key={`${rowIndex}_${colIndex}`}>
                                          <NormalInput
                                            isFloatTitle={false}
                                            placeholder=""
                                            disabled={true}
                                            defaultValue={
                                              table.cells.find(
                                                (cell) =>
                                                  cell.rowIndex === rowIndex &&
                                                  cell.columnIndex === colIndex
                                              )?.cellValue || ""
                                            }
                                            onChange={(e) => {
                                              changeCellVal(
                                                table.tableId,
                                                rowIndex,
                                                colIndex,
                                                e
                                              );
                                            }}
                                          />
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              }
                            )}
                            <tr></tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* 參考範例 or GRI 準則說明  */}
      <ContentModal {...popModal}>
        <div
          className="scroll admin-report-generation-detail-desc-box"
          dangerouslySetInnerHTML={CommonService.sanitizedData(popContent)}
        ></div>
      </ContentModal>
    </div>
  );
};

export default AdminReportGenerationDetail;
