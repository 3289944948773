import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./Summary.module.scss";
import MultiSelect from "../../../../components/base/MultiSelect/MultiSelect";
import Dropdown from "../../../../components/base/Dropdown/Dropdown";
import LinkIconButton from "../../../../components/button/LinkIconButton/LinkIconButton";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import mopsEsgService from "services/inquiry/infoDisclosure/mopsEsgService";
import { MarketTypeEnum, OptionModel, RequestType } from "models/baseModel";
import {
  MopsControlValSectionDetail,
  MopsNewestIndustryAndSampleListModel,
  MopsSummaryCompanyDataResponseModel,
  MopsSummaryDataRequestModel,
} from "models/inquiry/infoDisclosure/mopsEsgModel";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import EmptySearchPage, {
  EmptySearchPageProps,
} from "components/otherModule/EmptySearchPage/EmptySearchPage";
import useSearchParamStore from "state/useSearchParamStore";
import { CSVLink } from "react-csv";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { MarketOp } from "models/optionModel";
import { ResponseCode } from "models/responseCodeModel";
import { SummaryResultModel } from "models/localSearchResultModel";
import { CtrType } from "models/admin/indicatorModel";
import ProblemReportModal, {
  ProblemReportModalProps,
} from "components/otherModule/ProblemReportModal/ProblemReportModal";
import { PrintService } from "services/common/printService";
import { submitGAEvent } from "services/common/GA";
import { useTranslation } from "react-i18next";
import IconButton from "components/button/IconButton/IconButton";
import { CommonService } from "services/common/commonService";
import { MopsCompanyDataControlDetailModel } from "models/samples/companySampleDataModel/companySampleDataModel";
import { PrintElementType } from "models/common/printElementModel";
import { SampleControlCode } from "models/samples/sampleControlCode";

//#region SummaryBlockComponent
interface SummarySectionProps {
  data: MopsSummaryCompanyDataResponseModel[];
  openAll: boolean;
  getControlValue: (
    control: MopsCompanyDataControlDetailModel
  ) => string | JSX.Element;
}

const SummarySectionComponent: FC<SummarySectionProps> = (props) => {
  // 實際section 畫面渲染資訊
  const [detailInfo, setDetailInfo] = useState<
    MopsSummaryCompanyDataResponseModel[]
  >(props.data);
  const { t } = useTranslation();
  useEffect(() => {
    if (props.data) {
      initData();
    }
  }, [props.data, props.openAll]);

  const initData = () => {
    const newData = [...props.data];
    newData.forEach((p) => {
      p.hide = props.openAll;
    });
    setDetailInfo([...newData]);
  };

  // 更新隱藏
  const hideDetail = (companyCode: string | null) => {
    const newItem: MopsSummaryCompanyDataResponseModel[] = detailInfo.map(
      (item) => {
        if (item.companyCode === companyCode) {
          item.hide = !item.hide;
        }
        return item;
      }
    );
    setDetailInfo([...newItem]);
  };

  // 取得sectionTitle
  const getSectionTitle = (section: MopsControlValSectionDetail): string => {
    let title = "";
    if (!section.isBlankSection) {
      title = section.name || "";
    } else {
      if (section.controls && section.controls.length > 0) {
        title = section.controls[0].title || "";
      }
    }
    return title;
  };

  return (
    <div id="summary-form-box" className="form-box">
      {/* block 樣式 */}
      <div className="section-box">
        {detailInfo.map((company) => {
          return (
            <div key={company.companyCode} className="block-box">
              <div className="block-name">
                {/* 延展 */}
                <img
                  alt="extend"
                  src="/assets/images/declaration/application/extend-up-icon.svg"
                  className={`extend-img ` + `${company.hide ? "hide" : ""}`}
                  onClick={() => {
                    hideDetail(company.companyCode);
                  }}
                />
                <div>
                  {company.companyCode}
                  {company.companyName}
                </div>
              </div>
              {!company.hide && (
                <div className="question-box">
                  {company.sectionDatas?.map((section, sectionIndex) => {
                    return (
                      <div className="question-item" key={sectionIndex}>
                        <table
                          aria-label={t("SEARCH_RESULT")}
                          className="table-container inquiry-table"
                        >
                          <thead>
                            <tr>
                              {/* 名稱不存在合併row 取第一筆 */}
                              <th colSpan={section.controls?.length}>
                                <div>{getSectionTitle(section)}</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="sub-title">
                              {!section.isBlankSection &&
                                section.controls?.map(
                                  (control, controlIndex) => {
                                    return (
                                      <td key={controlIndex}>
                                        {control.title}
                                      </td>
                                    );
                                  }
                                )}
                            </tr>
                            <tr>
                              {section.controls?.map(
                                (control, controlIndex) => {
                                  return (
                                    <td
                                      className={`${
                                        control.textAlign
                                          ? control.textAlign
                                          : ""
                                      }`}
                                      key={controlIndex}
                                    >
                                      {props.getControlValue(control)}
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
//#endregion

//#region SummaryTableComponent
interface SummaryTableProps {
  data: MopsSummaryCompanyDataResponseModel[];
  getControlValue: (
    control: MopsCompanyDataControlDetailModel
  ) => string | JSX.Element;
}
const SummaryTableComponent: FC<SummaryTableProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <div id="summary-table-box" className="table-box">
      <table
        aria-label={t("SEARCH_RESULT")}
        className="table-container sticky-table inquiry-table"
      >
        {/* table 樣式 */}
        <thead>
          <tr>
            <th scope="rowgroup" rowSpan={2} className="fix-th">
              {t("COMPANY_CODE")}
              <br />
              {t("COMPANY_NAME")}
            </th>
            {/* 取第0筆當作題目渲染就可以 */}
            {data.length > 0 &&
              data[0].sectionDatas?.map((section, sectionIndex) => {
                return !section.isBlankSection ? (
                  <th
                    key={sectionIndex}
                    colSpan={
                      section.controls?.length === 0
                        ? 1
                        : section.controls?.length
                    }
                  >
                    <div>{section.name}</div>
                  </th>
                ) : (
                  // control 內只有一筆 (textarea 會特寬)
                  <th
                    key={sectionIndex}
                    rowSpan={2}
                    className={
                      section.controls &&
                      section.controls.length > 0 &&
                      section.controls[0].code ===
                        SampleControlCode.GreenhouseGasEmissionsDisclosureTextBlock
                        ? "textarea-th"
                        : ""
                    }
                  >
                    <div>
                      {section.controls &&
                        section.controls.length > 0 &&
                        section.controls[0]?.title}
                    </div>
                  </th>
                );
              })}
          </tr>
          <tr>
            {data.length > 0 &&
              data[0].sectionDatas?.map((section, sectionIndex) => {
                return section.controls?.length === 0 ? (
                  <th key={`${sectionIndex}_empty`}></th>
                ) : (
                  !section.isBlankSection &&
                    section.controls?.map((control, controlIndex) => {
                      return (
                        <th
                          key={`${sectionIndex}_${controlIndex}`}
                          className={
                            control.code ===
                              SampleControlCode.GreenhouseGasEmissionsDisclosureTextBlock &&
                            control.value
                              ? "textarea-th"
                              : ""
                          }
                        >
                          {control.title}
                        </th>
                      );
                    })
                );
              })}
          </tr>
        </thead>
        <tbody>
          {data.map((p) => {
            return (
              <tr key={p.companyCode}>
                <td>
                  {p.companyCode}
                  <br />
                  {p.companyName}
                </td>
                {p.sectionDatas?.map((section, sectionIndex) => {
                  return section.controls?.length === 0 ? (
                    <td key={`${sectionIndex}_empty`}></td>
                  ) : (
                    section.controls?.map((control, controlIndex) => {
                      return (
                        <td
                          key={`${sectionIndex}_${controlIndex}`}
                          className={`${
                            control.textAlign ? control.textAlign : ""
                          }`}
                        >
                          {props.getControlValue(control)}
                        </td>
                      );
                    })
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
//#endregion

//#region FilterComponent
interface FilterProps {
  searchChange: (param: MopsSummaryDataRequestModel) => void;
  /** 預設param */
  defaultParam: MopsSummaryDataRequestModel;
  yearOption: OptionModel[];
  marketOption: OptionModel[];
}
const FilterComponent: FC<FilterProps> = (props) => {
  const { defaultParam, yearOption, marketOption } = props;
  const [param, setParam] = useState<MopsSummaryDataRequestModel>(defaultParam);
  const [industryOption, setIndustryOption] = useState<OptionModel[]>([]);
  const [sampleOption, setSampleOption] = useState<OptionModel[]>([]);
  const sampleData = useRef<MopsNewestIndustryAndSampleListModel>();
  // 按鈕卡控
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const { t } = useTranslation();
  useEffect(() => {
    if (defaultParam.marketType !== null || defaultParam.year !== null) {
      setParam({
        ...param,
        ...defaultParam,
      });
    }
    // 取得市場別
    if (defaultParam.marketType !== null) {
      getIndustryAndSample.mutate(defaultParam.marketType);
    }
  }, [defaultParam]);

  useEffect(() => {
    // 不存在則預設空
    if (yearOption && yearOption.length > 0) {
      if (defaultParam.year !== null) {
        const exist = yearOption.some(
          (p) => p.id === defaultParam.year?.toString()
        );
        if (!exist) {
          setParam({
            ...param,
            year: null,
          });
        }
      }
    }
  }, [yearOption]);

  // 取得產業別和Sample
  const getIndustryAndSample = useMutation({
    mutationFn: (type: MarketTypeEnum) =>
      mopsEsgService.getIndustryAndSample(type),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        sampleData.current = res.data;
        // 產業別設置
        setIndustryOptionModel();

        // 議題設置
        setSampleOptionModel();
      } else {
        console.log(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // 設置產業別option
  const setIndustryOptionModel = () => {
    const industryEmpty: OptionModel[] = [];
    sampleData.current?.industryNameList?.forEach((p) => {
      if (p.industryName) {
        industryEmpty.push({
          id: p.industryName,
          text: p.industryName,
        });
      }
    });
    setIndustryOption([...industryEmpty]);

    // 如果產業別全空預設全選
    if (defaultParam.industryNameList?.length === 0) {
      setParam({
        ...param,
        industryNameList: industryEmpty.map((p) => p.id),
      });
    }
  };

  // 設置議題 option
  const setSampleOptionModel = () => {
    const sampleEmpty: OptionModel[] = [];
    sampleData.current?.sampleNameList?.forEach((p) => {
      if (p.sampleName) {
        sampleEmpty.push({
          id: p.sampleName,
          text: p.sampleName,
        });
      }
    });
    setSampleOption([...sampleEmpty]);
  };

  // 更改產業別
  const changeIndustry = (vals: string[]) => {
    setParam({
      ...param,
      industryNameList: vals,
    });
  };

  const handleChange = () => {
    props.searchChange(param);
  };

  //#region diabled button
  useEffect(() => {
    if (param) {
      disabledCondition();
    }
  }, [param]);
  const disabledCondition = () => {
    if (param.year !== null && param.marketType !== null && param.sampleId) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };
  //#endregion

  const changeValue = (type: string, value: string | null) => {
    switch (type) {
      case "year":
        if (value) {
          const year = Number.parseInt(value);
          setParam({
            ...param,
            year: year,
          });
        } else {
          setParam({
            ...param,
            year: null,
          });
        }
        break;
      case "market":
        if (value) {
          const market = Number.parseInt(value);
          setParam({
            ...param,
            marketType: market,
            industryNameList: [],
            sampleId: "",
            sampleName: "",
          });
          getIndustryAndSample.mutate(market);
        } else {
          setParam({
            ...param,
            marketType: null,
            industryNameList: [],
            sampleId: "",
            sampleName: "",
          });
        }
        break;
      case "sample":
        if (value) {
          setParam({
            ...param,
            sampleId: value,
            sampleName: value,
          });
        } else {
          setParam({
            ...param,
            sampleId: "",
            sampleName: "",
          });
        }
        break;
    }
  };
  return (
    <>
      <div className="search-item-box">
        <div className="mt-3">
          <Dropdown
            isFloatTitle={true}
            placeholder={`${t("MARKET_TYPE")}*`}
            options={marketOption}
            defaultId={param.marketType?.toString()}
            onChange={(e) => {
              changeValue("market", e);
            }}
          />
        </div>
        <div className="mt-3">
          <MultiSelect
            isFloatTitle={true}
            placeholder={`${t("INDUSTRY_TYPE")}*`}
            showAllText={t("ALL_INDUSTRY")}
            options={industryOption}
            defaultIds={param.industryNameList!}
            onChange={(e) => {
              changeIndustry(e);
            }}
          />
        </div>
        <div className="mt-3">
          <Dropdown
            isFloatTitle={true}
            placeholder={`${t("ISSUE")}*`}
            options={sampleOption}
            defaultId={param.sampleId}
            onChange={(e) => {
              changeValue("sample", e);
            }}
          />
        </div>
        <div className="mt-3">
          <Dropdown
            isFloatTitle={true}
            placeholder={`${t("REPORTING_YEAR")}*`}
            options={yearOption}
            defaultId={param.year?.toString()}
            onChange={(e) => {
              changeValue("year", e);
            }}
          />
        </div>
        <div className="mt-3">
          <button
            className={`default ` + `${btnDisabled ? "disabled " : ""}`}
            onClick={handleChange}
          >
            {t("INQUIRY")}
          </button>
        </div>
      </div>
    </>
  );
};
//#endregion

//#region Summary
interface SummaryProps {}

const Summary: FC<SummaryProps> = () => {
  const [defaultParam, setDefaultParam] = useState<MopsSummaryDataRequestModel>(
    {
      marketType: null,
      industryYearId: null,
      industryNameList: [],
      sampleId: null,
      year: null,
    }
  );
  const { t } = useTranslation();
  const [empty, setEmpty] = useState<EmptySearchPageProps>();
  const [hide, setHide] = useState(false);
  // tabValue
  const [radioValue, setRadioValue] = useState("table");
  const param = useRef<MopsSummaryDataRequestModel>();
  const [summaryData, setSummaryData] = useState<
    MopsSummaryCompanyDataResponseModel[]
  >([]);
  const [table, setTable] = useState<any>({
    data: [] as any[],
  });
  // 開分頁暫存的查詢
  const [newPageParam, setNewPageParam] =
    useState<MopsSummaryDataRequestModel>();
  // 儲存filter參數
  const { summaryParam, setSummaryParam, clearSummaryParam } =
    useSearchParamStore();
  // 取得搜尋參數
  const [queryParams, setSearchParams] = useSearchParams();
  const [yearOption, setYearOption] = useState<OptionModel[]>([]);
  const location = useLocation();
  // 展開 縮小
  const [openAll, setOpenAll] = useState<boolean>(false);

  const handleHide = () => {
    setHide((prev) => (prev = !prev));
  };

  const handleToggleAll = () => {
    setOpenAll(!openAll);
  };

  const switchView = [
    {
      name: t("FORM"),
      value: "table",
      img: "table-icon",
    },
    {
      name: t("BLOCK"),
      value: "section",
      img: "section-icon",
    },
  ];
  useEffect(() => {
    // 初始化完翻譯再set
    setEmpty({
      word: t("PLEASE_ENTER_SEARCH_CONDITION"),
    });
  }, [t]);

  //#region 取得報告年度與市場別清單
  /** 2023(含之後) */
  const getSummaryData = useMutation({
    mutationFn: (param: MopsSummaryDataRequestModel) =>
      mopsEsgService.getSummaryData(param),
    onSuccess: (res) => {
      clearEmpty();
      if (res.code === ResponseCode.SuccessOK && res.success) {
        if (res.data) {
          const result: MopsSummaryCompanyDataResponseModel[] = res.data;
          if (result.length > 0) {
            // 初始總覽資料
            initSummaryData(result);
            setSummaryData(result);
            setTableFlow(result);
            // 滾輪最上 最左
            const block = document.getElementById("result-box");
            if (block) {
              block.scrollTo({
                top: 0,
                left: 0,
              });
            }
          } else {
            setEmpty({
              ...empty,
              word: t("NO_INFORMATION_FOUND"),
              url: null,
            });
          }
        } else {
          setEmpty({
            ...empty,
            word: `${t("OLD_DATA_URL_MESSAGE")}\n${res.message}`,
            url: res.message,
          });
        }
        //- ga event
        submitGAEvent("ESG_InfoDisclosure_Inquiry");
      } else {
        setEmpty({
          ...empty,
          word: res.message,
          url: null,
        });
      }
    },
    onError: (err) => {
      console.log(err);
      clearEmpty();
    },
  });

  /** 2022(含之前) */
  const getSummaryDataOld = useMutation({
    mutationFn: (param: MopsSummaryDataRequestModel) =>
      mopsEsgService.getSummaryDataOld(param),
    onSuccess: (res) => {
      clearEmpty();
      if (res.code === ResponseCode.SuccessOK && res.success) {
        if (res.data) {
          const result: MopsSummaryCompanyDataResponseModel[] = res.data;
          if (result.length > 0) {
            // 初始總覽資料
            initSummaryData(result);
            setSummaryData(result);
            setTableFlow(result);
            // 滾輪最上 最左
            const block = document.getElementById("result-box");
            if (block) {
              block.scrollTo({
                top: 0,
                left: 0,
              });
            }
          } else {
            setEmpty({
              ...empty,
              word: t("NO_INFORMATION_FOUND"),
              url: null,
            });
          }
        } else {
          setEmpty({
            ...empty,
            word: `${t("OLD_DATA_URL_MESSAGE")}\n${res.message}`,
            url: res.message,
          });
        }
        //- ga event
        submitGAEvent("ESG_InfoDisclosure_Inquiry");
      } else {
        setEmpty({
          ...empty,
          word: res.message,
          url: null,
        });
      }
    },
    onError: (err) => {
      console.log(err);
      clearEmpty();
    },
  });

  /** 初始總覽資料 */
  const initSummaryData = (data: MopsSummaryCompanyDataResponseModel[]) => {
    data.forEach((p) => {
      p.sectionDatas?.forEach((sec) => {
        sec.controls?.forEach((con) => {
          //#region 文字 align 設定

          let align = "text-center";
          let text = getControlValue(con);
          if (text && text !== "-" && text !== "N/A") {
            switch (con.ctrType) {
              case CtrType.Textarea:
                align = "text-left";
                break;
              case CtrType.Number:
                align = "text-right";
                break;
              default:
                align = "text-center";
                break;
            }
          }

          con.textAlign = align;
          //#endregion

          //#region  特殊設定: 將"請說明"欄位控制項設定為 空section，防止control 顯示請說明
          if (sec.controls?.length === 1 && con.title === "請說明") {
            // 這邊歸類為空section
            sec.isBlankSection = true;
            // section 的名稱放置 第一個control
            con.title = sec.name;
          }
          //#endregion
        });
      });
    });
  };

  const getControlValue = (control: MopsCompanyDataControlDetailModel) => {
    if (control.value) {
      // 利害人關係連結直接顯示連結
      if (
        control.code ===
        SampleControlCode.StakeholderOrCorporateGovernanceAreaLink
      ) {
        return LinkComponent(control.value, control.value);
      }

      if (CommonService.isURL(control.value)) {
        let text = t("DETAILS");
        // 利害人關係連結直接顯示連結
        if (
          control.code ===
          SampleControlCode.StakeholderOrCorporateGovernanceAreaLink
        ) {
          text = control.value;
        }

        return LinkComponent(control.value, text);
      } else {
        return control.value;
      }
    } else {
      return "N/A";
    }
  };

  /** link元件 */
  const LinkComponent = (value: string, text: string) => {
    return (
      <div
        className="detail-link"
        onClick={(event) => {
          event.preventDefault();
          if (value && value !== "N/A") {
            const realUrl = CommonService.getProtocolUrl(value || "");
            window.open(realUrl, "_blank", "noopener noreferrer");
          }
        }}
      >
        {text}
      </div>
    );
  };

  // 清除空白
  const clearEmpty = () => {
    setSummaryData([]);
    setTableFlow([]);

    setTable({
      ...table,
      data: [],
    });
  };

  //#endregion

  //#region table相關
  const setTableFlow = (items: MopsSummaryCompanyDataResponseModel[]) => {
    const firstRow: string[] = [
      `${t("REPORTING_YEAR")}:${param.current?.year}`,
      `${t("ISSUE")}:${param.current?.sampleName}`,
    ];
    const tmpHeader: string[] = [t("COMPANY_CODE"), t("COMPANY_NAME")];
    const tmpData: any[] = [];
    items?.forEach((com, comIndex: number) => {
      // rowData設置
      let rowData = [`=""${com.companyCode}""`, com.companyName];
      com.sectionDatas?.forEach((section) => {
        section.controls?.forEach((control) => {
          // header只顯示一筆
          if (comIndex === 0) {
            if (section.name) {
              tmpHeader.push(`${section.name}-${control.title}`);
            } else {
              tmpHeader.push(`${control.title}`);
            }
          }
          // 橫向組合資料
          rowData.push(CommonService.sanitizeCSVField(control.value) ?? "");
        });
      });
      tmpData.push(rowData);
    });
    setTable({
      ...table,
      data: [firstRow, tmpHeader, ...tmpData],
    });
  };
  //#endregion

  //#region 處理新視窗
  const handleNewWindow = (print: boolean) => {
    setSummaryParam({
      ...param.current!,
      radioValue: radioValue,
    });
    if (radioValue === "table") {
      PrintService.openNewWindow(PrintElementType.SummaryTableBox, print);
    } else {
      PrintService.openNewWindow(PrintElementType.SummaryFormBox, print);
    }
  };
  //#endregion

  //#region  外部帶入參數
  useEffect(() => {
    const year = queryParams.get("year");
    const market = queryParams.get("market")
      ? Number.parseInt(queryParams.get("market")!)
      : null;
    const industry = queryParams.get("industry");
    const issue = queryParams.get("issue");
    // 沒產業別就代表全選
    if (market !== null && year && issue) {
      let newYear = Number.parseInt(year);
      const newModel = {
        ...defaultParam,
        marketType: market,
        year: newYear,
        sampleId: issue,
        sampleName: issue,
        industryNameList: industry ? [industry] : [],
      };
      setDefaultParam({
        ...defaultParam,
        ...newModel,
      });
      if(newModel.year >=2023){
        getSummaryData.mutate(newModel);
      }else{
        getSummaryDataOld.mutate(newModel);
      }
    }
  }, [queryParams]);
  //#endregion

  //#region 取得清單相關
  // 取得一次
  useEffect(() => {
    getYearList.mutate(RequestType.ESGDisclosure);
  }, []);
  // 取得報告年度與市場別清單
  const getYearList = useMutation({
    mutationFn: (type: RequestType) => mopsEsgService.getYearList(type),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        const result: number[] = res.data;
        setYearOptionModel(result);
      } else {
        console.log(res.message);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const setYearOptionModel = (result: number[]) => {
    let op: OptionModel[] = [];
    // 設置option
    result.forEach((p: number) => {
      const year = p.toString();
      op.push({
        id: year,
        text: year,
      });
    });
    setYearOption(op);
  };

  //#endregion

  //#region 問題回報modal
  const [problemModal, setProblemModal] = useState<ProblemReportModalProps>({
    show: false,
    handleClose: () => {
      setProblemModal({
        ...problemModal,
        show: false,
      });
    },
    size: "sm",
  });
  //#endregion

  return (
    <div className={`${styles["summary-box"]} common-summary `}>
      {(getSummaryData.isPending || getSummaryDataOld.isPending) && (
        <VisuallLoading />
      )}
      {/* 左區塊 */}
      <div className={"left-box " + (hide ? "hide" : "")}>
        <div style={{ display: hide ? "none" : "block" }}>
          <div className="toggle-box">
            <div className="main-title">{t("SEARCH_CONDITION")}</div>
            <img
              alt=""
              src="/assets/images/buttonIcon/toggle-left-icon.svg"
              onClick={handleHide}
            />
          </div>
          <FilterComponent
            yearOption={yearOption}
            marketOption={MarketOp.filter((p) =>
              [MarketTypeEnum.Listing, MarketTypeEnum.OTC].includes(p.enumKey!)
            )}
            defaultParam={defaultParam}
            searchChange={(e) => {
              param.current = {
                ...param.current,
                ...e,
              };
              if (e && e.year !== null&& e.year !== undefined) {
                if (e.year >= 2023) {
                  getSummaryData.mutate(e);
                } else {
                  getSummaryDataOld.mutate(e);
                }
              }
            }}
          />
        </div>
        {hide && (
          <div className="img-tool">
            <img
              alt=""
              src="/assets/images/buttonIcon/toggle-right-icon.svg"
              onClick={handleHide}
            />
            {/* <img
        alt=""
        src="/assets/images/buttonIcon/filter-icon.svg"
        onClick={handleHide}
      /> */}
          </div>
        )}
      </div>
      {/* 右區塊 */}
      <div className={`right-box ` + `${hide ? "" : "has-filter "}`}>
        {/* 上區塊 */}
        <div className="tool-box">
          <div className="result-title-box">
            <div className="main-title">{t("SEARCH_RESULT")}</div>
            <div className="sub-title mr-1-m ">{`(${summaryData.length})`}</div>
            {summaryData &&
              summaryData?.length > 0 &&
              radioValue === "section" && (
                <div>
                  <IconButton
                    imgName={
                      openAll === true
                        ? "double-down-icon.svg"
                        : "double-up-icon.svg"
                    }
                    text={
                      openAll === false ? t("ALL_CONDENSED") : t("EXPAND_ALL")
                    }
                    className="secondary"
                    onClick={handleToggleAll}
                  />
                </div>
              )}
          </div>

          <div className="tool-item-box">
            {summaryData.length > 0 && (
              <>
                {radioValue === "section" && (
                  <LinkIconButton
                    imgName="print-icon.svg"
                    text={t("PRINT_WEB_PAGE")}
                    onClick={() => {
                      handleNewWindow(true);
                    }}
                  />
                )}
                <LinkIconButton
                  imgName="new-window-icon.svg"
                  text={t("OPEN_NEW_WINDOW")}
                  onClick={() => {
                    handleNewWindow(false);
                  }}
                />
              </>
            )}
            <LinkIconButton
              imgName="question-icon.svg"
              text={t("PROBLEM_REPORT")}
              onClick={() => {
                setProblemModal({
                  ...problemModal,
                  show: true,
                });
              }}
            />
            {table.data.length > 0 && (
              <CSVLink
                className="d-flex align-items-center"
                data={table.data}
                filename={`${t("SUMMARY_DATA_QUERY")}.csv`}
                headers={table.header}
              >
                <LinkIconButton
                  imgName="download-icon.svg"
                  text={t("SAVE_AS_CSV")}
                  onClick={() => {}}
                ></LinkIconButton>
              </CSVLink>
            )}
            <ButtonGroup className="mb-2 switch-box">
              {switchView.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  className="d-flex align-items-center"
                  variant="secondary"
                  name="radio"
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={(e) => {
                    setRadioValue(e.currentTarget.value);
                  }}
                >
                  <img
                    alt=""
                    className="icon-item"
                    src={`/assets/images/buttonIcon/${
                      radioValue === radio.value ? "white" : "blue"
                    }-${radio.img}.svg`}
                  />
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <ProblemReportModal {...problemModal} />
          </div>
        </div>
        {/* 下區塊 */}
        <div id="result-box" className="result-box scroll">
          {summaryData.length > 0 ? (
            <>
              <ScrollToTopButton
                targetClassName={"result-box"}
                bottom={90}
                right={35}
              />
              <div className="mt-4 mb-4 default-word">
                *{t("INDIVIDUAL_MESSAGE1")}
              </div>
              {radioValue === "table" ? (
                <SummaryTableComponent
                  getControlValue={getControlValue}
                  data={summaryData}
                />
              ) : (
                <SummarySectionComponent
                  getControlValue={getControlValue}
                  openAll={openAll}
                  data={summaryData}
                />
              )}
            </>
          ) : (
            <EmptySearchPage {...empty} />
          )}
        </div>
      </div>
    </div>
  );
};

//#endregion

export default Summary;
