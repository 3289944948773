//* ---------------------------- React start ---------------------------- *//
import { FC, useEffect, useState, useRef } from "react";
//* ---------------------------- React end  ---------------------------- *//
//* ---------------------------- third-party start ---------------------------- *//
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
//* ---------------------------- third-party end  ---------------------------- *//
//* ---------------------------- local start ---------------------------- *//
import styles from "./AdminReportGeneration.module.scss";
import Dropdown from "components/base/Dropdown/Dropdown";
import { OptionModel, BaseResponse, RequestType } from "models/baseModel";
import ScrollToTopButton from "components/button/ScrollToTopButton/ScrollToTopButton";
import DeclarationService from "services/admin/declarationService";
import SearchInput from "components/base/SearchInput/SearchInput";
import {
  ReportProduceSearchModel,
  AllCompanyReportProduceShowModel,
} from "models/admin/declarationModel";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { success2Alert, error2Alert, successAlert } from "utils/otherToast";
import ConfirmModal, {
  ConfirmModalProps,
} from "components/base/ConfirmModal/ConfirmModal";
import TablePagination from "components/base/TablePagination/TablePagination";
import { PaginationModel } from "models/baseModel";
import { CommonService } from "services/common/commonService";
import mopsEsgService from "services/inquiry/infoDisclosure/mopsEsgService";
import { AxiosResponse } from "axios";
import { ResponseCode } from "models/responseCodeModel";
import useUserInfoStore from "state/useUserInfoStore";
import { usePermissionContext } from "context/PermissionProvider";
import { normalDate } from "utils/dateTimeFormat";
import { ReportProduceStatus } from "models/application/sustainReportDeclare/sustainReportProduceModel";
//* ---------------------------- local end ---------------------------- *//

//- setReportProduceDataRequestModel props
const OperationHeaderComponent: FC<{
  reportProduceDataRequestModel: ReportProduceSearchModel;
  setReportProduceDataRequestModel: React.Dispatch<
    React.SetStateAction<ReportProduceSearchModel>
  >;
  declareYearOptions: OptionModel[];
  declareStatusEnumOptions: OptionModel[];
  getReportProduceListExcelFileStream: UseMutationResult<
    AxiosResponse<any, any>,
    Error,
    ReportProduceSearchModel,
    unknown
  >;
}> = ({
  reportProduceDataRequestModel,
  setReportProduceDataRequestModel,
  declareYearOptions,
  declareStatusEnumOptions,
  getReportProduceListExcelFileStream,
}) => {
  //* ---------------------------- state start ---------------------------- *//
  const { PermissionService } = usePermissionContext();
  const { detectEveryActionPermission } = PermissionService;
  //* ---------------------------- state end ---------------------------- *//

  return (
    <div className={`${styles["operation-header"]}`}>
      <div className={`left`}>
        <div className={`select-item`}>
          <Dropdown
            placeholder={"年度"}
            defaultId={
              reportProduceDataRequestModel.declareYear === undefined
                ? "undefined"
                : reportProduceDataRequestModel.declareYear?.toString()
            }
            options={declareYearOptions}
            isFloatTitle={false}
            onChange={(e) => {
              setReportProduceDataRequestModel({
                ...reportProduceDataRequestModel,
                declareYear:
                  declareYearOptions.find((o) => o.id === e)?.enumKey ?? undefined,
              });
            }}
          />
        </div>
        <div className={`select-item`}>
          <span>狀態</span>
          <Dropdown
            placeholder={"請選擇"}
            defaultId={
              reportProduceDataRequestModel.status === undefined
                ? "undefined"
                : reportProduceDataRequestModel.status?.toString()
            }
            options={declareStatusEnumOptions}
            isFloatTitle={false}
            onChange={(e) => {
              setReportProduceDataRequestModel({
                ...reportProduceDataRequestModel,
                status:
                  declareStatusEnumOptions.find((o) => o.id === e)?.enumKey ??
                  undefined,
              });
            }}
          />
        </div>
        <div className={`select-item`}>
          <SearchInput
            onClick={(e) => {
              setReportProduceDataRequestModel((prev) => ({
                ...prev,
                searchInfo: e,
              }));
            }}
            placeholder={"搜尋公司代號/公司名稱"}
          />
        </div>
      </div>
      <div className={`right`}>
        <div className={`select-item`}>
          <button
            className="secondary"
            onClick={() => {
              getReportProduceListExcelFileStream.mutate(
                reportProduceDataRequestModel
              );
            }}
          >
            匯出 Excel
          </button>
        </div>
      </div>
    </div>
  );
};

const TableComponent: FC<{
  reportProduceDataList: AllCompanyReportProduceShowModel[] | undefined;
  isLoading: boolean;
  declareStatusEnumOptions: OptionModel[];
  openDeclareDataCorrectionRequestPermission: UseMutationResult<
    BaseResponse<any>,
    Error,
    string,
    unknown
  >;
  setReportProduceDataRequestModel: React.Dispatch<
    React.SetStateAction<ReportProduceSearchModel>
  >;
  reportProduceDataRequestModel: ReportProduceSearchModel;
}> = ({
  reportProduceDataList,
  isLoading,
  declareStatusEnumOptions,
  openDeclareDataCorrectionRequestPermission,
  setReportProduceDataRequestModel,
  reportProduceDataRequestModel,
}) => {
  //* ---------------------------- state start ---------------------------- *//
  const [selectItemId, setSelectItemId] = useState<string>();
  const [modal, setModal] = useState<ConfirmModalProps>({
    show: false,
    handleClose: () => {},
    handleConfirm: () => {},
  });
  const navigate = useNavigate();
  //* ---------------------------- state end ---------------------------- *//

  //- setModal
  useEffect(() => {
    setModal((prev) => ({
      ...prev,
      loading: openDeclareDataCorrectionRequestPermission.isPending,
      handleClose: () => {
        setSelectItemId(undefined);
        setModal((prev) => ({
          ...prev,
          show: false,
        }));
      },
      handleConfirm: () => {
        // console.log("selectItemId", selectItemId);
        openDeclareDataCorrectionRequestPermission.mutate(selectItemId!, {
          onSuccess: (res) => {
            console.log("openDeclareDataCorrectionRequestPermission!!!!");
            if (res.code === 200 && res.success) {
              setSelectItemId(undefined);
              setModal((prev) => ({
                ...prev,
                show: false,
              }));
            }
          },
        });
      },
    }));
  }, [modal.show, selectItemId, openDeclareDataCorrectionRequestPermission]);

  //- handleSort
  const handleSort = (sortKey: string) => {
    setReportProduceDataRequestModel((prev) => ({
      ...prev,
      sortType:
        prev.sortKey === sortKey ? !prev.sortType : prev.sortType ?? true,
      sortKey: sortKey,
    }));
  };

  const navigateToDetailPage = (item: AllCompanyReportProduceShowModel) => {
    if (!item?.id) return;
    navigate(`${item.id}/detail`);
  };

  return (
    <div
      className={`${styles["transaction-table-box"]} transaction-table-scroll scroll`}
    >
      <ConfirmModal {...modal}>
        <div>確認是否開啟權限?</div>
      </ConfirmModal>
      {isLoading ? <VisuallLoading /> : null}

      <ScrollToTopButton
        targetClassName={"transaction-table-scroll"}
        bottom={90}
        right={35}
      />
      <table
        aria-label="table"
        className={`${styles["transaction-table"]} table-container sticky-table`}
      >
        <thead>
          <tr>
            <th scope="col">
              <div>
                <span>公司代號</span>
                <img
                  src="/assets/images/buttonIcon/sort-icon.svg"
                  alt="sort-icon.svg"
                  onClick={() => handleSort("CompanyCode")}
                />
              </div>
            </th>
            <th scope="col">公司名稱</th>
            <th scope="col">最後編輯日期</th>
            <th scope="col">狀態</th>
          </tr>
        </thead>
        <tbody>
          {reportProduceDataList?.length === 0 ? (
            <tr>
              <td colSpan={9}>查無資料</td>
            </tr>
          ) : (
            reportProduceDataList?.map((item, index) => (
              <tr key={index} onClick={() => navigateToDetailPage(item)}>
                <td>{item.companyCode}</td>
                <td>{item.companyName}</td>
                <td>{item.lastEditDate ? normalDate(item.lastEditDate) : "-"}</td>
                <td>{item.statusName}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

interface AdminReportGenerationProps {}

const AdminReportGeneration: FC<AdminReportGenerationProps> = () => {
  //* ---------------------------- state start ---------------------------- *//
  const location = useLocation();
  const [declareYearOptions, setDeclareYearOptions] = useState<OptionModel[]>(
    []
  );
  // const [declareYearOptions] = useState<OptionModel[]>(() => {
  //   const declareYearOptionsList = [];
  //   const today = new Date();
  //   for (let i = today.getFullYear(); i >= 2021; i--) {
  //     declareYearOptionsList.push({
  //       id: i.toString(),
  //       text: `${i.toString()} 年度`,
  //       enumKey: i,
  //     });
  //   }
  //   return [
  //     {
  //       id: "undefined",
  //       text: "全部",
  //       enumKey: undefined,
  //     },
  //     ...declareYearOptionsList,
  //   ];
  // });
  const [declareStatusEnumOptions] = useState<OptionModel[]>(() => {
    const enumValues = Object.values(ReportProduceStatus);

    const declareStatusEnumOptionsList:OptionModel[] = 
    [
      {
        id:ReportProduceStatus.InProgress.toString(),
        text: "產製中",
        enumKey: ReportProduceStatus.InProgress
      },
      {
        id:ReportProduceStatus.Completed.toString(),
        text: "產製完成",
        enumKey: ReportProduceStatus.Completed
      }
    ];

    return [
      {
        id: "undefined",
        text: "全部",
        enumKey: undefined,
      },
      ...declareStatusEnumOptionsList,
    ];
  });
  const [reportProduceDataRequestModel, setReportProduceDataRequestModel] =
    useState<ReportProduceSearchModel>(() => {
      if (location?.state?.reportProduceDataRequestModel) {
        const stateRequest = CommonService.deepClone(
          location.state.reportProduceDataRequestModel
        );
        return stateRequest;
      }
      return {
        page: 1,
        pageSize: 10,
        searchKey: null,
        sortKey: null,
        sortType: undefined,
        declareYear: undefined,
        status: undefined,
        searchInfo: undefined,
      };
    });
  const [reportProduceDataList, setReportProduceDataList] = useState<{
    count: number;
    list: AllCompanyReportProduceShowModel[];
  }>({
    count: 0,
    list: [],
  });
  const [tablePagination, setTablePagination] = useState<PaginationModel>({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  //* userInfo
  const { userInfo } = useUserInfoStore();
  //* permission
  const { permission } = userInfo;
  //* actionCodes
  const { actionCodes } = permission;
  //* ---------------------------- state end ---------------------------- *//
  //* ---------------------------- api start ---------------------------- *//
  //- getEsgDisclosureList
  const getReportProduceList = useMutation({
    mutationFn: (request: ReportProduceSearchModel) =>
      DeclarationService.getReportProduceList(request),
    onSuccess: (res) => {
      console.log("setReportProduceDataList", res);
      if (res.code === 200 && res.success) {
        setReportProduceDataList({
          count: res.count!,
          list: res.data,
        });
      } else {
        setReportProduceDataList({
          count: 0,
          list: [],
        });
      }
    },
    onError: (err) => {
      console.log("setReportProduceDataList", err);
    },
  });

  /** 取得報告書產製下攢清單 */
  const getReportProduceListExcelFileStream = useMutation({
    mutationFn: (request: ReportProduceSearchModel) =>
      DeclarationService.getReportProduceListExcelFileStream(request),
    onSuccess: (res) => {
      console.log("getReportProduceListExcelFileStream", res);
      if (res.status !== ResponseCode.ServerErrorInternal) {
        successAlert("下載成功");
        CommonService.downloadByStream(res);
      } else {
        error2Alert(res.data.message);
      }
    },
    onError: (err) => {
      console.log("getReportProduceListExcelFileStream", err);
      error2Alert("執行失敗");
    },
  });

  //- openDeclareDataCorrectionRequestPermission
  const openDeclareDataCorrectionRequestPermission = useMutation({
    mutationFn: (id: string) =>
      DeclarationService.openDeclareDataCorrectionRequestPermission(id),
    onSuccess: (res) => {
      console.log("openDeclareDataCorrectionRequestPermission", res);
      if (res.code === 200 && res.success) {
        success2Alert(res.message);
        getReportProduceList.mutate(reportProduceDataRequestModel);
      } else {
        error2Alert(res.message);
      }
    },
    onError: (err) => {
      console.log("openDeclareDataCorrectionRequestPermission", err);
      error2Alert("執行失敗");
    },
  });

  //- getYearList
  const { mutate: getYearListMutate, isPending: getYearListIsPending } =
    useMutation({
      mutationFn: () => DeclarationService.getYearList(),
      onSuccess: (res) => {
        console.log("getYearList", res);
        if (res.code === 200 && res.success) {
          //- 過濾res.data相同的年度
          const declareYearOptionsList = res.data
            .filter(
              (e: number, i: number, self: number[]) => self.indexOf(e) === i
            )
            .map((e: number) => ({
              id: e.toString(),
              text: `${e.toString()} 年度`,
              enumKey: e,
            })) as OptionModel[];
          setDeclareYearOptions([
            {
              id: "undefined",
              text: "全部",
              enumKey: undefined,
            },
            ...declareYearOptionsList,
          ]);
        }
      },
      onError: (err) => {
        console.log("getYearList", err);
        error2Alert("執行失敗");
      },
    });
  //* ---------------------------- api end ---------------------------- *//
  //* ---------------------------- function start ---------------------------- *//

  //* ---------------------------- function end ---------------------------- *//
  //* ---------------------------- hook start ---------------------------- *//
  // -useEffect reportProduceDataRequestModel
  useEffect(() => {
    getReportProduceList.mutate(reportProduceDataRequestModel);
    getYearListMutate();
  }, [reportProduceDataRequestModel]);

  // -useEffect reportProduceDataList
  useEffect(() => {
    setTablePagination((prev) => {
      if (reportProduceDataList?.list.length) {
        return {
          ...prev,
          page: reportProduceDataRequestModel.page ?? 1,
          pageSize: reportProduceDataRequestModel.pageSize ?? 10,
          total: reportProduceDataList.count,
        };
      } else {
        return {
          ...prev,
          page: 0,
          total: 0,
        };
      }
    });
  }, [reportProduceDataList, reportProduceDataRequestModel]);
  //* ---------------------------- hook end ---------------------------- *//

  return (
    <>
      <div className={`${styles["transaction-request-box"]}`}>
        {getReportProduceListExcelFileStream.isPending ||
        getYearListIsPending ? (
          <VisuallLoading />
        ) : null}
        {/* 搜尋框 */}
        <OperationHeaderComponent
          reportProduceDataRequestModel={reportProduceDataRequestModel}
          setReportProduceDataRequestModel={setReportProduceDataRequestModel}
          declareYearOptions={declareYearOptions}
          declareStatusEnumOptions={declareStatusEnumOptions}
          getReportProduceListExcelFileStream={
            getReportProduceListExcelFileStream
          }
        />
        {/* 資料集 */}
        <TableComponent
          reportProduceDataList={reportProduceDataList.list}
          isLoading={getReportProduceList.isPending}
          declareStatusEnumOptions={declareStatusEnumOptions}
          openDeclareDataCorrectionRequestPermission={
            openDeclareDataCorrectionRequestPermission
          }
          setReportProduceDataRequestModel={setReportProduceDataRequestModel}
          reportProduceDataRequestModel={reportProduceDataRequestModel}
        />
        <div className={`${styles["table-pagination-box"]}`}>
          <TablePagination
            total={tablePagination.total!}
            onChange={(e) => {
              setReportProduceDataRequestModel((prev) => ({
                ...prev,
                page: e.page,
                pageSize: e.pageSize,
              }));
            }}
            page={tablePagination.page}
            pageSize={tablePagination.pageSize}
          />
        </div>
      </div>
    </>
  );
};

export default AdminReportGeneration;
