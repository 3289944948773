import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from "react-router-dom";
import NotFound from "./components/common/NotFound/NotFound";
import { FC, useEffect } from "react";
import SignalContextProvider from "./context/SignalProvider";
import VisuallLoading from "./components/common/VisuallLoading/VisuallLoading";
import InquiryRouter from "./pages/Inquiry/InquiryRouter";
import ApplicationRouter from "./pages/Application/ApplicationRouter";
import AdminRouter from "./pages/Admin/AdminRouter";
import { PermissionProvider } from "./context/PermissionProvider";
import Redirection from "pages/Redirection/Redirection";
import DevelopmentPage from "pages/DevelopmentPage/DevelopmentPage";
import Entrance from "pages/Entrance/Entrance";
import useUserInfoStore from "state/useUserInfoStore";
import { CommonService } from "services/common/commonService";
import EmptyMessagePage from "pages/EmptyMessagePage/EmptyMessagePage";
import StorageName from "constants/storageName";

const AppLayout = () => {
  //以下四種情境捕捉不到
  /**
   * Event handlers
   * 非同步的程式碼 (例如 setTimeout 或 requestAnimationFrame callback)
   * Server side rendering
   * 在錯誤邊界裡丟出的錯誤（而不是在它底下的 children）
   */
  const { userInfo } = useUserInfoStore();

  const RenderComponent = () => {
    let component = <Outlet></Outlet>;
    if (!userInfo?.accessToken) {
      if (CommonService.isDevMode()) {
        component = <Navigate to="/entrance" />;
      } else {
        // 是否直接從入口近來，導向查詢頁
        if (CommonService.isTwseInquiry()) {
          component = <Navigate to="/inquiry" />;
        } else {
          component = <Navigate to="/empty" />;
        }
      }
    }
    return component;
  };

  return RenderComponent();
};
/** router 範例 */
const App: FC<any> = () => {
  const routers = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          path: "",
          element: <Navigate to="/inquiry" />,
        },
        {
          // 申報
          path: "apply/*",
          element: (
            <PermissionProvider>
              <ApplicationRouter />
            </PermissionProvider>
          ),
        },
        {
          // 後台
          path: "admin/*",
          element: (
            <PermissionProvider>
              <AdminRouter />
            </PermissionProvider>
          ),
        },
        {
          // 管理者元件測試
          path: "development/*",
          element: !CommonService.isDevMode() ? (
            <NotFound />
          ) : (
            <DevelopmentPage />
          ),
        },
      ],
    },
    {
      // 查詢 (不需登入)
      path: "inquiry/*",
      element: <InquiryRouter />,
    },
    {
      // 進入口 (尚未登入/開發者/pm使用，後續依狀況導向)
      // 非正式
      path: "entrance/*",
      element: !CommonService.isDevMode() ? <NotFound /> : <Entrance />,
    },
    {
      // 認證導向
      path: "esgcheck/*",
      element: <Redirection />,
    },
    {
      // 空白頁面只顯示訊息
      path: "empty",
      element: <EmptyMessagePage></EmptyMessagePage>,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  useEffect(() => {
    if (CommonService.isTwseProduction()) {
      document.title = "ESG數位平台";
    } else {
      document.title = "ESG數位平台(測試)";
    }
  }, []);

  const { userInfo, setUserInfo } = useUserInfoStore();

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      // 排除部分router
      if (CommonService.isExcludeApiRouter()) {
        // 定時被刪除；
        if (event.key === "twse_timer" && event.newValue === null) {
          setUserInfo(null);
        }

        // 避免userInfo直接從 localstorage
        if (event.key === "twse_userInfo" && event.newValue === null) {
          setUserInfo(null);
        } else if (event.key === "twse_userInfo" && event.newValue) {
          // 別的頁面已將值更新為null
          let obj = JSON.parse(event.newValue);
          console.log(obj);
          if (
            obj.state &&
            obj.state.userInfo &&
            !obj.state.userInfo.accessToken
          ) {
            setUserInfo(null);
          }
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // 要啟用的一組可選的未來標誌。我們建議儘早選擇新發布的未來標誌，以方便您最終遷移到 v7。
  return (
    <RouterProvider
      router={routers}
      fallbackElement={<VisuallLoading />}
      future={{ v7_startTransition: true }}
    />
  );
};
export default App;
